import React from "react";
import { ActivityIndicator, View } from "react-native";
import PropTypes from "prop-types";
import { StyleSheet } from "react-native";

import { APP_COLOR_2 } from "../common/colors";

LoadingIndicator.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

export function LoadingIndicator(props) {
  return (
    <React.Fragment>
      {props.isLoading ? (
        <View style={style.loadingIndicator}>
          <ActivityIndicator
            style={style.loadingIndicator}
            size={props.size}
            color={APP_COLOR_2}
          />
        </View>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
}

const style = StyleSheet.create({
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
});

export const LOADING_SIZE = {
  LARGE: "large",
  SMALL: "small",
};
