import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CouponsList from "./CouponsList";
import { CouponsReceivedConsts } from "../../common/constants";
import {
  fetchReceivedCoupons,
  deleteReceivedCoupon,
} from "../../modules/coupons/services/CouponService";
import { refetchReceivedCoupons } from "../../modules/coupons/actions/AppConfigActions";

class CouponsReceived extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const { shouldRefetchReceivedCoupons } = this.props.config;

    if (shouldRefetchReceivedCoupons) {
      this.props.refetchReceivedCoupons(false);
      this.props.fetchReceivedCoupons();
    }
  }

  render() {
    const { coupons } = this.props.coupons.couponsReceived;
    const { params } = this.props.route;

    return (
      <CouponsList
        navigation={this.props.navigation}
        routeParams={params}
        listType={CouponsReceivedConsts.COUPON_TYPE}
        data={coupons}
        fetchCoupons={this.props.fetchReceivedCoupons}
        deleteCoupon={this.props.deleteReceivedCoupon}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  coupons: state.coupons,
  config: state.config,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchReceivedCoupons: fetchReceivedCoupons,
      deleteReceivedCoupon: deleteReceivedCoupon,
      refetchReceivedCoupons: refetchReceivedCoupons,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CouponsReceived);
