import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_BUDDIES,
  ADD_BUDDY,
  DELETE_BUDDY,
} from "../actions/ProfileActionTypes";

export const profileInitialState = {
  userProfile: {},
  buddies: [],
};

export const profile = (state = profileInitialState, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.profile,
        },
      };
    case UPDATE_USER_BUDDIES:
      return {
        ...state,
        buddies: action.buddies,
      };
    case ADD_BUDDY:
      return {
        ...state,
        buddies: [...state.buddies, action.buddy],
      };
    case DELETE_BUDDY:
      return {
        ...state,
        buddies: state.buddies.filter(
          (buddy) => buddy.username !== action.buddy.username
        ),
      };
    default:
      return state;
  }
};
