import React from "react";
import { StyleSheet } from "react-native";
import { Provider } from "react-redux";
import _ from "lodash";
import Constants from "expo-constants";
import * as Sentry from "sentry-expo";

import { appStyle } from "./styles/AppStyle";
import RootNavigation from "./modules/Navigation";
import { store } from "./store";
import { getSentryURL, PRODUCTION } from "./common/environment";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import "react-native-gesture-handler";
import { LogBox } from "react-native";
import { StatusBar } from "expo-status-bar";

// https://github.com/react-navigation/react-navigation/issues/9894
LogBox.ignoreLogs([
  "EventEmitter.removeListener",
  "ViewPropTypes will be removed from React Native",
]);

export default function App() {
  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <SafeAreaView style={styles.container}>
          <StatusBar style="dark" />
          <RootNavigation />
        </SafeAreaView>
      </SafeAreaProvider>
    </Provider>
  );
}

const styles = StyleSheet.create(appStyle);

Sentry.init({
  dsn: getSentryURL(),
  enableInExpoDevelopment: true,
  environment:
    Constants.expoConfig.extra.REALM === PRODUCTION
      ? PRODUCTION
      : "development",
});
