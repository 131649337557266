import { store } from "../store";
import Constants from "expo-constants";

export const log = (...messages: String[]) => {
  const state = store.getState();
  const username = state.auth.userAccount?.username ?? "Username N/A";
  const deviceName = Constants.deviceName;
  const logline = [deviceName, username, messages.join(" ")];
  console.log(logline.join(" | "));
};
