import React, { Component } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
} from "react-native";
import { AirbnbRating, Text } from "@rneui/themed";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { profileStyle } from "../../styles/ProfileStyle";
import { getUserProfile } from "../../modules/coupons/services/ProfileService";
import Edit from "../../assets/images/edit.svg";
import { ProfileConsts, NavigationConsts } from "../../common/constants";
import { avatars } from "../../common/assets";
import Buddies from "./Buddies";
import { COMPONENT_COLOR_2, COMPONENT_COLOR_1 } from "../../common/colors";
import { getBuddyProfiles } from "../../modules/coupons/services/BuddiesService";
import { Button } from "../../common/Button";
import { log } from "../../common/logging";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherUserProfile: null,
      isGettingProfile: true,
      gettingProfileError: null,
    };
  }

  async componentDidMount() {
    this.setState({ isGettingProfile: true, gettingProfileError: null });

    let { username, displayName } = this.props.route.params;

    const onOtherUserProfile = !!username;

    this.setupHeaderIcons(onOtherUserProfile);
    let otherUserProfile = null;
    try {
      if (onOtherUserProfile) {
        displayName = displayName ? displayName : username;
        const HEADER_TITLE =
          displayName.charAt(displayName.length - 1).toLowerCase() === "s"
            ? ProfileConsts.OTHER_PROFILE_TITLE_WITHOUT_S
            : ProfileConsts.OTHER_PROFILE_TITLE;
        this.props.navigation.setParams({ title: displayName + HEADER_TITLE });
        otherUserProfile = await this.props.getUserProfile(username);
      } else {
        await this.props.getUserProfile();
      }
    } catch (e) {
      this.setState({
        gettingProfileError: ProfileConsts.PROFILE_FETCH_ERROR(username),
        isGettingProfile: false,
        otherUserProfile: null,
      });
      log(e.message);
      return;
    }

    this.setState({
      isGettingProfile: false,
      otherUserProfile: otherUserProfile,
    });
  }

  setupHeaderIcons = (onOtherUserProfile) => {
    const editThisProfile = () => {
      this.props.navigation.navigate(NavigationConsts.EDIT_PROFILE_SCREEN);
    };

    const HeaderIcons = (
      <View style={profileStyle.profileHeaderIconsContainer}>
        {onOtherUserProfile ? null : (
          <TouchableOpacity
            activeOpacity={1}
            style={profileStyle.profileHeaderIcon}
            onPress={editThisProfile}
          >
            <Edit height={20} width={20} />
          </TouchableOpacity>
        )}
      </View>
    );
    this.props.navigation.setOptions({ headerRight: () => HeaderIcons });
  };

  onRefresh = async () => {
    let { username } = this.props.route.params;
    const onOtherUserProfile = !!username;
    let otherUserProfile = null;

    try {
      this.setState({
        isGettingProfile: true,
        gettingProfileError: null,
      });

      if (onOtherUserProfile) {
        otherUserProfile = await this.props.getUserProfile(username);
      } else {
        await this.props.getUserProfile();
        await this.props.getBuddyProfiles();
      }
    } catch (e) {
      this.setState({
        gettingProfileError: ProfileConsts.PROFILE_FETCH_ERROR(username),
        isGettingProfile: false,
        otherUserProfile: null,
      });
      return;
    }
    this.setState({
      isGettingProfile: false,
      otherUserProfile: otherUserProfile,
    });
  };

  render() {
    const { userProfile } = this.props.profile;
    const { otherUserProfile, isGettingProfile, gettingProfileError } =
      this.state;
    const { username } = this.props.route.params;

    const onOtherUserProfile = !!username;

    let activeProfile = null;
    if (onOtherUserProfile) {
      activeProfile = otherUserProfile;
    } else {
      activeProfile = userProfile;
    }

    let RefreshController = (
      <RefreshControl
        onRefresh={this.onRefresh}
        refreshing={isGettingProfile}
        colors={[COMPONENT_COLOR_1, COMPONENT_COLOR_2]}
      />
    );

    if (onOtherUserProfile) {
      return (
        <View style={profileStyle.backgroundContainer}>
          <ScrollView
            contentContainerStyle={profileStyle.container}
            showsVerticalScrollIndicator={false}
            refreshControl={RefreshController}
          >
            {gettingProfileError ? (
              <View style={profileStyle.profileErrorContainer}>
                <React.Fragment>
                  <Text style={profileStyle.profileErrorText}>
                    {gettingProfileError}
                  </Text>
                  <Button
                    type={1}
                    customStyle={profileStyle.profileErrorButton}
                    onPress={this.onRefresh}
                  >
                    {ProfileConsts.TRY_AGAIN}
                  </Button>
                </React.Fragment>
              </View>
            ) : isGettingProfile ? null : activeProfile ? (
              <React.Fragment>
                <ProfileSummary userProfile={activeProfile} />
                <ProfileRating
                  userProfile={activeProfile}
                  isOnOtherUserProfile={onOtherUserProfile}
                />
                <ProfileDetails
                  userProfile={activeProfile}
                  isOnOtherUserProfile={onOtherUserProfile}
                />
              </React.Fragment>
            ) : (
              <View style={profileStyle.profileNotFoundContainer}>
                <Text style={profileStyle.profileNotFoundText}>
                  {ProfileConsts.USER_NOT_FOUND}
                </Text>
              </View>
            )}
          </ScrollView>
        </View>
      );
    }

    return (
      <View style={profileStyle.backgroundContainer}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={RefreshController}
        >
          <Text style={profileStyle.profileDetailsTitle}>
            {ProfileConsts.MY_PROFILE}
          </Text>
          {activeProfile ? (
            <React.Fragment>
              <ProfileSummary userProfile={activeProfile} />
              <ProfileRating
                userProfile={activeProfile}
                isOnOtherUserProfile={onOtherUserProfile}
              />
              <ProfileDetails
                userProfile={activeProfile}
                isOnOtherUserProfile={onOtherUserProfile}
              />
            </React.Fragment>
          ) : (
            <View style={profileStyle.profileNotFoundContainer}>
              <Text style={profileStyle.profileNotFoundText}>
                {ProfileConsts.USER_NOT_FOUND}
              </Text>
            </View>
          )}
          <Buddies navigation={this.props.navigation} />
        </ScrollView>
      </View>
    );
  }
}

const ProfileSummary = (props) => {
  const { userProfile } = props;

  const Avatar = avatars[userProfile.avatarID];
  const displayName = userProfile.fullname
    ? userProfile.fullname
    : userProfile.username;

  return (
    <View style={profileStyle.profileNameContainer}>
      <View style={profileStyle.profileNameHeader}>
        <Avatar width={50} height={50} />
        <Text style={profileStyle.profileNameHeaderName}>{displayName}</Text>
      </View>
      <View style={profileStyle.profileNameSummary}></View>
    </View>
  );
};

ProfileSummary.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

const ProfileRating = (props) => {
  const { userProfile, isOnOtherUserProfile } = props;

  const reputation =
    userProfile.number_of_ratings !== 0
      ? (
          Math.round(
            (userProfile.ratings * 100) / userProfile.number_of_ratings
          ) / 100
        ).toFixed(1)
      : ProfileConsts.NOT_AVAILABLE_REPUTATION;

  return (
    <View style={profileStyle.ratingContainer}>
      <Text style={profileStyle.ratingBox}>{reputation}</Text>
      <View style={profileStyle.ratingInfoContainer}>
        <Text style={profileStyle.ratingTitle}>
          {isOnOtherUserProfile
            ? ProfileConsts.OTHER_PROFILE_REPUTATION
            : ProfileConsts.MY_REPUTATION}
        </Text>
        <AirbnbRating
          showRating={false}
          defaultRating={parseFloat(reputation) ? parseFloat(reputation) : 0}
          count={5}
          size={20}
          isDisabled
        />
      </View>
    </View>
  );
};

ProfileRating.propTypes = {
  userProfile: PropTypes.object.isRequired,
  isOnOtherUserProfile: PropTypes.bool.isRequired,
};

const ProfileDetails = (props) => {
  const { userProfile, isOnOtherUserProfile } = props;

  return (
    <View style={profileStyle.detailsContainer}>
      {!isOnOtherUserProfile && userProfile["email"] && (
        <View style={profileStyle.detailsFieldContainer}>
          <Text style={profileStyle.detailsFieldName}>
            {ProfileConsts.FIELD_EMAIL}
          </Text>
          <Text style={profileStyle.detailsFieldValue}>
            {userProfile["email"]}
          </Text>
        </View>
      )}
      <View style={profileStyle.detailsFieldContainer}>
        <Text style={profileStyle.detailsFieldName}>
          {ProfileConsts.FIELD_FULLNAME}
        </Text>
        <Text
          style={[
            profileStyle.detailsFieldValue,
            userProfile["fullname"] ? null : { fontStyle: "italic" },
          ]}
        >
          {userProfile["fullname"]
            ? userProfile["fullname"]
            : ProfileConsts.FIELD_NO_DISPLAYNAME}
        </Text>
      </View>
      <View style={profileStyle.detailsFieldContainer}>
        <Text style={profileStyle.detailsFieldName}>
          {ProfileConsts.FIELD_USERNAME}
        </Text>
        <Text style={profileStyle.detailsFieldValue}>
          {userProfile["username"]}
        </Text>
      </View>
    </View>
  );
};

ProfileDetails.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfile: getUserProfile,
      getBuddyProfiles: getBuddyProfiles,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
