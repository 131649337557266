import React, { Component } from "react";
import { Input, ListItem } from "@rneui/themed";
import { View, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as Sentry from "sentry-expo";

import { buddiesStyle } from "../../styles/BuddiesStyle";
import { getUserProfileSuggestions } from "../../modules/coupons/services/ProfileService";
import {
  BuddiesConsts,
  NavigationConsts,
  NetworkConsts,
} from "../../common/constants";
import { TEXT_COLOR_4 } from "../../common/colors";
import SearchIcon from "../../assets/images/search.svg";
import XIcon from "../../assets/images/letter-x.svg";
import {
  addBuddy,
  getBuddyProfiles,
  deleteBuddy,
} from "../../modules/coupons/services/BuddiesService";
import { LoadingIndicator, LOADING_SIZE } from "../LoadingIndicator";
import {
  addBuddyAction,
  deleteBuddyAction,
} from "../../modules/coupons/actions/ProfileActions";
import { avatars } from "../../common/assets";

export class Buddies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchingForSuggestions: false,
      userProfileSuggestions: [],
      userProfilesCache: {},

      friendUsername: "",
      searchedFriend: null,
      fetchingBuddies: false,
      fetchingBuddiesError: null,
      addingBuddy: false,
      addingBuddyError: null,
      deletingBuddy: false,
      deletingBuddyError: null,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ fetchingBuddies: true });
      await this.props.getBuddyProfiles();
      this.setState({ fetchingBuddies: false });
    } catch (e) {
      if (e.name !== NetworkConsts.AUTHENTICATION_FAILED_NAME) {
        this.setState({
          fetchingBuddiesError: BuddiesConsts.FAILED_TO_FETCH_BUDDIES,
          fetchingBuddies: false,
        });
      }
      Sentry.Native.captureException(e);
    }
  }

  handleFindFriend = async (untrimmedFriend) => {
    this.setState({
      searchingForSuggestions: true,
      addingBuddyError: null,
    });

    let friend = untrimmedFriend.trim();
    var userProfiles = [];
    if (this.validateFindFriend(friend)) {
      if (this.state.userProfilesCache[friend]) {
        userProfiles = this.state.userProfilesCache[friend];
      } else {
        userProfiles = await this.props.getUserProfileSuggestions(friend);
        if (userProfiles === null) {
          return;
        }
        var cache = this.state.userProfilesCache;
        cache[friend] = userProfiles;
        this.setState({ userProfilesCache: cache });
      }
    }

    if (Object.keys(this.state.userProfilesCache).length > 100) {
      this.setState({ userProfilesCache: {} });
    }

    this.setState({
      searchingForSuggestions: false,
      userProfileSuggestions: userProfiles,
      searchedFriend: true,
    });
  };

  validateFindFriend = (friend) => {
    const isUsernameShorterThan2Chars = friend.search(/^\w{2,}$/) === -1;
    const isUsernameTooLong = friend.search(/^\w{0,20}$/) === -1;
    const doesUsernameContainSpecialChars = friend.search(/^[\w\d_]+$/) === -1;

    return !(
      friend === "" ||
      isUsernameShorterThan2Chars ||
      isUsernameTooLong ||
      doesUsernameContainSpecialChars
    );
  };

  handleAddBuddy = async (buddy) => {
    this.setState({
      addingBuddy: true,
      searchedFriend: false,
    });
    try {
      await this.props.addBuddy(buddy.username);
    } catch (e) {
      this.setState({
        addingBuddy: false,
        addingBuddyError: BuddiesConsts.FAILED_TO_ADD_BUDDY,
        searchingForSuggestions: false,
        userProfileSuggestions: [],
        friendUsername: "",
      });
      this.friendInput.clear();
      return;
    }
    this.setState({
      addingBuddy: false,
      searchingForSuggestions: false,
      userProfileSuggestions: [],
      friendUsername: "",
    });
    this.props.addBuddyAction(buddy);
    this.friendInput.clear();
  };

  handleDeleteBuddy = async (buddy) => {
    try {
      this.setState({
        deletingBuddy: true,
        deletingBuddyError: null,
      });
      await this.props.deleteBuddy(buddy.username);
      this.props.deleteBuddyAction(buddy);
      this.setState({ deletingBuddy: false });
    } catch (error) {
      this.setState({ deletingBuddyError: error });
    }
  };

  handleRefresh = async () => {
    try {
      this.setState({
        fetchingBuddies: true,
        fetchingBuddiesError: null,
      });
      await this.props.getBuddyProfiles();
      this.setState({ fetchingBuddies: false });
    } catch (e) {
      this.setState({
        fetchingBuddiesError: BuddiesConsts.FAILED_TO_FETCH_BUDDIES,
        fetchingBuddies: false,
      });
    }
  };

  handleInputText = (text) => {
    this.setState({
      friendUsername: text.trim(),
      searchingForSuggestions: false,
      searchedFriend: false,
    });
  };

  render = () => {
    const {
      searchingForSuggestions,
      userProfileSuggestions,
      fetchingBuddies,
      fetchingBuddiesError,
      friendUsername,
      searchedFriend,
    } = this.state;
    const { buddies } = this.props.profile;

    return (
      <View style={buddiesStyle.container}>
        <Text style={buddiesStyle.buddiesDetailsTitle}>
          {BuddiesConsts.PEOPLE_I_KNOW}
        </Text>
        <View style={buddiesStyle.innerContainer}>
          <Input
            ref={(input) => {
              this.friendInput = input;
            }}
            style={buddiesStyle.findFriend}
            containerStyle={buddiesStyle.findFriendContainer}
            inputContainerStyle={buddiesStyle.findFriendInput}
            leftIconContainerStyle={buddiesStyle.findFriendIcon}
            onChangeText={(text) => this.handleInputText(text)}
            onSubmitEditing={({ nativeEvent }) =>
              this.handleFindFriend(nativeEvent.text)
            }
            placeholder={BuddiesConsts.ADD_BUDDY}
            placeholderTextColor={TEXT_COLOR_4}
            leftIcon={
              <TouchableOpacity
                onPress={() => this.handleFindFriend(friendUsername)}
              >
                <SearchIcon height={25} width={25} />
              </TouchableOpacity>
            }
          />
          <DropdownSuggestion
            buddiesList={buddies}
            searchingForSuggestions={searchingForSuggestions}
            searchedFriend={searchedFriend}
            friendUsername={friendUsername}
            userProfileSuggestions={userProfileSuggestions}
            handleAddBuddy={this.handleAddBuddy}
          />
          <ListOfBuddies
            buddiesList={buddies}
            navigation={this.props.navigation}
            refreshing={fetchingBuddies}
            onRefresh={this.handleRefresh}
            handleDeleteBuddy={this.handleDeleteBuddy}
            fetchingBuddiesError={fetchingBuddiesError}
          />
        </View>
      </View>
    );
  };
}

Buddies.propTypes = {
  profile: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  getBuddyProfiles: PropTypes.func.isRequired,
  getUserProfileSuggestions: PropTypes.func.isRequired,
  addBuddy: PropTypes.func.isRequired,
  addBuddyAction: PropTypes.func.isRequired,
  deleteBuddy: PropTypes.func.isRequired,
  deleteBuddyAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBuddyProfiles: getBuddyProfiles,
      getUserProfileSuggestions: getUserProfileSuggestions,
      addBuddy: addBuddy,
      addBuddyAction: addBuddyAction,
      deleteBuddy: deleteBuddy,
      deleteBuddyAction: deleteBuddyAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Buddies);

const DropdownSuggestion = (props) => {
  const matchingProfile = props.userProfileSuggestions.filter(
    (profile) =>
      profile.username.toUpperCase() === props.friendUsername.toUpperCase()
  )[0];

  if (props.searchingForSuggestions) {
    return (
      <View style={buddiesStyle.suggestionSearchingContainer}>
        <Text style={buddiesStyle.suggestionSearchingText}>
          {BuddiesConsts.SEARCHING_FOR_FRIEND(props.friendUsername)}
        </Text>
      </View>
    );
  } else if (!matchingProfile && props.searchedFriend) {
    return (
      <View style={buddiesStyle.suggestionSearchingContainer}>
        <Text style={buddiesStyle.suggestionSearchingText}>
          {BuddiesConsts.NO_USER_WITH_NAME(props.friendUsername)}
        </Text>
      </View>
    );
  } else if (matchingProfile) {
    if (
      props.buddiesList.find(
        (profile) =>
          profile.username.toUpperCase() ===
          matchingProfile.username.toUpperCase()
      )
    ) {
      return (
        <View style={buddiesStyle.suggestionSearchingContainer}>
          <Text style={buddiesStyle.suggestionSearchingText}>
            {BuddiesConsts.ALREADY_FRIENDS_WITH(props.friendUsername)}
          </Text>
        </View>
      );
    }
    return (
      <TouchableOpacity
        style={buddiesStyle.suggestionContainer}
        onPress={() => props.handleAddBuddy(matchingProfile)}
      >
        <Text style={buddiesStyle.suggestionActionText}>
          {BuddiesConsts.ADD}
        </Text>
        <Text style={buddiesStyle.suggestionText}>
          {matchingProfile.username}
        </Text>
      </TouchableOpacity>
    );
  } else {
    return null;
  }
};

const ListOfBuddies = (props) => {
  const onBuddyPress = (username, displayName) => {
    props.navigation.push(NavigationConsts.PROFILE_SCREEN, {
      username: username,
      displayName: displayName,
    });
  };

  if (props.fetchingBuddiesError) {
    return (
      <LoadingIndicator isLoading={props.refreshing} size={LOADING_SIZE.LARGE}>
        <View style={buddiesStyle.containerNoList}>
          <Text style={buddiesStyle.noListErrorText}>
            {BuddiesConsts.FAILED_TO_FETCH_BUDDIES}
          </Text>
        </View>
      </LoadingIndicator>
    );
  } else if (props.buddiesList && props.buddiesList.length === 0) {
    return (
      <LoadingIndicator isLoading={props.refreshing} size={LOADING_SIZE.SMALL}>
        <View style={buddiesStyle.emptyListContainer}>
          <Text style={buddiesStyle.emptyListText}>
            {BuddiesConsts.NO_FRIENDS}
          </Text>
        </View>
      </LoadingIndicator>
    );
  } else if (props.buddiesList) {
    const deleteFriendIcon = (friend) => (
      <TouchableOpacity
        activeOpacity={1}
        style={buddiesStyle.buddiesListItemXButton}
        onPress={() => props.handleDeleteBuddy(friend)}
      >
        <XIcon height={15} width={15} />
      </TouchableOpacity>
    );
    const avatarIcon = (avatarID) => {
      const AvatarIcon = avatars[avatarID];
      return <AvatarIcon height={35} width={35} />;
    };
    return props.buddiesList.map((profile, i) => (
      <ListItem
        key={i}
        bottomDivider
        onPress={() => onBuddyPress(profile.username, profile.fullname)}
        containerStyle={buddiesStyle.buddiesListItem}
      >
        {avatarIcon(profile.avatarID)}
        <ListItem.Content>
          <ListItem.Title>
            {profile.fullname ?? profile.username}
          </ListItem.Title>
        </ListItem.Content>
        {deleteFriendIcon(profile)}
      </ListItem>
    ));
  } else {
    return null;
  }
};
