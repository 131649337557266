import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_BUDDIES,
  ADD_BUDDY,
  DELETE_BUDDY,
} from "./ProfileActionTypes";

export const updateUserProfileAction = (userProfile) => ({
  type: UPDATE_USER_PROFILE,
  profile: userProfile,
});

export const updateProfileBuddiesAction = (buddies) => ({
  type: UPDATE_USER_BUDDIES,
  buddies: buddies,
});

export const addBuddyAction = (buddy) => ({
  type: ADD_BUDDY,
  buddy: buddy,
});

export const deleteBuddyAction = (buddy) => ({
  type: DELETE_BUDDY,
  buddy: buddy,
});
