import { Platform } from "react-native";
import * as Notifications from "expo-notifications";
import { isDevice } from "expo-device";
import { navigate } from "./TheNavigator";
import { NavigationConsts } from "../common/constants";
import { store } from "../store";
import * as Sentry from "sentry-expo";

export const registerForPushNotificationsAsync = async () => {
  console.debug("Setup Expo push notifications");
  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  let token = undefined;
  if (isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      let msg =
        "Failed to get push token for push notification with status: " +
        finalStatus;
      Sentry.Native.captureMessage(msg);
      console.debug(msg);
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    console.debug("Expo Push Notification Token: ", token);
  } else {
    alert("Must use physical device for Push Notifications");
  }

  return token;
};

export const setNotificationHandler = () => {
  Notifications.setNotificationHandler({
    handleNotification: async (notification) => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
    }),
  });
};

export const removeNotificationHandler = () => {
  Notifications.setNotificationHandler({
    handleNotification: null,
  });
};

export const getNotificationReceivedListener = () => {
  return Notifications.addNotificationReceivedListener(_handleNotification);
};

export const getNotificationResponseReceivedListener = () => {
  return Notifications.addNotificationResponseReceivedListener(
    _handleNotificationInteraction
  );
};

const _handleNotification = (notification) => {
  console.debug("Notification data:", JSON.stringify(notification));
};

const _handleNotificationInteraction = (notificationResponse) => {
  if (
    notificationResponse &&
    notificationResponse.notification &&
    notificationResponse.notification.request &&
    notificationResponse.notification.request.content &&
    notificationResponse.notification.request.content.data
  ) {
    let couponType;
    let couponId;
    if (Platform.OS === "android") {
      couponType =
        notificationResponse.notification.request.content.data?.type ?? null;
      couponId =
        notificationResponse.notification.request.content.data?.couponId ??
        null;
    } else if (
      Platform.OS === "ios" &&
      notificationResponse.notification.request.content.data.body
    ) {
      couponType =
        notificationResponse.notification.request.content.data.body?.type ??
        null;
      couponId =
        notificationResponse.notification.request.content.data.body?.couponId ??
        null;
    } else {
      return;
    }

    if (couponType && couponId) {
      const state = store.getState();
      const couponsReceived = state.coupons.couponsReceived.coupons;
      const couponsSent = state.coupons.couponsSent.coupons;
      if (
        couponsReceived.find((coupon) => coupon.id === couponId) ||
        couponsSent.find((coupon) => coupon.id === couponId)
      ) {
        navigate(NavigationConsts.COUPON_SCREEN, {
          listType: couponType,
          couponId: couponId,
        });
      }
    }
  }
  console.debug(
    "Interacted with notification: " + JSON.stringify(notificationResponse)
  );
};
