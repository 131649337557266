import React, { Component } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  RefreshControl,
  SectionList,
} from "react-native";
import { connect } from "react-redux";
import moment from "moment";
import { ScrollView } from "react-native-gesture-handler";
import PropTypes from "prop-types";
import * as Sentry from "sentry-expo";

import Coupon from "../Coupon";
import { coupsListStyle } from "../../styles/CouponsListStyle";
import {
  CouponsListConsts,
  CouponsConsts,
  NavigationConsts,
} from "../../common/constants";
import { Button } from "../../common/Button";
import {
  COMPONENT_COLOR_1,
  COMPONENT_COLOR_2,
  BACKGROUND_COLOR,
} from "../../common/colors";

export class CouponsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingCoupons: false,
      fetchingCouponsError: null,
    };
    this.sectionedListRef = null;
  }

  async componentDidMount() {
    await this.handleFetchingCoupons();
  }

  componentDidUpdate(prevProps) {
    if (this.props.routeParams) {
      const { scrollToCouponId } = this.props.routeParams;
      if (prevProps.scrollToCouponId !== scrollToCouponId) {
        this.scrollToCoupon(scrollToCouponId);
        this.props.navigation.setParams({ scrollToCouponId: undefined });
      }
    }
  }

  handleFetchingCoupons = async () => {
    this.setState({
      fetchingCoupons: true,
      fetchingCouponsError: null,
    });
    await this.props.fetchCoupons({
      success: () => {},
      failure: () =>
        this.setState({
          fetchingCouponsError: CouponsListConsts.ERROR_LOADING_COUPONS_MSG,
        }),
    });

    this.setState({ fetchingCoupons: false });
  };

  onPressCreateCoupon = () => {
    this.props.navigation.navigate(NavigationConsts.CREATECOUPON_SCREEN);
  };

  scrollToCoupon = async (couponid) => {
    const { data } = this.props;
    try {
      const sectionedData = getSectionedData(data);
      const couponIndexParam = getCouponSectionedIndex(sectionedData, couponid);
      await new Promise((res, rej) =>
        setTimeout(() => {
          try {
            if (this.sectionedListRef) {
              this.sectionedListRef.scrollToLocation(couponIndexParam);
            }
          } catch (error) {
            console.debug("Failed to scroll to location", couponid);
          }
        }, 1000)
      );
    } catch (error) {
      console.debug("Failed to scroll to coupon id", couponid, error);
      Sentry.Native.captureException(error);
    }
  };

  render() {
    const { listType, data, deleteCoupon, navigation } = this.props;

    const { fetchingCoupons, fetchingCouponsError } = this.state;

    let RefreshController = (
      <RefreshControl
        refreshing={fetchingCoupons}
        onRefresh={this.handleFetchingCoupons}
        colors={[COMPONENT_COLOR_1, COMPONENT_COLOR_2]}
      />
    );

    const sectionedData = getSectionedData(data);

    let noActiveCouponsHeader = false;
    if (
      sectionedData.length > 0 &&
      sectionedData[0].status === CouponsConsts.STATE_INACTIVE
    ) {
      noActiveCouponsHeader = true;
    }

    if (fetchingCouponsError) {
      return (
        <View style={coupsListStyle.noCouponsListContainer}>
          <View style={coupsListStyle.errorView}>
            <Text style={coupsListStyle.errorText}>{fetchingCouponsError}</Text>
            <Button
              type={1}
              onPress={this.handleFetchingCoupons}
              customStyle={coupsListStyle.errRetryBtn}
            >
              {CouponsListConsts.ERROR_RETRY_BTN}
            </Button>
          </View>
        </View>
      );
    } else if (fetchingCoupons) {
      return (
        <View style={coupsListStyle.noCouponsListContainer}>
          <View style={coupsListStyle.spinnerView}>
            <ActivityIndicator size="large" color={COMPONENT_COLOR_1} />
          </View>
        </View>
      );
    } else if (data.length === 0) {
      return (
        <ScrollView
          contentContainerStyle={coupsListStyle.emptyCouponContainerView}
          refreshControl={RefreshController}
        >
          <NoActiveCoupons
            listType={listType}
            onPressCreateCoupon={this.onPressCreateCoupon}
          />
        </ScrollView>
      );
    }

    return (
      <View style={coupsListStyle.container}>
        <View style={coupsListStyle.flatListView}>
          <SectionList
            ref={(ref) => (this.sectionedListRef = ref)}
            onScrollToIndexFailed={() => {}}
            ListHeaderComponent={
              noActiveCouponsHeader ? (
                <NoActiveCoupons
                  listType={listType}
                  onPressCreateCoupon={this.onPressCreateCoupon}
                />
              ) : null
            }
            sections={sectionedData}
            refreshControl={RefreshController}
            stickySectionHeadersEnabled={true}
            renderSectionHeader={({ section: { status, data } }) =>
              status === CouponsConsts.STATE_INACTIVE && data.length > 0 ? (
                <CompletedExpiredSectionHeader num={data.length} />
              ) : null
            }
            renderItem={({ item }) => (
              <Coupon
                navigation={navigation}
                couponId={item.id}
                listType={listType}
                status={item.status}
                name={item.name}
                description={item.description}
                startDate={item.startDate}
                endDate={item.endDate}
                sender={item.sender}
                receiver={item.receiver}
                senderDisplayName={item.senderDisplayName}
                receiverDisplayName={item.receiverDisplayName}
                senderAvatarId={item.senderAvatarId}
                receiverAvatarId={item.receiverAvatarId}
                redeemers={item.redeemers}
                isNew={item.isNew}
                deleteCoupon={deleteCoupon}
                scrollToCoupon={this.scrollToCoupon}
              />
            )}
            keyExtractor={(item) => item.id}
          />
        </View>
      </View>
    );
  }
}

CouponsList.propTypes = {
  navigation: PropTypes.object.isRequired,
  routeParams: PropTypes.object,
  listType: PropTypes.string.isRequired,
  data: PropTypes.array,
  fetchCoupons: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
};

const getSectionedData = (data) => {
  // The order of these statuses is how the sections will be ordered in
  const couponStatusBuckets = {
    [CouponsConsts.STATUS_WAITING_FOR_COMPLETION]: [],
    [CouponsConsts.STATUS_REDEEMED]: [],
    [CouponsConsts.STATUS_REQUEST]: [],
    [CouponsConsts.STATUS_SENT]: [],
    [CouponsConsts.STATUS_SENT_LINK]: [],
    [CouponsConsts.STATUS_ACCEPTED]: [],
    [CouponsConsts.STATUS_REJECTED]: [],
    [CouponsConsts.STATUS_EXPIRED]: [],
    [CouponsConsts.STATUS_COMPLETED]: [],
    [CouponsConsts.STATUS_RATED]: [],
    [CouponsConsts.STATE_INACTIVE]: [],
  };

  data.forEach((coupon) => couponStatusBuckets[coupon.status].push(coupon));

  const sections = [];
  Object.keys(couponStatusBuckets).forEach((status) => {
    if (
      status === CouponsConsts.STATUS_EXPIRED ||
      status === CouponsConsts.STATUS_COMPLETED ||
      status === CouponsConsts.STATUS_RATED ||
      status === CouponsConsts.STATE_INACTIVE
    ) {
      return;
    }
    if (couponStatusBuckets[status].length !== 0) {
      sections.push({
        status: status,
        data: couponStatusBuckets[status].sort(
          (a, b) => moment(b.recentActivity) - moment(a.recentActivity)
        ),
      });
    }
  });
  sections.push({
    status: CouponsConsts.STATE_INACTIVE,
    data: [
      ...couponStatusBuckets[CouponsConsts.STATUS_EXPIRED].sort(
        (a, b) => moment(b.recentActivity) - moment(a.recentActivity)
      ),
      ...couponStatusBuckets[CouponsConsts.STATUS_COMPLETED].sort(
        (a, b) => moment(b.recentActivity) - moment(a.recentActivity)
      ),
      ...couponStatusBuckets[CouponsConsts.STATUS_RATED].sort(
        (a, b) => moment(b.recentActivity) - moment(a.recentActivity)
      ),
    ],
  });
  return sections;
};

const getCouponSectionedIndex = (sections, couponid) => {
  if (!sections) {
    return null;
  }
  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].data.length; j++) {
      if (sections[i].data[j].id === couponid) {
        console.info(sections[i].data[j]);
        return {
          sectionIndex: i,
          itemIndex: j + 1,
        };
      }
    }
  }
  return null;
};

const CompletedExpiredSectionHeader = (props) => {
  return (
    <View style={coupsListStyle.sectionHeaderContainer}>
      <Text style={coupsListStyle.sectionHeaderText}>
        {CouponsListConsts.COMPLETED_EXPIRED_SECTION_TITLE}
      </Text>
      <Text style={coupsListStyle.sectionHeaderText}>{props.num}</Text>
    </View>
  );
};

const NoActiveCoupons = ({ listType, onPressCreateCoupon }) => {
  return (
    <View style={coupsListStyle.noActiveCouponsContainer}>
      {listType === CouponsConsts.TYPE_RECEIVED ? (
        <Text style={coupsListStyle.noActiveCouponsText}>
          {CouponsListConsts.NO_ACTIVE_COUPONS}
        </Text>
      ) : (
        <View style={coupsListStyle.emptyCouponContainerView}>
          <Text style={coupsListStyle.noActiveCouponsText}>
            {CouponsListConsts.NO_SENT_COUPONS}
          </Text>
          <Button type={4} onPress={onPressCreateCoupon}>
            {CouponsListConsts.CREATE_A_COUPON}
          </Button>
        </View>
      )}
    </View>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(CouponsList);
