import {
  updateReceivedCouponAction,
  updateSentCouponAction,
  deleteReceivedCouponAction,
} from "../actions/CouponActions";
import { CouponsConsts } from "../../../common/constants";
import { authFetchWithTimeout, processResponse } from "../../../common/network";
import { getApiURL } from "../../../common/environment";
import { log } from "../../../common/logging";

const backendUrl = getApiURL();

const taskEndpoint = `${backendUrl}/api/task`;

const POST = (taskType, couponId) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    task: taskType,
    couponId,
  }),
});

const POST_RATING = (taskType, couponId, rating) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    task: taskType,
    couponId,
    rating: rating,
  }),
});

export const acceptCoupon = (couponId, resultCallbacks) => (dispatch) => {
  return authFetchWithTimeout(
    taskEndpoint,
    POST(TaskType.ACCEPT_COUPON, couponId),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      dispatch(
        updateReceivedCouponAction(couponId, {
          status: CouponsConsts.STATUS_ACCEPTED,
        })
      );
      resultCallbacks ? resultCallbacks.success() : null;
      return res.data;
    })
    .catch((error) => {
      log("Task Error: ", error.message);
      resultCallbacks ? resultCallbacks.failure() : null;
      return null;
    });
};

export const rejectCoupon = (couponId, resultCallbacks) => (dispatch) => {
  return authFetchWithTimeout(
    taskEndpoint,
    POST(TaskType.DECLINE_COUPON, couponId),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      dispatch(deleteReceivedCouponAction(couponId));
      resultCallbacks ? resultCallbacks.success() : null;
      return res.data;
    })
    .catch((error) => {
      log("Task Error: ", error.message);
      resultCallbacks ? resultCallbacks.failure() : null;
      return null;
    });
};

export const redeemCoupon = (couponId, resultCallbacks) => (dispatch) => {
  return authFetchWithTimeout(
    taskEndpoint,
    POST(TaskType.REDEEM_COUPON, couponId),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      dispatch(
        updateReceivedCouponAction(couponId, {
          status: CouponsConsts.STATUS_REDEEMED,
          isNew: true,
        })
      );
      resultCallbacks ? resultCallbacks.success() : null;
      return res.data;
    })
    .catch((error) => {
      log("Task Error: ", error.message);
      resultCallbacks ? resultCallbacks.failure() : null;
      return null;
    });
};

export const completeCoupon = (couponId, resultCallbacks) => (dispatch) => {
  return authFetchWithTimeout(
    taskEndpoint,
    POST(TaskType.COMPLETE_COUPON, couponId),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      dispatch(
        updateSentCouponAction(couponId, {
          status: CouponsConsts.STATUS_COMPLETED,
        })
      );
      resultCallbacks ? resultCallbacks.success() : null;
      return res.data;
    })
    .catch((error) => {
      log("Task Error: ", error.message);
      resultCallbacks ? resultCallbacks.failure() : null;
      return null;
    });
};

export const expireCoupon = (couponId) => (dispatch) => {
  return authFetchWithTimeout(
    taskEndpoint,
    POST(TaskType.EXPIRE_COUPON, couponId),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log("Task Error: ", error.message);
      return null;
    });
};

export const rateCoupon = (couponId, rating) => (dispatch) => {
  return authFetchWithTimeout(
    taskEndpoint,
    POST_RATING(TaskType.RATE_COUPON, couponId, rating),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      dispatch(
        updateReceivedCouponAction(couponId, {
          status: CouponsConsts.STATUS_RATED,
        })
      );
      return res.data;
    })
    .catch((error) => {
      log("Task Error: ", error.message);
      return null;
    });
};

export const TaskType = {
  ACCEPT_COUPON: "ACCEPT_COUPON",
  DECLINE_COUPON: "DECLINE_COUPON",
  REDEEM_COUPON: "REDEEM_COUPON",
  COMPLETE_COUPON: "COMPLETE_COUPON",
  EXPIRE_COUPON: "EXPIRE_COUPON",
  RATE_COUPON: "RATE_COUPON",
};
