import React, { useState, useEffect } from "react";
import { View, StyleSheet, Keyboard, Platform } from "react-native";
import { connect } from "react-redux";
import { TouchableOpacity } from "react-native-gesture-handler";

import { COMPONENT_COLOR_6, COMPONENT_COLOR_5 } from "../common/colors";
import HomeIcon from "../assets/images/home.svg";
import CouponIcon from "../assets/images/coupon.svg";
import FriendIcon from "../assets/images/friend.svg";
import { NavigationConsts } from "../common/constants";
import { avatars } from "../common/assets";
import Overlay from "../components/Overlay";

const TaskBar = (props) => {
  const [tabBarVisible, setTabBarVisible] = useState(true);

  useEffect(() => {
    let keyboardDidShow;
    let keyboardDidHide;
    let keyboardWillShow;
    let keyboardWillHide;
    if (Platform.OS === "android") {
      keyboardDidShow = Keyboard.addListener("keyboardDidShow", () =>
        setTabBarVisible(false)
      );
      keyboardDidHide = Keyboard.addListener("keyboardDidHide", () =>
        setTabBarVisible(true)
      );
      keyboardWillShow = Keyboard.addListener("keyboardWillShow", () =>
        setTabBarVisible(false)
      );
      keyboardWillHide = Keyboard.addListener("keyboardWillHide", () =>
        setTabBarVisible(true)
      );
    }

    return () => {
      if (keyboardDidHide) {
        keyboardDidHide.remove();
      }
      if (keyboardDidShow) {
        keyboardDidShow.remove();
      }
      if (keyboardWillHide) {
        keyboardWillHide.remove();
      }
      if (keyboardWillShow) {
        keyboardWillShow.remove();
      }
    };
  });

  const { userProfile } = props.profile;

  if (!tabBarVisible) {
    return null;
  }

  const routesWithLineSeparators = [];
  for (let index = 0; index < props.state.routes.length; index++) {
    const route = props.state.routes[index];
    routesWithLineSeparators.push(route);

    if (index < props.state.routes.length - 1) {
      routesWithLineSeparators.push(
        <View
          key={index + 1}
          style={{
            width: 0.5,
            height: 36,
            marginHorizontal: -24,
            backgroundColor: COMPONENT_COLOR_5,
            borderRadius: 4,
          }}
        ></View>
      );
    }
  }

  return (
    <View>
      <Overlay />
      <View style={style.container}>
        <View style={style.barContainer}>
          {routesWithLineSeparators.map((route, index) => {
            const lineSeparatorMultiple = 2;
            if (index % 2 !== 0) {
              return route;
            }

            const { options } = props.descriptors[route.key];
            const label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                ? options.title
                : route.name;

            const isFocused =
              props.state.index === index * lineSeparatorMultiple;

            const onPress = () => {
              const event = props.navigation.emit({
                type: "tabPress",
                target: route.key,
              });

              if (!isFocused && !event.defaultPrevented) {
                props.navigation.navigate(route.name);
              }
            };

            const onLongPress = () => {
              props.navigation.emit({
                type: "tabLongPress",
                target: route.key,
              });
            };
            let Icon = null;
            let IconSize = 22;
            const Avatar = avatars[userProfile.avatarID];
            if (route.name === NavigationConsts.HOME_STACK) {
              Icon = <HomeIcon width={IconSize} height={IconSize} />;
            } else if (route.name === NavigationConsts.COUPONS_STACK) {
              Icon = <CouponIcon width={IconSize} height={IconSize} />;
            } else if (route.name === NavigationConsts.BUDDIES_STACK) {
              Icon = <FriendIcon width={IconSize} height={IconSize} />;
            } else if (route.name === NavigationConsts.PROFILE_STACK) {
              Icon = <Avatar width={IconSize} height={IconSize} />;
            }

            return (
              <TouchableOpacity
                key={route.key}
                activeOpacity={1}
                accessibilityRole="button"
                accessibilityStates={isFocused ? ["selected"] : []}
                onPress={onPress}
                onLongPress={onLongPress}
                containerStyle={style.iconButton}
              >
                {Icon}
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, null)(TaskBar);

const style = StyleSheet.create({
  container: {
    height: 96,
  },
  barContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: COMPONENT_COLOR_6,
    marginHorizontal: 16,
    borderRadius: 10,
    marginBottom: 24,
    justifyContent: "space-around",
    alignItems: "center",
  },
  iconButton: {
    padding: 16,
  },
});
