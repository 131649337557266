import { CooponUI } from "../../../common/types";
import {
  FETCH_SENT_COUPONS,
  FETCH_RECEIVED_COUPONS,
  ADD_SENT_COUPON,
  ADD_RECEIVED_COUPON,
  UPDATE_SENT_COUPON,
  UPDATE_RECEIVED_COUPON,
  DELETE_SENT_COUPON,
  DELETE_RECEIVED_COUPON,
} from "../actions/CouponActionTypes";

type CooponState = {
  couponsSent: {
    coupons: CooponUI[];
  };
  couponsReceived: {
    coupons: CooponUI[];
  };
};

export const initialCouponState: CooponState = {
  couponsSent: {
    coupons: [],
  },
  couponsReceived: {
    coupons: [],
  },
};

export function coupons(state = initialCouponState, action) {
  switch (action.type) {
    case FETCH_SENT_COUPONS:
      return {
        ...state,
        couponsSent: {
          coupons: action.payload,
        },
      };
    case FETCH_RECEIVED_COUPONS:
      return {
        ...state,
        couponsReceived: {
          coupons: action.payload,
        },
      };
    case ADD_SENT_COUPON:
      return {
        ...state,
        couponsSent: {
          coupons: [...state.couponsSent.coupons, action.payload],
        },
      };
    case ADD_RECEIVED_COUPON:
      return {
        ...state,
        couponsReceived: {
          coupons: [...state.couponsReceived.coupons, action.payload],
        },
      };
    case UPDATE_SENT_COUPON:
      return {
        ...state,
        couponsSent: {
          coupons: updateCouponFromCoupons(
            state.couponsSent.coupons,
            action.couponId,
            action.newCouponValues
          ),
        },
      };
    case UPDATE_RECEIVED_COUPON:
      return {
        ...state,
        couponsReceived: {
          coupons: updateCouponFromCoupons(
            state.couponsReceived.coupons,
            action.couponId,
            action.newCouponValues
          ),
        },
      };
    case DELETE_SENT_COUPON:
      return {
        ...state,
        couponsSent: {
          coupons: state.couponsSent.coupons.filter(
            (coupon) => coupon.id !== action.couponId
          ),
        },
      };
    case DELETE_RECEIVED_COUPON:
      return {
        ...state,
        couponsReceived: {
          coupons: state.couponsReceived.coupons.filter(
            (coupon) => coupon.id !== action.couponId
          ),
        },
      };
    default: {
      return state;
    }
  }
}

const updateCouponFromCoupons = (
  coupons: CooponUI[],
  couponId: string,
  newCouponValues: CooponUI
): CooponUI[] => {
  const couponsToBeUpdated: CooponUI[] = coupons.slice();
  const oldCoupon = couponsToBeUpdated.find((coupon) => coupon.id === couponId);
  if (oldCoupon) {
    const oldCouponPosition = couponsToBeUpdated.findIndex(
      (coupon) => coupon.id === couponId
    );
    const newCoupon = {
      ...oldCoupon,
      ...newCouponValues,
    };
    couponsToBeUpdated.splice(oldCouponPosition, 1, newCoupon);
  }
  return couponsToBeUpdated;
};
