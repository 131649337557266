import * as Linking from "expo-linking";

import {
  initializeBinding,
  resetChannel,
} from "./coupons/services/PusherService";
import { store } from "../store";
import * as couponActions from "./coupons/actions/CouponActions";
import * as profileActions from "./coupons/actions/ProfileActions";
import {
  fetchReceivedCoupons,
  fetchSentCoupons,
  fetchSentCoupon,
  fetchReceivedCoupon,
} from "./coupons/services/CouponService";
import {
  getNotificationReceivedListener,
  getNotificationResponseReceivedListener,
  setNotificationHandler,
  removeNotificationHandler,
} from "./Notifications";
import { validateUUID } from "../common/validator";
import { NavigationConsts, CouponsConsts } from "../common/constants";
import { navigate } from "./TheNavigator";
import * as Sentry from "sentry-expo";
import { log } from "../common/logging";

let notificationReceivedSubscription = null;
let notificationResponseReceivedSubscription = null;

export const setupApp = async () => {
  Sentry.Native.captureMessage("Application is being setup");
  console.debug("Setting up APP");
  try {
    const state = store.getState();
    const userAccount = state.auth.userAccount;

    store.dispatch(fetchReceivedCoupons());
    store.dispatch(fetchSentCoupons());

    initializeBinding(userAccount, couponActions, profileActions, store);

    if (!notificationReceivedSubscription) {
      console.debug("Setting up received notification listener");
      notificationReceivedSubscription = getNotificationReceivedListener();
    }

    if (!notificationResponseReceivedSubscription) {
      console.debug("Setting up interaction notification listener");
      notificationResponseReceivedSubscription =
        getNotificationResponseReceivedListener();
    }

    setNotificationHandler();

    log("Setting up Linking listener");
    Linking.addEventListener("url", handleLinking);

    const initialUrl = await Linking.getInitialURL();
    console.info("Initial url", initialUrl);

    if (initialUrl) {
      handleLinking(initialUrl);
    }
  } catch (e) {
    Sentry.Native.captureException(e);
  }
};

export const teardownApp = () => {
  console.debug("Tearing down APP");
  try {
    if (notificationReceivedSubscription) {
      console.debug("Removing received notification listener");
      notificationReceivedSubscription.remove();
      notificationReceivedSubscription = null;
    }

    if (notificationResponseReceivedSubscription) {
      console.debug("Removing interaction notification listener");
      notificationResponseReceivedSubscription.remove();
      notificationResponseReceivedSubscription = null;
    }

    const state = store.getState();
    const userAccount = state.auth?.userAccount ?? null;
    resetChannel(userAccount);
    removeNotificationHandler();

    log("Remove Linking listener");
    Linking.removeEventListener("url", handleLinking);
  } catch (e) {
    Sentry.Native.captureException(e);
  }
};

/**
 * Bare: empty string
 * Standalone, Custom: yourscheme:///path
 * Web (dev): https://localhost:19006/path
 * Web (prod): https://myapp.com/path
 * Expo Client (dev): exp://128.0.0.1:19000/--/path
 * Expo Client (prod): exp://exp.host/@yourname/your-app/--/path
 *
 * @param {string} url
 */
const handleLinking = async (event) => {
  const { url } = event;
  console.info("Handle linking for url " + url);

  if (!url) {
    return;
  }

  let { hostname } = Linking.parse(url); // Returns for example, {"hostname": "10799169-e03c-470f-832e-43ffae641f14", "path": null, "queryParams": {}, "scheme": "coopons"}

  if (hostname) {
    try {
      handleSentLinkCooponID(hostname);
    } catch (error) {
      log(error.message);
      Sentry.Native.captureException(error);
    }
  } else {
    Sentry.Native.captureException(
      new Error("Failed to parse " + url + ". Hostname: " + hostname)
    );
  }
};

const handleSentLinkCooponID = async (path) => {
  validateUUID(path);

  let coopon = await fetchSentCoupon(path)(store.dispatch);
  console.info("Got SENT LINK coopon " + JSON.stringify(coopon));

  if (coopon && coopon.id) {
    const state = store.getState();
    const userAccount = state.auth.userAccount;
    console.info("Navigate away");
    const isCreatorViewingLink = userAccount.userId === coopon.senderId;
    navigate(NavigationConsts.COUPON_SCREEN, {
      incomingCoopon: coopon,
      couponId: coopon.id,
      listType: isCreatorViewingLink
        ? CouponsConsts.TYPE_SENT
        : CouponsConsts.TYPE_RECEIVED,
    });
  } else {
    console.debug("Failed to fetch coopon from link using path: " + path);
  }
  Sentry.Native.captureMessage("Handle linking for url " + path);
};

// TODO: Currently takes the user to the coupon view with type SENT when it should be type RECEIVED if this method is executed instead of handleSentLinkCooponID.
// Happens when inputting a SENT_LINK coupon ID
const handleUserCooponID = async (path) => {
  validateUUID(path);

  const state = store.getState();

  const { couponsSent, couponsReceived } = state.coupons;
  const { userProfile } = state.profile;

  const localSentCoopon = couponsSent.coupons.find(
    (coupon) => coupon.id === path && coupon.sender === userProfile.username
  );
  console.info("Got local SENT coopon " + JSON.stringify(localSentCoopon));
  if (localSentCoopon) {
    console.info("Navigate away");
    navigate(NavigationConsts.COUPON_SCREEN, {
      incomingCoopon: localSentCoopon,
      couponId: localSentCoopon.id,
      listType: CouponsConsts.TYPE_SENT,
    });
    return true;
  }

  const localReceivedCoopon = couponsReceived.coupons.find(
    (coupon) => coupon.id === path && coupon.receiver === userProfile.username
  );
  console.info(
    "Got local RECEIVED coopon " + JSON.stringify(localReceivedCoopon)
  );
  if (localReceivedCoopon) {
    console.info("Navigate away");
    navigate(NavigationConsts.COUPON_SCREEN, {
      incomingCoopon: localReceivedCoopon,
      couponId: localReceivedCoopon.id,
      listType: CouponsConsts.TYPE_RECEIVED,
    });
    return true;
  }

  let sentCoopon = await fetchSentCoupon(path)(store.dispatch);
  console.info("Got SENT coopon " + JSON.stringify(sentCoopon));
  if (sentCoopon && sentCoopon.id) {
    console.info("Navigate away");
    navigate(NavigationConsts.COUPON_SCREEN, {
      incomingCoopon: sentCoopon,
      couponId: sentCoopon.id,
      listType: CouponsConsts.TYPE_SENT,
    });
    return true;
  }

  let receivedCoopon = await fetchReceivedCoupon(path)(store.dispatch);
  console.info("Got RECEIVED coopon " + JSON.stringify(receivedCoopon));
  if (receivedCoopon && receivedCoopon.id) {
    console.info("Navigate away");
    navigate(NavigationConsts.COUPON_SCREEN, {
      incomingCoopon: receivedCoopon,
      couponId: receivedCoopon.id,
      listType: CouponsConsts.TYPE_RECEIVED,
    });
    return true;
  }
  return false;
};
