import React, { Component } from "react";

import { getUserProfileSuggestions } from "../modules/coupons/services/ProfileService";

export function composeProfileSuggestions(SuggestionsComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        searchingForSuggestions: false,
        userProfileSuggestions: [],
        userProfilesCache: {},
      };
    }

    handleFindProfile = async (profile) => {
      this.setState({ searchingForSuggestions: true });

      var userProfiles = [];
      if (this.validateFindProfile(profile)) {
        if (this.state.userProfilesCache[profile]) {
          userProfiles = this.state.userProfilesCache[profile];
        } else {
          userProfiles = await getUserProfileSuggestions(profile);
          if (userProfiles) {
            var cache = this.state.userProfilesCache;
            cache[profile] = userProfiles;
            this.setState({ userProfilesCache: profile });
          }
        }
      }

      if (Object.keys(this.state.userProfilesCache).length > 100) {
        this.setState({ userProfilesCache: {} });
      }

      this.setState({
        searchingForSuggestions: false,
        userProfileSuggestions: userProfiles ? userProfiles : [],
      });
    };

    validateFindProfile = (profile) => {
      const isUsernameShorterThan2Chars = profile.search(/^\w{2,}$/) === -1;
      const isUsernameTooLong = profile.search(/^\w{0,20}$/) === -1;
      const doesUsernameContainSpecialChars =
        profile.search(/^[\w\d_]+$/) === -1;

      return !(
        profile === "" ||
        isUsernameShorterThan2Chars ||
        isUsernameTooLong ||
        doesUsernameContainSpecialChars
      );
    };

    render = () => {
      return (
        <SuggestionsComponent
          profileSuggestions={this.state.userProfileSuggestions}
          isSearchingForSuggestions={this.state.searchingForSuggestions}
          handleFindProfile={this.handleFindProfile}
          {...this.props}
        />
      );
    };
  };
}
