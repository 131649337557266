import { StyleSheet } from "react-native";
import {
  BACKGROUND_COLOR,
  COMPONENT_COLOR_3,
  TEXT_COLOR_1,
  COMPONENT_COLOR_2,
} from "../common/colors";

export const editProfileStyle = StyleSheet.create({
  background: {
    flex: 1,
  },
  container: {
    backgroundColor: BACKGROUND_COLOR,
    margin: 24,
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  doneButtonLoading: {
    marginVertical: 11.5,
  },

  changeAvatarText: {
    color: TEXT_COLOR_1,
    fontSize: 18,
    marginLeft: 8,
  },

  userDetailsContainer: {
    marginHorizontal: 16,
  },
  userDetailsInputContainer: {
    backgroundColor: COMPONENT_COLOR_3,
    paddingHorizontal: 8,
    paddingVertical: 16,
    borderRadius: 8,
  },
  userDetailsInput: {},

  avatar: {
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },

  generalErrorText: {
    color: COMPONENT_COLOR_2,
    textAlign: "center",
    marginBottom: 8,
  },
});
