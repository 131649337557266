import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CouponsSentConsts } from "../../common/constants";
import CouponsList from "./CouponsList";
import {
  fetchSentCoupons,
  deleteSentCoupon,
} from "../../modules/coupons/services/CouponService";
import { refetchSentCoupons } from "../../modules/coupons/actions/AppConfigActions";

class CouponsSent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const { shouldRefetchSentCoupons } = this.props.config;

    if (shouldRefetchSentCoupons) {
      this.props.refetchSentCoupons(false);
      this.props.fetchSentCoupons();
    }
  }

  render() {
    const { coupons } = this.props.coupons.couponsSent;
    const { params } = this.props.route;

    return (
      <CouponsList
        navigation={this.props.navigation}
        routeParams={params}
        listType={CouponsSentConsts.COUPON_TYPE}
        data={coupons}
        fetchCoupons={this.props.fetchSentCoupons}
        deleteCoupon={this.props.deleteSentCoupon}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  coupons: state.coupons,
  config: state.config,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSentCoupons: fetchSentCoupons,
      deleteSentCoupon: deleteSentCoupon,
      refetchSentCoupons: refetchSentCoupons,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CouponsSent);
