import {
  FETCH_SENT_COUPONS,
  FETCH_RECEIVED_COUPONS,
  ADD_SENT_COUPON,
  ADD_RECEIVED_COUPON,
  UPDATE_SENT_COUPON,
  UPDATE_RECEIVED_COUPON,
  DELETE_SENT_COUPON,
  DELETE_RECEIVED_COUPON,
} from "./CouponActionTypes";

export const fetchSentCouponsAction = (coupons) => ({
  type: FETCH_SENT_COUPONS,
  payload: coupons,
});

export const fetchReceivedCouponsAction = (coupons) => ({
  type: FETCH_RECEIVED_COUPONS,
  payload: coupons,
});

export const addSentCouponAction = (coupon) => ({
  type: ADD_SENT_COUPON,
  payload: coupon,
});

export const addReceivedCouponAction = (coupon) => ({
  type: ADD_RECEIVED_COUPON,
  payload: coupon,
});

export const updateSentCouponAction = (couponId, couponValues) => ({
  type: UPDATE_SENT_COUPON,
  couponId: couponId,
  newCouponValues: couponValues,
});

export const updateReceivedCouponAction = (couponId, couponValues) => ({
  type: UPDATE_RECEIVED_COUPON,
  couponId: couponId,
  newCouponValues: couponValues,
});

export const deleteSentCouponAction = (couponId) => ({
  type: DELETE_SENT_COUPON,
  couponId: couponId,
});

export const deleteReceivedCouponAction = (couponId) => ({
  type: DELETE_RECEIVED_COUPON,
  couponId: couponId,
});
