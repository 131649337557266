import { StyleSheet } from "react-native";
import {
  BACKGROUND_COLOR,
  TEXT_COLOR_4,
  COMPONENT_COLOR_3,
  TExT_COLOR_2,
  TEXT_COLOR_1,
  TEXT_COLOR_3,
  COMPONENT_COLOR_4,
} from "../common/colors";

export const loginStyle = StyleSheet.create({
  safeareaViewContainer: {},
  container: {
    backgroundColor: BACKGROUND_COLOR,
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  contentContainer: {
    flex: 1,
  },
  inputContainer: {
    marginHorizontal: 24,
  },
  logoContainer: {
    alignSelf: "center",
  },
  userInputContainer: {
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 4,
    paddingHorizontal: 16,
    borderBottomColor: COMPONENT_COLOR_3,
    marginBottom: -16,
  },
  userInputText: {
    color: TEXT_COLOR_4,
    fontSize: 14,
  },
  signInButton: {
    marginTop: 24,
    marginBottom: 48,
  },
  signinLoading: {
    marginVertical: 11.5,
  },
  credentialError: {
    color: "red",
    fontSize: 12,
    alignSelf: "center",
    marginBottom: 6,
  },
  signupLink: {
    color: TEXT_COLOR_1,
  },

  title: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 20,
  },
  inputBoxes: {
    marginHorizontal: -10,
  },
  buttonContainer: {},
  button: {},
  alternativeLoginContainer: {},
  signUpSection: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 12,
  },
  resetMessageContainer: {
    backgroundColor: COMPONENT_COLOR_4,
    marginVertical: 4,
    padding: 8,
    borderRadius: 4,
  },
  resetMessage: {
    color: TEXT_COLOR_3,
    textAlign: "center",
  },
  orSignInWithContainer: {
    flexDirection: "column",
    marginTop: 16,
  },
  orSignInText: {
    textAlign: "center",
    paddingBottom: 8,
  },
  googleSignInContainer: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: 8,
    borderRadius: 8,
    height: 40,
  },
  // STYLE for ONLY GOOGLE ACCOUNT
  // orSignInWithContainer: {
  //     flexDirection: 'column',
  // },
  // orSignInText: {
  //     textAlign: 'center',
  //     fontSize: 16,
  //     fontWeight: '500',
  //     color: 'gray',
  //     paddingLeft: 16,
  // },
  // googleSignInContainer: {
  //     backgroundColor: '#ffffff',
  //     flexDirection: 'row',
  //     justifyContent: 'flex-start',
  //     alignItems: 'center',
  //     padding: 8,
  //     borderRadius: 8,
  //     height: 60,
  // },
});
