import { Dimensions } from "react-native";
import { ALT_APP_COLOR_1 } from "./colors";

export const dimensions = {
  FULLWIDTH: Dimensions.get("window").width,
  FULLHEIGHT: Dimensions.get("window").height,
};

export const getValueBasedOnScreenSize = (
  small: number | string,
  medium: number | string
) => {
  const screenWidth = Dimensions.get("window").width;
  const screenHeight = Dimensions.get("window").height;
  const totalPixels = screenHeight * screenWidth;
  const sqrtValue = Math.sqrt(totalPixels);
  if (sqrtValue > 480) {
    return medium;
  } else {
    return small;
  }
};

export const getValueBasedOnWidth = (
  threshold: number,
  small: string | number,
  medium: string | number
): any => {
  const screenWidth = Dimensions.get("window").width;
  if (screenWidth > threshold) {
    return medium;
  } else {
    return small;
  }
};

export const H1_TEXT = {
  fontSize: 32, //was 2rem
  color: ALT_APP_COLOR_1,
  margin: 8, //was 0.5rem
  alignSelf: "center",
  fontWeight: "600",
};

export const FONT_SIZE_1 = 40;
export const FONT_SIZE_2 = 32;
export const FONT_SIZE_3 = 24;
export const FONT_SIZE_4 = 16;

export const ICON_SMALLER = 20;
export const ICON_SMALL = 32;
