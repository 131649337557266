import Constants from "expo-constants";
import { Platform } from "react-native";

export const STAGING = "staging";
export const PRODUCTION = "production";

const REALM = Constants.expoConfig.extra.REALM;

export const getApiURL = (): string => {
  if (Platform.OS === "web" || REALM === PRODUCTION) {
    return "https://coopons.api.psyntax.org";
  } else if (REALM === STAGING || !REALM || __DEV__) {
    return "http://192.168.1.69:8080";
  }
};

export const getSentryURL = (): string => {
  return "https://2647e92aefd8455f9e549843f1404ff1@o411166.ingest.sentry.io/5368013";
};

export const getPusherId = (): string => {
  if (REALM === PRODUCTION) {
    return "8bf4c209b9f7a3e863cd";
  } else if (REALM === STAGING || !REALM || __DEV__) {
    return "0bc0705eb61b32801faf";
  }
};

export const getUpdateID = (): string => {
  return "EAS Update 9";
};
