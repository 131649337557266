import * as MailComposer from "expo-mail-composer";
import { StyleSheet, Text, View } from "react-native";
import { BACKGROUND_COLOR, TEXT_COLOR_3 } from "../../../common/colors";

const CONTACT_EMAIL = "psyntaxapps@gmail.com";

export const ContactUs = () => {
  return (
    <View style={style.container}>
      <Text style={style.text}>Reach out to us by email!</Text>
      <Text style={style.email} selectable={true}>
        {CONTACT_EMAIL}
      </Text>
    </View>
  );
};

export const isEmailComposerAvailable = async () =>
  await MailComposer.isAvailableAsync();

export const openEmailPrompt = async () => {
  return MailComposer.isAvailableAsync().then(async (isAvailable) => {
    if (isAvailable) {
      const mailComposeStatus = await MailComposer.composeAsync({
        recipients: [CONTACT_EMAIL],
      });
      return mailComposeStatus;
    }
  });
};

const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: BACKGROUND_COLOR,
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 16,
  },
  email: {
    fontSize: 24,
    fontWeight: "500",
  },
});
