import { StyleSheet } from "react-native";

import {
  BACKGROUND_COLOR,
  COMPONENT_COLOR_1,
  COMPONENT_COLOR_3,
  COMPONENT_COLOR_5,
  TEXT_COLOR_1,
  TEXT_COLOR_4,
} from "../common/colors";

export const createCouponStyle = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: BACKGROUND_COLOR,
  },
  inputContainer: {},
  keyboardviewContainer: {
    flex: 1, // Allows input boxes to scroll on top of keyboard view
  },
  couponDetailsContainer: {
    marginHorizontal: 16,
  },
  couponDetailsTitle: {
    marginVertical: 16,
    color: TEXT_COLOR_4,
  },
  couponDetailsInputContainer: {
    backgroundColor: COMPONENT_COLOR_3,
    paddingHorizontal: 8,
    paddingVertical: 16,
    borderRadius: 8,
  },
  couponDetailsInput: {},
  receiverScrollView: {
    maxHeight: "200%",
    marginHorizontal: 8,
    zIndex: 3,
  },
  submitButtonContainer: {
    margin: 8,
  },
  submitButton: {},

  buddyContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 8,
    marginHorizontal: 4,
  },
  buddyInfo: {
    flexDirection: "row",
  },
  buddyUsername: {},
  buddyFullname: {
    marginLeft: 4,
  },
  buddyChecked: {},
  shareLinkOptionContainer: {},
  shareLinkOption: {
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: COMPONENT_COLOR_3,
    borderColor: COMPONENT_COLOR_1,
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 8,
    marginHorizontal: 8,
    marginBottom: 12,
  },
  shareLinkOptionText: {
    color: TEXT_COLOR_1,
  },
  orContainer: {
    justifyContent: "center",
    flexDirection: "row",
  },
  orText: {
    fontSize: 18,
    color: COMPONENT_COLOR_5,
    fontStyle: "italic",
  },
});
