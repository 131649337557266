import React from "react";
import { View, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import {
  COMPONENT_COLOR_3,
  BACKGROUND_COLOR,
  TEXT_COLOR_6,
} from "../common/colors";
import { Button } from "../common/Button";
import { NavigationConsts } from "../common/constants";

export const CouponsBar = (props) => {
  let headerTitle;
  const screenName = props.state.routes[props.state.index].name;
  if (screenName === NavigationConsts.RECEIVED_SCREEN) {
    headerTitle = "Received Coupons";
  } else if (screenName === NavigationConsts.SENT_SCREEN) {
    headerTitle = "Sent Coupons";
  }

  const iconSize = 20;

  return (
    <View style={style.container}>
      <View style={style.barContainer}>
        {props.state.routes.map((route, index) => {
          const { options } = props.descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = props.state.index === index;

          const onPress = () => {
            const event = props.navigation.emit({
              type: "tabPress",
              target: route.key,
            });

            if (!isFocused && !event.defaultPrevented) {
              props.navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            props.navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };

          let buttonLabel = null;
          if (route.name === NavigationConsts.RECEIVED_SCREEN) {
            buttonLabel = NavigationConsts.RECEIVED_LABEL;
          } else if (route.name === NavigationConsts.SENT_SCREEN) {
            buttonLabel = NavigationConsts.SENT_LABEL;
          }

          return (
            <TouchableOpacity
              key={route.key}
              activeOpacity={1}
              accessibilityRole="button"
              accessibilityStates={isFocused ? ["selected"] : []}
              onPress={onPress}
              onLongPress={onLongPress}
              containerStyle={style.buttonContainer}
            >
              <Button type={isFocused ? 1 : 3} activeOpacity={1}>
                {buttonLabel}
              </Button>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    flexDirection: "column",
    backgroundColor: BACKGROUND_COLOR,
  },
  header: {
    marginHorizontal: 16,
    marginTop: 12,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  headerTitle: {
    color: TEXT_COLOR_6,
    fontWeight: "bold",
    fontSize: 28,
  },
  headerOptions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  icons: {
    marginLeft: 24,
  },
  barContainer: {
    flexDirection: "row",
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 8,
    justifyContent: "space-evenly",
    alignItems: "center",
    marginVertical: 4,
    marginHorizontal: 12,
  },
  buttonContainer: {
    flex: 1,
  },
});
