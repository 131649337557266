import Avatar1 from "../assets/avatars/avatar_1.svg";
import Avatar2 from "../assets/avatars/avatar_2.svg";
import Avatar3 from "../assets/avatars/avatar_3.svg";
import Avatar4 from "../assets/avatars/avatar_4.svg";
import Avatar5 from "../assets/avatars/avatar_5.svg";
import Avatar6 from "../assets/avatars/avatar_6.svg";
import Avatar7 from "../assets/avatars/avatar_7.svg";
import Avatar8 from "../assets/avatars/avatar_8.svg";
import Avatar9 from "../assets/avatars/avatar_9.svg";
import Avatar10 from "../assets/avatars/avatar_10.svg";
import Avatar11 from "../assets/avatars/avatar_11.svg";
import Avatar12 from "../assets/avatars/avatar_12.svg";
import Avatar13 from "../assets/avatars/avatar_13.svg";
import Avatar14 from "../assets/avatars/avatar_14.svg";
import Avatar15 from "../assets/avatars/avatar_15.svg";
import Avatar16 from "../assets/avatars/avatar_16.svg";
import Avatar17 from "../assets/avatars/avatar_17.svg";
import Avatar18 from "../assets/avatars/avatar_18.svg";
import Avatar19 from "../assets/avatars/avatar_19.svg";
import Avatar20 from "../assets/avatars/avatar_20.svg";
import Avatar21 from "../assets/avatars/avatar_21.svg";
import Avatar22 from "../assets/avatars/avatar_22.svg";
import Avatar23 from "../assets/avatars/avatar_23.svg";
import Avatar24 from "../assets/avatars/avatar_24.svg";
import Avatar25 from "../assets/avatars/avatar_25.svg";
import Avatar26 from "../assets/avatars/avatar_26.svg";
import Avatar27 from "../assets/avatars/avatar_27.svg";
import Avatar28 from "../assets/avatars/avatar_28.svg";
import Avatar29 from "../assets/avatars/avatar_29.svg";
import Avatar30 from "../assets/avatars/avatar_30.svg";
import Avatar31 from "../assets/avatars/avatar_31.svg";
import Avatar32 from "../assets/avatars/avatar_32.svg";
import Avatar33 from "../assets/avatars/avatar_33.svg";
import Avatar34 from "../assets/avatars/avatar_34.svg";
import Avatar35 from "../assets/avatars/avatar_35.svg";
import Avatar36 from "../assets/avatars/avatar_36.svg";
import Avatar37 from "../assets/avatars/avatar_37.svg";
import Avatar38 from "../assets/avatars/avatar_38.svg";
import Avatar39 from "../assets/avatars/avatar_39.svg";
import Avatar40 from "../assets/avatars/avatar_40.svg";
import Avatar41 from "../assets/avatars/avatar_41.svg";
import Avatar42 from "../assets/avatars/avatar_42.svg";
import Avatar43 from "../assets/avatars/avatar_43.svg";
import Avatar44 from "../assets/avatars/avatar_44.svg";
import Avatar45 from "../assets/avatars/avatar_45.svg";
import Avatar46 from "../assets/avatars/avatar_46.svg";
import Avatar47 from "../assets/avatars/avatar_47.svg";
import Avatar48 from "../assets/avatars/avatar_48.svg";
import Avatar49 from "../assets/avatars/avatar_49.svg";
import Avatar50 from "../assets/avatars/avatar_50.svg";

export const avatars = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar20,
  Avatar21,
  Avatar22,
  Avatar23,
  Avatar24,
  Avatar25,
  Avatar26,
  Avatar27,
  Avatar28,
  Avatar29,
  Avatar30,
  Avatar31,
  Avatar32,
  Avatar33,
  Avatar34,
  Avatar35,
  Avatar36,
  Avatar37,
  Avatar38,
  Avatar39,
  Avatar40,
  Avatar41,
  Avatar42,
  Avatar43,
  Avatar44,
  Avatar45,
  Avatar46,
  Avatar47,
  Avatar48,
  Avatar49,
  Avatar50,
];
