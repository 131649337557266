import React from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SafeAreaView } from "react-native-safe-area-context";
import jwt_decode from "jwt-decode";

import {
  getUserAccount,
  getAuthToken,
  updateUserAccount,
  signOut,
} from "../../modules/coupons/services/AuthService";
import {
  updateJwtTokenAction,
  authorizeUserAccessAction,
} from "../../modules/coupons/actions/AuthActions";
import { getUserProfile } from "../../modules/coupons/services/ProfileService";
import { updateUserProfileAction } from "../../modules/coupons/actions/ProfileActions";
import { COMPONENT_COLOR_1 } from "../../common/colors";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Sentry from "sentry-expo";

export class Authorizing extends React.Component {
  async componentDidMount() {
    await this.beginNavigatingUser();
  }

  async beginNavigatingUser() {
    console.info("Getting authorization token from storage");
    const authToken = await getAuthToken();

    if (authToken) {
      const decodedToken = await jwt_decode(authToken);
      const now = new Date();
      const nowInSeconds = Math.round(now / 1000);
      console.info("Decoded token: ", decodedToken);
      console.info("Auth token available from storage: ", authToken);
      if (decodedToken.exp < nowInSeconds) {
        await this.props.signOut();
        return;
      }
      try {
        await this.props.getUserAccount();
        await this.props.getUserProfile();
        this.props.updateJwtTokenAction(authToken);
        console.info(
          "Retrieved user account, profile and token. Granting access."
        );
        this.props.authorizeUserAccessAction();
      } catch (e) {
        console.info(
          "Failed to retrive user account and/or profile, fetching from storage"
        );
        const userAccount = await this.getStoredUserAccount();
        const userProfile = await this.getStoredUserProfile();
        console.info("User account from storage: ", userAccount);
        console.info("User profile from storage: ", userProfile);
        if (userAccount && userProfile) {
          this.props.updateUserAccount(userAccount);
          this.props.updateUserProfileAction(userProfile);
          this.props.updateJwtTokenAction(authToken);
          console.info(
            "Retrieved user account and profile from storage. Granting access."
          );
          this.props.authorizeUserAccessAction();
        } else {
          console.info(
            "Stored account or profile does not exist. Signing user out"
          );
          await this.props.signOut();
        }
      }
    } else {
      console.info("Token not available from storage. Signing user out");
      await this.props.signOut();
    }
  }

  getStoredUserAccount = async () => {
    try {
      return await AsyncStorage.getItem("@user_account").then((value) =>
        JSON.parse(value)
      );
    } catch (e) {
      Sentry.Native.captureException(e);
      return null;
    }
  };

  getStoredUserProfile = async () => {
    try {
      return await AsyncStorage.getItem("@user_profile").then((value) =>
        JSON.parse(value)
      );
    } catch (e) {
      Sentry.Native.captureException(e);
      return null;
    }
  };

  render() {
    return (
      <SafeAreaView style={style.authorizingScreen}>
        <ActivityIndicator size="large" color={COMPONENT_COLOR_1} />
      </SafeAreaView>
    );
  }
}

const style = StyleSheet.create({
  authorizingScreen: {
    flex: 1,
    justifyContent: "center",
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserAccount: getUserAccount,
      updateUserAccount: updateUserAccount,
      updateJwtTokenAction: updateJwtTokenAction,
      authorizeUserAccessAction: authorizeUserAccessAction,
      signOut: signOut,
      getUserProfile: getUserProfile,
      updateUserProfileAction: updateUserProfileAction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Authorizing);
