export const validateUsername = (username, setErrorMessage) => {
  const isUsernameShorterThan3Chars = username.search(/^.{3,}$/) === -1;
  const isUsernameTooLong = username.search(/^\w{0,20}$/) === -1;
  const doesUsernameContainSpecialChars = username.search(/^[\w\d_]+$/) === -1;

  if (!username.trim()) {
    setErrorMessage(ValidityConsts.REQUIRED_FIELD_ERROR);
    throw new Error(ValidityConsts.REQUIRED_FIELD_ERROR);
  }
  if (isUsernameShorterThan3Chars) {
    setErrorMessage(ValidityConsts.USERNAME_ERROR_1);
    throw new Error(ValidityConsts.USERNAME_ERROR_1);
  }
  if (doesUsernameContainSpecialChars) {
    setErrorMessage(ValidityConsts.USERNAME_ERROR_2);
    throw new Error(ValidityConsts.USERNAME_ERROR_2);
  }
  if (isUsernameTooLong) {
    setErrorMessage(ValidityConsts.USERNAME_ERROR_3);
    throw new Error(ValidityConsts.USERNAME_ERROR_3);
  }
};

export const validateEmailAddress = (emailAddress, setErrorMessage) => {
  const isEmailAddressInvalid =
    emailAddress.search(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) === -1;

  if (!emailAddress.trim()) {
    setErrorMessage(ValidityConsts.REQUIRED_FIELD_ERROR);
    throw new Error(ValidityConsts.REQUIRED_FIELD_ERROR);
  }
  if (isEmailAddressInvalid) {
    setErrorMessage(ValidityConsts.EMAIL_ADDR_ERROR_1);
    throw new Error(ValidityConsts.EMAIL_ADDR_ERROR_1);
  }
};

export const vallidateFullname = (fullname, setErrorMessage) => {
  const doesFullnameContainSpecialChars =
    fullname.search(/^[a-zA-Z0-9 ]*$/) === -1;
  const isFullnameTooLong = fullname.search(/^.{0,30}$/) === -1;

  if (isFullnameTooLong) {
    setErrorMessage(ValidityConsts.NAME_ERROR_2);
    throw new Error(ValidityConsts.NAME_ERROR_2);
  }
  if (doesFullnameContainSpecialChars) {
    setErrorMessage(ValidityConsts.NAME_ERROR_3);
    throw new Error(ValidityConsts.NAME_ERROR_3);
  }
};

export const validatePassword = (password, setErrorMessage) => {
  const isPasswordTooShort = password.search(/^.{6,}$/) === -1;

  if (isPasswordTooShort) {
    setErrorMessage(ValidityConsts.PASSWORD_ERROR_1);
    throw new Error(ValidityConsts.PASSWORD_ERROR_1);
  }
};

export const validateUUID = (uuid, setErrorMessage = (a) => {}) => {
  const isNotUUID =
    uuid.search(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    ) === -1;

  if (isNotUUID) {
    setErrorMessage(ValidityConsts.UUID_ERROR_1 + " " + uuid);
    throw new Error(ValidityConsts.UUID_ERROR_1 + " " + uuid);
  }
};

const ValidityConsts = {
  REQUIRED_FIELD_ERROR: "Required",
  EMAIL_ADDR_ERROR_1: "Invalid email address",

  USERNAME_ERROR_1: "Username must be longer than 3 characters",
  USERNAME_ERROR_2: "Username cannot contain special characters",
  USERNAME_ERROR_3: "Username is too long",

  NAME_ERROR_2: "Name is too long",
  NAME_ERROR_3: "Name cannot contain special characters",

  PASSWORD_ERROR_1: "Password must be longer than 6 characters",
  PASSWORD_ERROR_2: "Provided passwords do not match",

  UUID_ERROR_1: "Failed to validate UUID",
};
