import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Text,
  View,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Animated,
  Platform,
  UIManager,
  LayoutAnimation,
  Vibration,
} from "react-native";
import PropTypes from "prop-types";

import { couponStyle } from "../styles/CouponStyle";
import {
  COMPONENT_COLOR_4,
  COMPONENT_COLOR_2,
  COMPONENT_COLOR_3,
  COMPONENT_COLOR_1,
  TEXT_COLOR_1,
  COUPON_STATE_COLOR_2,
  COUPON_STATE_COLOR_5,
  BUTTON_COLOR_2,
  COUPON_STATE_COLOR_4,
  COUPON_STATE_COLOR_6,
} from "../common/colors";
import {
  CouponsConsts,
  NavigationConsts,
  ProfileConsts,
} from "../common/constants";
import {
  createReceivedCoupon,
  deleteSentCoupon,
  deleteReceivedCoupon,
} from "../modules/coupons/services/CouponService";
import { Button } from "../common/Button";
import {
  CouponHeader,
  CouponBody,
  handleCompleteConfirmationDialog,
} from "./CouponComponents";
import {
  acceptCoupon,
  redeemCoupon,
  completeCoupon,
  rejectCoupon,
  rateCoupon,
  expireCoupon,
} from "../modules/coupons/services/TaskService";
import HandshakeSent from "../assets/images/handshake.svg";
import HandshakeReceived from "../assets/images/handshake-white.svg";
import Hourglass from "../assets/images/hourglass_1.svg";
import CompletedSent from "../assets/images/completed_coupon_2.svg";
import CompletedReceived from "../assets/images/completed_coupon_1.svg";
import RejectedCoupon from "../assets/images/rejected_coupon_1.svg";
import ExpiredSent from "../assets/images/expired_coupon_1.svg";
import ExpiredReceived from "../assets/images/expired_coupon_2.svg";
import SentCoupon from "../assets/images/sent_coupon_1.svg";
import ReceivedCoupon from "../assets/images/received_coupon_1.svg";
import RatedIcon from "../assets/images/rated.svg";
import {
  updateSentCouponAction,
  updateReceivedCouponAction,
  deleteSentCouponAction,
  deleteReceivedCouponAction,
} from "../modules/coupons/actions/CouponActions";
import { dimensions } from "../common/dimensions";
import { log } from "../common/logging";

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.newOpacityAnimation = new Animated.Value(0);
    this.deleteCouponAnimation = new Animated.Value(0);
    this.inProgressAnimationReceiver = new Animated.Value(0);
    this.inProgressAnimationSender = new Animated.Value(0);

    if (Platform.OS === "android") {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
  }

  componentDidMount = () => {
    const { isNew, status, listType } = this.props;

    this.initiateAnimations(isNew, status, listType);
  };

  componentDidUpdate = (prevProps) => {
    const { isNew, status, listType } = this.props;

    if (prevProps.status !== status) {
      this.initiateAnimations(isNew, status, listType);
    }
  };

  initiateAnimations = (isNew, status, listType) => {
    if (isNew) {
      Animated.loop(
        Animated.timing(this.newOpacityAnimation, {
          toValue: 1,
          duration: 3000,
          useNativeDriver: false,
        }),
        {
          iterations: -1,
        }
      ).start();
    }

    if (
      status === CouponsConsts.STATUS_REDEEMED &&
      listType === CouponsConsts.TYPE_RECEIVED
    ) {
      Animated.loop(
        Animated.timing(this.inProgressAnimationReceiver, {
          toValue: 1,
          duration: 2000,
          useNativeDriver: false,
        }),
        {
          iterations: -1,
        }
      ).start();
    }

    if (status === CouponsConsts.STATUS_WAITING_FOR_COMPLETION) {
      Animated.loop(
        Animated.timing(this.inProgressAnimationSender, {
          toValue: 1,
          duration: 2000,
          useNativeDriver: false,
        }),
        {
          iterations: -1,
        }
      ).start();
    }
  };

  handlePress = () => {
    this.props.navigation.navigate(NavigationConsts.COUPON_SCREEN, {
      listType: this.props.listType,
      couponId: this.props.couponId,
    });
  };

  handleAcceptPress = async (cbs) => {
    const { couponId, acceptCoupon } = this.props;

    await acceptCoupon(couponId, cbs);
  };

  handleRejectPress = async (cbs) => {
    const { couponId } = this.props;

    await this.props.rejectCoupon(couponId, cbs);
  };

  handleDeletePress = async (cbs) => {
    const { couponId, listType } = this.props;

    if (listType === CouponsConsts.TYPE_RECEIVED) {
      await this.props.deleteReceivedCoupon(couponId, cbs, true);
    } else if (listType === CouponsConsts.TYPE_SENT) {
      await this.props.deleteSentCoupon(couponId, cbs, true);
    }
  };

  handleDeleteCouponAnimation = () => {
    Animated.timing(this.deleteCouponAnimation, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start(() => {
      const { couponId, listType } = this.props;

      if (listType === CouponsConsts.TYPE_RECEIVED) {
        this.props.deleteReceivedCouponAction(couponId);
      } else if (listType === CouponsConsts.TYPE_SENT) {
        this.props.deleteSentCouponAction(couponId);
      }
      LayoutAnimation.configureNext(
        LayoutAnimation.create(
          250,
          LayoutAnimation.Types.easeInEaseOut,
          LayoutAnimation.Properties.opacity
        )
      );
    });
  };

  handleRedeemPress = async (cbs) => {
    const { couponId } = this.props;

    await this.props.redeemCoupon(couponId, cbs);
  };

  handleCompletePress = async (cbs) => {
    const { couponId } = this.props;

    await this.props.completeCoupon(couponId, cbs);
  };

  handleRating = async (rating) => {
    const { couponId } = this.props;

    await this.props.rateCoupon(couponId, rating);
  };

  handleUserPress = (username, displayName) => {
    this.props.navigation.navigate(NavigationConsts.PROFILE_SCREEN, {
      username: username,
      displayName: displayName,
    });
  };

  handleExpiration = () => {
    const { couponId, status, listType } = this.props;

    if (
      status === CouponsConsts.STATUS_REQUEST ||
      status === CouponsConsts.STATUS_ACCEPTED
    ) {
      this.props.expireCoupon(couponId);
      if (listType === CouponsConsts.TYPE_RECEIVED) {
        this.props.updateReceivedCouponAction(couponId, {
          status: CouponsConsts.STATUS_EXPIRED,
        });
      } else if (listType === CouponsConsts.TYPE_SENT) {
        this.props.updateSentCouponAction(couponId, {
          status: CouponsConsts.STATUS_EXPIRED,
        });
      }
    }
  };

  onSuccessfulTask = () => {
    const { couponId, scrollToCoupon } = this.props;

    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
    scrollToCoupon(couponId);
  };

  handleLongPress = () => {
    __DEV__ ? log(JSON.stringify(this.props)) : null;

    const { couponId, listType } = this.props;

    if (listType === CouponsConsts.TYPE_SENT) {
      this.props.updateSentCouponAction(couponId, { isNew: false });
    } else if (listType === CouponsConsts.TYPE_RECEIVED) {
      this.props.updateReceivedCouponAction(couponId, { isNew: false });
    }
    const DURATION = 50;
    Vibration.vibrate(Platform.OS === "android" ? DURATION : [DURATION], false);
  };

  render() {
    var {
      couponId,
      listType,
      status,
      name,
      description,
      receiver,
      sender,
      senderDisplayName,
      receiverDisplayName,
      senderAvatarId,
      receiverAvatarId,
      startDate,
      endDate,
      isNew,
    } = this.props;

    const interpolateNewCouponColor = this.newOpacityAnimation.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [COMPONENT_COLOR_3, COUPON_STATE_COLOR_4, COMPONENT_COLOR_3],
    });

    const interpolateDeletePosition = this.deleteCouponAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, dimensions.FULLWIDTH],
    });

    const interpolateInProgressReceiverColor =
      this.inProgressAnimationReceiver.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [
          COMPONENT_COLOR_3,
          COUPON_STATE_COLOR_2,
          COMPONENT_COLOR_3,
        ],
      });

    const interpolateInProgressSenderColor =
      this.inProgressAnimationSender.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [
          COUPON_STATE_COLOR_6,
          COUPON_STATE_COLOR_5,
          COUPON_STATE_COLOR_6,
        ],
      });

    description = !description ? CouponsConsts.NO_DESCRIPTION : description;

    const statusIconContainer = getStatusIconContainer(status, listType);
    const targetUser = listType === CouponsConsts.TYPE_SENT ? receiver : sender;
    return (
      <React.Fragment>
        <TouchableWithoutFeedback
          onLongPress={this.handleLongPress}
          onPress={this.handlePress}
        >
          <Animated.View
            style={[
              couponStyle.container,
              isNew ? { backgroundColor: interpolateNewCouponColor } : null,
              { transform: [{ translateX: interpolateDeletePosition }] },
              status === CouponsConsts.STATUS_REDEEMED &&
              listType === CouponsConsts.TYPE_RECEIVED
                ? {
                    borderColor: interpolateInProgressReceiverColor,
                    borderWidth: 4,
                  }
                : null,
              status === CouponsConsts.STATUS_WAITING_FOR_COMPLETION
                ? {
                    borderColor: interpolateInProgressSenderColor,
                    borderWidth: 4,
                  }
                : null,
            ]}
          >
            <View style={couponStyle.leftContainer}>
              <View style={statusIconContainer.statusIconContainer}>
                <View style={statusIconContainer.statusIcon.iconStyle}>
                  {statusIconContainer.statusIcon.icon}
                </View>
              </View>
              {status === CouponsConsts.STATUS_RATED ? (
                <View style={couponStyle.ratedIcon}>
                  <RatedIcon width={25} height={25} />
                </View>
              ) : null}
            </View>
            <View style={couponStyle.contents}>
              <CouponHeader
                name={name}
                startDate={startDate}
                endDate={endDate}
              />
              <CouponBody
                listType={listType}
                sender={sender}
                receiver={receiver}
                senderDisplayName={senderDisplayName}
                receiverDisplayName={receiverDisplayName}
                senderAvatarId={senderAvatarId}
                receiverAvatarId={receiverAvatarId}
                status={status}
                startDate={startDate}
                endDate={endDate}
                handleUserPress={this.handleUserPress}
                handleCouponPress={this.handlePress}
                handleExpiration={this.handleExpiration}
              />
              <View style={couponStyle.options}>
                <Options
                  couponType={listType}
                  status={status}
                  receiver={receiver}
                  receiverDisplayName={receiverDisplayName}
                  couponTitle={name}
                  onPressAccept={this.handleAcceptPress}
                  onPressReject={this.handleRejectPress}
                  onPressDelete={this.handleDeletePress}
                  deleteAnimation={this.handleDeleteCouponAnimation}
                  onPressRedeem={this.handleRedeemPress}
                  onPressComplete={this.handleCompletePress}
                  onRateCoupon={this.handleRating}
                  onSuccessfulTask={this.onSuccessfulTask}
                />
              </View>
            </View>
          </Animated.View>
        </TouchableWithoutFeedback>
      </React.Fragment>
    );
  }
}

Coupon.propTypes = {
  couponId: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  receiver: PropTypes.string,
  sender: PropTypes.string.isRequired,
  senderDisplayName: PropTypes.string,
  receiverDisplayName: PropTypes.string,
  senderAvatarId: PropTypes.number.isRequired,
  receiverAvatarId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,

  acceptCoupon: PropTypes.func.isRequired,
  rejectCoupon: PropTypes.func.isRequired,
  deleteSentCoupon: PropTypes.func.isRequired,
  deleteReceivedCoupon: PropTypes.func.isRequired,
  redeemCoupon: PropTypes.func.isRequired,
  completeCoupon: PropTypes.func.isRequired,
  rateCoupon: PropTypes.func.isRequired,
  createReceivedCoupon: PropTypes.func.isRequired,
};

function getStatusIconContainer(status, couponType) {
  let iconContainerStyle = {};
  let statusIcon;
  let statusIconStyle = {};
  switch (status) {
    case CouponsConsts.STATUS_SENT:
    case CouponsConsts.STATUS_SENT_LINK:
    case CouponsConsts.STATUS_REQUEST:
      iconContainerStyle = {
        backgroundColor:
          couponType == CouponsConsts.TYPE_SENT
            ? COMPONENT_COLOR_3
            : COMPONENT_COLOR_1,
        borderColor: COMPONENT_COLOR_1,
        borderWidth: 1,
      };
      statusIcon =
        status === CouponsConsts.STATUS_SENT ||
        status === CouponsConsts.STATUS_SENT_LINK ? (
          <SentCoupon width={40} height={40} />
        ) : (
          <ReceivedCoupon width={40} height={40} />
        );
      break;
    case CouponsConsts.STATUS_ACCEPTED:
      iconContainerStyle = {
        backgroundColor:
          couponType == CouponsConsts.TYPE_SENT
            ? COMPONENT_COLOR_3
            : COMPONENT_COLOR_1,
        borderColor: COMPONENT_COLOR_1,
        borderWidth: 1,
      };
      statusIcon =
        couponType == CouponsConsts.TYPE_SENT ? (
          <HandshakeSent width={40} height={40} />
        ) : (
          <HandshakeReceived width={40} height={40} />
        );
      statusIconStyle = { color: TEXT_COLOR_1 };
      break;
    case CouponsConsts.STATUS_REDEEMED:
    case CouponsConsts.STATUS_WAITING_FOR_COMPLETION:
      iconContainerStyle = {
        backgroundColor:
          couponType === CouponsConsts.TYPE_SENT
            ? COMPONENT_COLOR_3
            : COUPON_STATE_COLOR_2,
        borderColor: COUPON_STATE_COLOR_5,
        borderWidth: couponType === CouponsConsts.TYPE_SENT ? 1 : 0,
      };
      statusIcon =
        status === CouponsConsts.STATUS_WAITING_FOR_COMPLETION ? (
          <Text
            style={[couponStyle.statusIcon, { color: COUPON_STATE_COLOR_5 }]}
          >
            {CouponsConsts.STATUS_GO_ICON}
          </Text>
        ) : (
          <Hourglass width={40} height={40} />
        );
      break;
    case CouponsConsts.STATUS_COMPLETED:
    case CouponsConsts.STATUS_RATED:
      iconContainerStyle = {
        backgroundColor:
          couponType == CouponsConsts.TYPE_SENT
            ? COMPONENT_COLOR_3
            : COMPONENT_COLOR_1,
        borderColor: COMPONENT_COLOR_1,
        borderWidth: 1,
      };
      statusIcon =
        couponType == CouponsConsts.TYPE_SENT ? (
          <CompletedSent width={40} height={40} />
        ) : (
          <CompletedReceived width={40} height={40} />
        );
      break;
    case CouponsConsts.STATUS_REJECTED:
    case CouponsConsts.STATUS_EXPIRED:
      iconContainerStyle = {
        backgroundColor:
          couponType == CouponsConsts.TYPE_SENT
            ? COMPONENT_COLOR_3
            : COMPONENT_COLOR_2,
        borderColor: COMPONENT_COLOR_2,
        borderWidth: 1,
      };
      statusIcon =
        status === CouponsConsts.STATUS_REJECTED ? (
          <RejectedCoupon width={40} height={40} />
        ) : couponType === CouponsConsts.TYPE_RECEIVED ? (
          <ExpiredReceived width={40} height={40} />
        ) : (
          <ExpiredSent width={40} height={40} />
        );
      break;
    default:
      iconContainerStyle = { backgroundColor: COMPONENT_COLOR_3 };
      statusIcon = "";
      break;
  }
  return {
    statusIconContainer: {
      ...couponStyle.statusIconContainer,
      ...iconContainerStyle,
    },
    statusIcon: {
      icon: statusIcon,
      iconStyle: {
        ...couponStyle.statusIcon,
        ...statusIconStyle,
      },
    },
  };
}

function Options(props) {
  const [accepted, setAccept] = useState(false);
  const [rejected, setReject] = useState(false);

  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [redeemed, setRedeemed] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [rated, setRated] = useState(false);

  const handleAccept = async () => {
    setAccept(true);
    await props.onPressAccept({
      success: props.onSuccessfulTask,
      failure: () => setAccept(false),
    });
  };

  const handleReject = async () => {
    setReject(true);
    await props.onPressReject({
      success: () => {},
      failure: () => setReject(false),
    });
  };

  const handleDeleting = async () => {
    setDeleting(true);
    await props.onPressDelete({
      success: props.deleteAnimation,
      failure: () => setDeleting(false),
    });
  };

  const handleRedeem = async () => {
    setRedeemed(true);
    await props.onPressRedeem({
      success: props.onSuccessfulTask,
      failure: () => setRedeemed(false),
    });
  };

  const handleComplete = async () => {
    setCompleted(true);
    await props.onPressComplete({
      success: props.onSuccessfulTask,
      failure: () => setCompleted(false),
    });
  };

  const handleCompleteConfirmation = () => {
    handleCompleteConfirmationDialog(
      props.receiverDisplayName,
      props?.receiver ?? ProfileConsts.UNKNOWN_USERNAME,
      props.couponTitle,
      handleComplete
    );
  };

  const handleRate = async (rating) => {
    setRated(true);
    await props.onRateCoupon(rating);
    setRated(false);
  };

  if (props.status === CouponsConsts.STATUS_REQUEST) {
    return (
      <View style={couponStyle.requestOptions}>
        <View style={couponStyle.couponOptionsSubcontainer}>
          <Button
            type={1}
            customButtonStyle={{ backgroundColor: COMPONENT_COLOR_4 }}
            includeFlexContainer={true}
            onPress={handleAccept}
            isLoading={accepted}
            onLoadingJSX={<ActivityIndicator color={COMPONENT_COLOR_3} />}
          >
            {CouponsConsts.ACCEPT}
          </Button>
          <Button
            type={2}
            customTextStyle={{ color: COMPONENT_COLOR_2 }}
            includeFlexContainer={true}
            onPress={handleReject}
            isLoading={rejected}
            onLoadingJSX={<ActivityIndicator color={BUTTON_COLOR_2} />}
          >
            {CouponsConsts.DECLINE}
          </Button>
        </View>
      </View>
    );
  } else if (
    props.status === CouponsConsts.STATUS_ACCEPTED &&
    props.couponType === CouponsConsts.TYPE_RECEIVED
  ) {
    return (
      <View style={couponStyle.requestOptions}>
        <View style={couponStyle.couponOptionsSubcontainer}>
          <Button
            type={1}
            includeFlexContainer={true}
            onPress={handleRedeem}
            isLoading={redeemed}
            onLoadingJSX={<ActivityIndicator color={COMPONENT_COLOR_3} />}
          >
            {CouponsConsts.REDEEM}
          </Button>
          <View style={{ flex: 1 }} />
        </View>
      </View>
    );
  } else if (props.status === CouponsConsts.STATUS_REJECTED) {
    return (
      <View style={couponStyle.requestOptions}>
        <View style={couponStyle.couponOptionsSubcontainer}>
          <Button
            type={1}
            includeFlexContainer={true}
            customButtonStyle={{ backgroundColor: BUTTON_COLOR_2 }}
            onPress={handleDeleting}
            isLoading={deleting}
            onLoadingJSX={<ActivityIndicator color={COMPONENT_COLOR_3} />}
          >
            {CouponsConsts.DELETE}
          </Button>
          <View style={{ flex: 1 }} />
        </View>
      </View>
    );
  } else if (props.status === CouponsConsts.STATUS_WAITING_FOR_COMPLETION) {
    return (
      <View style={couponStyle.requestOptions}>
        <View style={couponStyle.couponOptionsSubcontainer}>
          <Button
            type={1}
            includeFlexContainer={true}
            onPress={handleCompleteConfirmation}
            isLoading={completed}
            onLoadingJSX={<ActivityIndicator color={COMPONENT_COLOR_3} />}
          >
            {CouponsConsts.COMPLETE}
          </Button>
          <View style={{ flex: 1 }} />
        </View>
      </View>
    );
  } else if (props.status === CouponsConsts.STATUS_EXPIRED) {
    return (
      <View style={couponStyle.requestOptions}>
        <View style={couponStyle.couponOptionsSubcontainer}>
          <Button
            type={1}
            includeFlexContainer={true}
            customButtonStyle={{ backgroundColor: BUTTON_COLOR_2 }}
            onPress={handleDeleting}
            isLoading={deleting}
            onLoadingJSX={<ActivityIndicator color={COMPONENT_COLOR_3} />}
          >
            {CouponsConsts.DELETE}
          </Button>
          <View style={{ flex: 1 }} />
        </View>
      </View>
    );
  } else if (
    props.status === CouponsConsts.STATUS_REDEEMED ||
    props.status === CouponsConsts.STATUS_RATED
  ) {
    return null;
  }

  return null;
}

Options.propTypes = {
  couponType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  receiver: PropTypes.string,
  receiverDisplayName: PropTypes.string,
  couponTitle: PropTypes.string.isRequired,
  onPressAccept: PropTypes.func.isRequired,
  onPressReject: PropTypes.func.isRequired,
  onPressDelete: PropTypes.func.isRequired,
  onPressRedeem: PropTypes.func.isRequired,
  onPressComplete: PropTypes.func.isRequired,
  onRateCoupon: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      acceptCoupon: acceptCoupon,
      rejectCoupon: rejectCoupon,
      deleteSentCoupon: deleteSentCoupon,
      deleteReceivedCoupon: deleteReceivedCoupon,
      deleteSentCouponAction: deleteSentCouponAction,
      deleteReceivedCouponAction: deleteReceivedCouponAction,
      redeemCoupon: redeemCoupon,
      completeCoupon: completeCoupon,
      expireCoupon: expireCoupon,
      rateCoupon: rateCoupon,
      createReceivedCoupon: createReceivedCoupon,
      updateSentCouponAction: updateSentCouponAction,
      updateReceivedCouponAction: updateReceivedCouponAction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Coupon);
