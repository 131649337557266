import { StyleSheet } from "react-native";

import { BACKGROUND_COLOR, TEXT_COLOR_6, TEXT_COLOR_4 } from "../common/colors";
import { getValueBasedOnScreenSize } from "../common/dimensions";

export const coupsListStyle = StyleSheet.create({
  viewContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: BACKGROUND_COLOR,
  },
  title: {
    color: TEXT_COLOR_6,
    fontWeight: "bold",
    fontSize: 28,
  },
  flatListView: {
    flex: 1,
    height: 0, //I'm not sure what's going on here, but it worked
  },

  errorView: {
    alignSelf: "center",
    height: 100, //Value is as big as button it contains. Won't center unless height specified
  },
  errorText: {
    marginBottom: 8,
  },
  errRetryBtn: {
    marginHorizontal: 32,
  },
  spinnerView: {},
  emptyCouponContainerView: {
    backgroundColor: BACKGROUND_COLOR,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyCouponListView: {
    alignSelf: "center",
  },
  noCouponsListContainer: {
    justifyContent: "center",
    flex: 1,
    backgroundColor: BACKGROUND_COLOR,
  },
  sectionHeaderContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: BACKGROUND_COLOR,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  sectionHeaderText: {
    color: TEXT_COLOR_4,
    fontSize: 24,
  },
  noActiveCouponsContainer: {
    justifyContent: "center",
    height: 260,
    marginHorizontal: 36,
  },
  noActiveCouponsText: {
    color: TEXT_COLOR_4,
    alignSelf: "center",
    textAlign: "center",
    fontSize: getValueBasedOnScreenSize(24, 34),
  },
});
