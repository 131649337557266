import React, { Component } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  KeyboardAvoidingView,
  View,
  ActivityIndicator,
  Text,
  TouchableOpacity,
  Keyboard,
  BackHandler,
} from "react-native";
import { Input } from "@rneui/themed";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ScrollView } from "react-native-gesture-handler";
import PropTypes from "prop-types";
import * as Sentry from "sentry-expo";

import { signupStyle } from "../../styles/SignupStyle";
import {
  SignupConsts,
  NetworkConsts,
  NavigationConsts,
} from "../../common/constants";
import { Button } from "../../common/Button";
import {
  signUp,
  googleSignUp,
} from "../../modules/coupons/services/AuthService";
import {
  getUserAccount,
  googleSignIn,
} from "../../modules/coupons/services/AuthService";
import { getUserProfile } from "../../modules/coupons/services/ProfileService";
import { updateExpoToken } from "../../modules/coupons/services/ExpoService";
import { authorizeUserAccessAction } from "../../modules/coupons/actions/AuthActions";
import { COMPONENT_COLOR_3 } from "../../common/colors";
import { AvatarPicker } from "../Avatars";
import { avatars } from "../../common/assets";
import { validateUsername, vallidateFullname } from "../../common/validator";
import AsyncStorage from "@react-native-async-storage/async-storage";

const EMPTY_FIELD = "";

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();

    const randomAvatarID = Math.floor(Math.random() * avatars.length);

    this.state = {
      username: EMPTY_FIELD,
      fullname: EMPTY_FIELD,
      password: EMPTY_FIELD,
      avatarID: randomAvatarID,
      usernameError: null,
      firstnameError: null,
      lastnameError: null,
      fullnameError: null,
      passwordError: null,

      signUpError: null,
      isSigningUp: false,
      isAvatarPickerVisible: false,

      googleIdToken: null,
    };
  }

  componentDidMount() {
    BackHandler.addEventListener("hardwareBackPress", this.hideAvatarPicker);

    const { params } = this.props.route;

    if (params) {
      const { idToken } = this.props.route.params;

      if (idToken) {
        this.setState({
          googleIdToken: idToken,
        });
      }
    }
  }

  componentWillUnmount() {
    BackHandler.addEventListener("hardwareBackPress", this.hideAvatarPicker);
  }

  handleSignup = async () => {
    const { username, password, fullname, avatarID, googleIdToken } =
      this.state;

    try {
      this.setState({ isSigningUp: true });

      if (googleIdToken) {
        validateUsername(username, (error) =>
          this.setState({ usernameError: error })
        );
        vallidateFullname(fullname, (error) =>
          this.setState({ fullnameError: error })
        );

        await this.props.googleSignUp(
          username,
          googleIdToken,
          fullname,
          avatarID
        );

        const userLogin = await this.props.googleSignIn(googleIdToken);
        await AsyncStorage.setItem("@token", userLogin.token);

        const userAccount = await this.props.getUserAccount();
        await AsyncStorage.setItem(
          "@user_account",
          JSON.stringify(userAccount)
        );

        const userProfile = await this.props.getUserProfile();
        await AsyncStorage.setItem(
          "@user_profile",
          JSON.stringify(userProfile)
        );

        this.props.updateExpoToken();
        this.props.authorizeUserAccessAction();
        log(
          `User ${userAccount.username} succesfully logged with user id ${userLogin.userId} and Google Auth flow`
        );
      } else {
        this.validateCredentials();

        const { token } = await this.props.signUp(
          username,
          password,
          fullname,
          avatarID
        );

        await AsyncStorage.setItem("@token", token);

        const userAccount = await this.props.getUserAccount();
        await AsyncStorage.setItem(
          "@user_account",
          JSON.stringify(userAccount)
        );

        const userProfile = await this.props.getUserProfile();
        await AsyncStorage.setItem(
          "@user_profile",
          JSON.stringify(userProfile)
        );

        this.props.updateExpoToken();
        this.props.authorizeUserAccessAction();
        log(`User ${username} succesfully logged in with regular Auth flow`);
      }
    } catch (e) {
      log("Failed to SIGN UP because ", e.message);
      if (
        e.message === NetworkConsts.NETWORK_REQUEST_FAILED ||
        e.message === SignupConsts.UNAUTHORIZED_ACCOUNT_EXISTS
      ) {
        this.setState({ signUpError: e.message });
      }
      this.setState({ isSigningUp: false });
      Sentry.Native.captureException(e);
    }
  };

  validateCredentials = () => {
    const { username, password, fullname } = this.state;

    try {
      validateUsername(username, (error) =>
        this.setState({ usernameError: error })
      );
      vallidateFullname(fullname, (error) =>
        this.setState({ fullnameError: error })
      );

      if (!password) {
        this.setState({ passwordError: SignupConsts.REQUIRED_FIELD_ERROR });
        throw new Error(SignupConsts.REQUIRED_FIELD_ERROR);
      }

      if (password.length <= 4) {
        this.setState({ passwordError: SignupConsts.PASSWORD_ERROR_1 });
        throw new Error(SignupConsts.PASSWORD_ERROR_1);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  handleNextInputFocus = () => {
    const { username, password, googleIdToken } = this.state;

    if (googleIdToken) {
      Keyboard.dismiss();
      return;
    }

    if (!username) {
      this.usernameRef.current.focus();
    } else if (!password) {
      this.passwordRef.current.focus();
    } else {
      Keyboard.dismiss();
    }
  };

  setAvatar = (id) => {
    this.setState({
      avatarID: id,
    });
  };

  toggleAvatarPicker = () => {
    this.setState({
      isAvatarPickerVisible: !this.state.isAvatarPickerVisible,
    });
  };

  hideAvatarPicker = () => {
    this.setState({
      isAvatarPickerVisible: false,
    });
  };

  render = () => {
    const { googleIdToken, fullname } = this.state;

    const isGoogleAccountAvailable = !!googleIdToken;

    const getErrorAwareUserInputContainer = (error) => ({
      ...signupStyle.userInputContainer,
      ...(error ? { marginBottom: 0 } : {}),
    });

    return (
      <SafeAreaView style={signupStyle.safeareaviewContainer}>
        <ScrollView contentContainerStyle={signupStyle.scrollviewContainer}>
          <KeyboardAvoidingView style={signupStyle.container}>
            <View style={signupStyle.inputContainer}>
              <Text style={signupStyle.title}>{SignupConsts.TITLE_SIGNUP}</Text>
              <View style={signupStyle.inputBoxes}>
                <Input
                  ref={this.usernameRef}
                  blurOnSubmit={false}
                  autoCapitalize="none"
                  onSubmitEditing={this.handleNextInputFocus}
                  inputContainerStyle={getErrorAwareUserInputContainer(
                    this.state.usernameError
                  )}
                  inputStyle={signupStyle.userInputText}
                  placeholder={SignupConsts.USERNAME_FIELD}
                  errorMessage={this.state.usernameError}
                  onChangeText={(text) =>
                    this.setState({
                      username: text.trim(),
                      usernameError: null,
                    })
                  }
                />
                {isGoogleAccountAvailable ? null : (
                  <Input
                    ref={this.passwordRef}
                    blurOnSubmit={false}
                    onSubmitEditing={this.handleNextInputFocus}
                    inputContainerStyle={getErrorAwareUserInputContainer(
                      this.state.passwordError
                    )}
                    inputStyle={signupStyle.userInputText}
                    placeholder={SignupConsts.PASSWORD_FIELD}
                    secureTextEntry={true}
                    errorMessage={this.state.passwordError}
                    onChangeText={(text) =>
                      this.setState({ password: text, passwordError: null })
                    }
                  />
                )}
                <View style={signupStyle.avatarAndfullname}>
                  <TouchableOpacity
                    style={signupStyle.avatarContainer}
                    activeOpacity={0.8}
                  >
                    <ChooseAvatar
                      avatarID={this.state.avatarID}
                      setAvatarPicketVisible={this.toggleAvatarPicker}
                    />
                  </TouchableOpacity>
                  <Input
                    ref={this.fullnameRef}
                    value={fullname}
                    blurOnSubmit={false}
                    onSubmitEditing={this.handleNextInputFocus}
                    containerStyle={signupStyle.userInput}
                    inputContainerStyle={signupStyle.userInputContainer}
                    inputStyle={signupStyle.userInputText}
                    placeholder={SignupConsts.FULLNAME_FIELD}
                    errorMessage={this.state.fullnameError}
                    onChangeText={(text) =>
                      this.setState({
                        fullname: text.trim(),
                        fullnameError: null,
                      })
                    }
                  />
                </View>
                <AvatarPicker
                  setAvatar={this.setAvatar}
                  toggleVisibility={this.toggleAvatarPicker}
                  isVisible={this.state.isAvatarPickerVisible}
                />
              </View>
              <Text style={signupStyle.generalError}>
                {this.state.signUpError}
              </Text>
            </View>
            <View style={signupStyle.signupButtonsContainer}>
              <Button
                type={1}
                style={signupStyle.signUpButton}
                onPress={this.handleSignup}
                isLoading={this.state.isSigningUp}
                onLoadingJSX={
                  <ActivityIndicator
                    style={signupStyle.signupLoading}
                    color={COMPONENT_COLOR_3}
                  />
                }
              >
                {SignupConsts.SIGNUP_LINK_TEXT}
              </Button>
              <View style={signupStyle.signinContainer}>
                <Text style={signupStyle.signinText}>
                  {SignupConsts.SIGNIN_TEXT + " "}
                </Text>
                <Text
                  style={signupStyle.signinLink}
                  onPress={() =>
                    this.props.navigation.navigate(
                      NavigationConsts.LOGIN_SCREEN
                    )
                  }
                >
                  {SignupConsts.SIGNIN}
                </Text>
              </View>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
      </SafeAreaView>
    );
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUp: signUp,
      googleSignUp: googleSignUp,
      googleSignIn: googleSignIn,
      getUserAccount: getUserAccount,
      getUserProfile: getUserProfile,
      updateExpoToken: updateExpoToken,
      authorizeUserAccessAction: authorizeUserAccessAction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SignUp);

const ChooseAvatar = (props) => {
  const Avatar = avatars[props.avatarID];

  return (
    <TouchableOpacity
      onPress={props.setAvatarPicketVisible}
      style={signupStyle.avatar}
    >
      <Avatar height={45} width={45} />
    </TouchableOpacity>
  );
};

ChooseAvatar.propTypes = {
  avatarID: PropTypes.number.isRequired,
  setAvatarPicketVisible: PropTypes.func.isRequired,
};
