import React from "react";
import { View, Text, StyleSheet, Animated } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deactivateOverlayMessage } from "../modules/coupons/actions/AppConfigActions";

import { dimensions } from "../common/dimensions";
import {
  COMPONENT_COLOR_3,
  COMPONENT_COLOR_1,
  TEXT_COLOR_4,
} from "../common/colors";

const AUTO_DISMISS_TIME = 7000;

const Overlay = (props) => {
  const { active, message, delay } = props.overlay;
  const initialPosition = 100;
  const destinationPosition = -72;

  const [overlayActivated, setOverlayActivated] = React.useState(false);
  const positionAnim = React.useRef(
    new Animated.Value(initialPosition)
  ).current;

  React.useEffect(() => {
    if (active && !overlayActivated) {
      setOverlayActivated(true);

      Animated.sequence([
        Animated.timing(positionAnim, {
          toValue: destinationPosition,
          duration: 80,
          delay: delay,
          useNativeDriver: false,
        }),
        Animated.timing(positionAnim, {
          toValue: initialPosition,
          duration: 80,
          delay: AUTO_DISMISS_TIME + delay - 2000,
          useNativeDriver: false,
        }),
      ]).start();

      setTimeout(() => {
        props.deactivateOverlayMessage();
        setOverlayActivated(false);
        positionAnim.setValue(initialPosition);
      }, AUTO_DISMISS_TIME + delay);
    }
  });

  if (!active) {
    return null;
  }

  return (
    <Animated.View
      style={{
        ...style.positioning,
        transform: [{ translateY: positionAnim }],
      }}
    >
      <View style={style.container}>
        <Text style={style.text}>{message}</Text>
      </View>
    </Animated.View>
  );
};

const style = StyleSheet.create({
  positioning: {
    position: "absolute",
    height: 48,
    transform: [
      {
        translateY: 100,
      },
    ],
    width: dimensions.FULLWIDTH - 32, // Subtract horizontal margin total and set half of it to left
    left: 16,
    backgroundColor: COMPONENT_COLOR_3,
    borderColor: COMPONENT_COLOR_1,
    borderWidth: 1,
    borderRadius: 8,
    zIndex: 1,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
    color: TEXT_COLOR_4,
  },
});

const mapStateToProps = (state) => ({
  overlay: state.config.overlay,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deactivateOverlayMessage: deactivateOverlayMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
