import React, { useState } from "react";
import { View, Text, Image, ScrollView, StyleSheet, Alert } from "react-native";
import AppLink from "react-native-app-link";
import * as Linking from "expo-linking";
import CouponDetails, { CouponSnippet } from "./CouponDetails";
import { Coopon, ListType } from "../../common/types";
import { bindActionCreators, Dispatch } from "redux";
import { fetchShareableCoupon } from "../../modules/coupons/services/CouponService";
import { connect } from "react-redux";
import { validateUUID } from "../../common/validator";
import { CouponBody, CouponDetailsTemplate } from "../CouponComponents";
import {
  BACKGROUND_COLOR,
  COMPONENT_COLOR_1,
  TEXT_COLOR_3,
} from "../../common/colors";
import {
  getValueBasedOnScreenSize,
  getValueBasedOnWidth,
} from "../../common/dimensions";
import { Button } from "../../common/Button";
import * as Sentry from "sentry-expo";
import * as Device from "expo-device";
import { useAssets } from "expo-asset";
import { log } from "../../common/logging";

// import Logo from "../../assets/images/Logo1-1.svg";

export const CouponDetailsWeb = () => {
  const [coopon, setCoopon] = useState<Coopon>(null);
  const [error, setError] = useState<String>("");

  Linking.getInitialURL().then((url) => {
    if (url) {
      const { path } = Linking.parse(url);

      if (path && !coopon) {
        try {
          validateUUID(path);
          fetchShareableCoupon(path).then((data) => {
            if (data) {
              if ("message" in data) {
                setError(data.message);
              } else {
                setCoopon(data);
              }
            }
          });
        } catch (error) {
          log(error);
        }
      }
    }
  });

  const handleClaim = () => {
    openApp(coopon.id);
  };

  const openApp = (couponId: string) => {
    const playstoreLink =
      "https://play.google.com/store/apps/details?id=org.psyntax.coopons";
    const link = `coopons://${couponId}`;

    Device.getDeviceTypeAsync().then((type) => {
      // If web browser is not on mobile, then just open the play store
      if (
        type === Device.DeviceType.PHONE ||
        type === Device.DeviceType.TABLET
      ) {
        // Temporary: Until openURL decides to work again, we'll attempt to open both links
        Linking.openURL(playstoreLink);
        // openURL is not working as expected. It's supposed to reject if dialog is cancelled or fails to open app. Neither is hapenning. So just attempt to open app anyways.
        Linking.openURL(link).catch((error) => {
          Sentry.Browser.captureException(error);
          Linking.openURL(playstoreLink);
        });
      } else {
        Linking.openURL(playstoreLink);
      }
    });
  };

  let CooponContent = null;
  let CooponContentSnippet = null;
  if (coopon) {
    const {
      name,
      description,
      status,
      sender,
      senderAvatarId,
      senderDisplayName,
      startDate,
      endDate,
      receiver,
      receiverDisplayName,
      receiverAvatarId,
    } = coopon;
    const listType: ListType = "RECEIVED";
    CooponContent = (
      <ScrollView showsVerticalScrollIndicator={false}>
        <CouponDetailsTemplate
          name={name}
          description={description}
          sender={sender}
          receiver={receiver}
          senderDisplayName={senderDisplayName}
          receiverDisplayName={receiverDisplayName}
          couponType={listType}
          startDate={startDate}
          endDate={endDate}
        />
        <CouponBody
          listType={listType}
          sender={sender}
          receiver={receiver}
          senderDisplayName={senderDisplayName}
          receiverDisplayName={receiverDisplayName}
          senderAvatarId={senderAvatarId}
          receiverAvatarId={receiverAvatarId}
          status={status}
          startDate={startDate}
          endDate={endDate}
          handleUserPress={() => {}}
          handleExpiration={() => {}}
          contrast
          isBigBoxPressable
        />
      </ScrollView>
    );
    CooponContentSnippet = (
      <React.Fragment>
        <CouponSnippet
          status={status}
          listType={listType}
          receiver={receiver}
          receiverDisplayName={receiverDisplayName}
          sender={sender}
          senderDisplayName={senderDisplayName}
        />
        <Button
          type={3}
          onPress={handleClaim}
          customButtonStyle={{ borderRadius: 20 }}
          customTextStyle={{ fontSize: 20 }}
        >
          Claim
        </Button>
      </React.Fragment>
    );
  } else {
    let errorMsg = "Invalid Coopon";
    if (error.endsWith("is not of type SENT_LINK")) {
      errorMsg = "This Coopon was already claimed";
    }
    CooponContent = (
      <View style={style.errorContainer}>
        <Text style={style.errorText}>{errorMsg}</Text>
      </View>
    );
  }

  return (
    <View style={style.container}>
      <HeaderBar />
      <View style={style.couponContainer}>
        <View
          style={[
            style.upperCouponContainer,
            !coopon ? { justifyContent: "center" } : null,
          ]}
        >
          {CooponContent}
        </View>
        <View style={style.dashedLine}></View>
        <View style={style.lowerCouponContainer}>
          <View style={style.svgView1}></View>
          <View style={style.lowerCouponContent}>{CooponContentSnippet}</View>
          <View style={style.svgView2}></View>
        </View>
      </View>
    </View>
  );
};

const HeaderBar = () => {
  const [assets, setAssetError] = useAssets([
    require("../../assets/coopons_w_logo.png"),
  ]);

  const imageSize = { width: 200, height: 40 };

  const handleDownload = () => {
    const playstoreLink =
      "https://play.google.com/store/apps/details?id=org.psyntax.coopons";
    Linking.openURL(playstoreLink);
  };

  return (
    <View style={style.header}>
      <View>
        {assets ? <Image style={imageSize} source={assets[0]} /> : null}
      </View>
      <Button type={5} onPress={handleDownload}>
        Download
      </Button>
    </View>
  );
};

export const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: BACKGROUND_COLOR,
    alignItems: "center",
  },
  couponContainer: {
    flex: 1,
    width: getValueBasedOnWidth(600, "100%", 800),
    height: "100%",
    marginBottom: 40,
  },
  upperCouponContainer: {
    marginTop: 24,
    marginHorizontal: 24,
    padding: 16,
    flex: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: COMPONENT_COLOR_1,
  },
  dashedLine: {
    height: 0,
    borderStyle: "dashed",
    borderWidth: 0.5,
    borderColor: BACKGROUND_COLOR,
    borderRadius: 1,
    marginHorizontal: 24,
  },
  lowerCouponContainer: {
    marginHorizontal: 24,
    padding: 16,
    flex: 0.2,
    borderRadius: 1,
    borderColor: COMPONENT_COLOR_1,
    borderTopWidth: 2,
    backgroundColor: COMPONENT_COLOR_1,
    flexDirection: "row",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  svgView1: {
    backgroundColor: BACKGROUND_COLOR,
    height: getValueBasedOnScreenSize(50, 60),
    width: getValueBasedOnScreenSize(50, 60),
    borderRadius: 100,
    right: 50,
  },
  svgView2: {
    backgroundColor: BACKGROUND_COLOR,
    height: getValueBasedOnScreenSize(50, 60),
    width: getValueBasedOnScreenSize(50, 60),
    borderRadius: 100,
    left: 50,
  },
  lowerCouponContent: {
    flex: 1,
    flexDirection: getValueBasedOnWidth(600, "column", "row"),
    justifyContent: "space-around",
    alignItems: "center",
  },
  claimButton: {
    backgroundColor: "red",
  },

  errorContainer: {},
  errorText: {
    fontSize: 24,
    color: TEXT_COLOR_3,
    textAlign: "center",
  },
  header: {
    flex: 0.1,
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  headerText: {
    fontSize: 24,
  },
});
