import { processResponse, authFetchWithTimeout } from "../../../common/network";
import {
  updateProfileBuddiesAction,
  addBuddyAction,
} from "../actions/ProfileActions";
import { getApiURL } from "../../../common/environment";
import { AuthError } from "../../../common/AuthError";
import { log } from "../../../common/logging";

const backendUrl = getApiURL();

const getBuddiesEndpoint = `${backendUrl}/api/buddies`;
const getBuddyProfilesEndpoint = `${backendUrl}/api/buddies/profiles`;
const addBuddyEndpoint = `${backendUrl}/api/buddies/add/`;
const removeBuddyEndpoint = `${backendUrl}/api/buddies/delete/`;

const GET = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
};

const POST = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

export const getBuddyProfiles = () => async (dispatch) => {
  return authFetchWithTimeout(getBuddyProfilesEndpoint, GET, dispatch)
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      dispatch(updateProfileBuddiesAction(res.data.profiles));
    })
    .catch((error) => {
      log("Failed to get buddies:", error.message);
      throw error;
    });
};

export const addBuddy = (buddy) => async (dispatch) => {
  return authFetchWithTimeout(addBuddyEndpoint + buddy, POST, dispatch)
    .then(processResponse)
    .then((res) => {
      if (res.status !== 201) {
        throw new Error(res.data.message);
      }
      log(res.data.message);
    })
    .catch((error) => {
      log("Failed to add friend:", error.message);
      throw new Error(error.message);
    });
};

export const deleteBuddy = (buddy) => async (dispatch) => {
  return authFetchWithTimeout(removeBuddyEndpoint + buddy, POST, dispatch)
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      log(res.data.message);
    })
    .catch((error) => {
      log("Failed to delete friend:", error.message);
      throw new Error(error.message);
    });
};
