import { StyleSheet } from "react-native";
import {
  BACKGROUND_COLOR,
  COMPONENT_COLOR_3,
  COMPONENT_COLOR_6,
  TEXT_COLOR_3,
  TEXT_COLOR_6,
  TEXT_COLOR_4,
} from "../common/colors";

export const profileStyle = StyleSheet.create({
  backgroundContainer: {
    backgroundColor: BACKGROUND_COLOR,
    flex: 1,
    paddingHorizontal: 24,
  },
  container: {
    flex: 1, // This flex on scrollview keeps the gettingProfileError View to stay centered. May possibly prevent scrolling
  },

  profileHeaderIconsContainer: {},
  profileHeaderIcon: {
    padding: 8,
  },

  profileNameContainer: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: COMPONENT_COLOR_3,
    marginBottom: 8,
  },
  profileNameHeader: {
    flexDirection: "row",
  },
  profileNameHeaderName: {
    fontSize: 20,
    marginLeft: 8,
  },
  profileNameSummary: {},

  ratingContainer: {
    flexDirection: "row",
    padding: 16,
    marginVertical: 8,
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 8,
    justifyContent: "flex-start",
  },
  ratingBox: {
    backgroundColor: COMPONENT_COLOR_6,
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    borderRadius: 8,
    padding: 8,
    color: TEXT_COLOR_3,
    overflow: "hidden", // Fixes borderRadius for IOS
  },
  ratingInfoContainer: {
    flexDirection: "column",
    marginHorizontal: 8,
    justifyContent: "space-between",
  },
  ratingTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },

  detailsContainer: {
    padding: 16,
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 8,
  },
  detailsFieldContainer: {
    marginVertical: 8,
  },
  detailsFieldName: {
    fontWeight: "bold",
    color: TEXT_COLOR_6,
  },
  detailsFieldValue: {
    color: TEXT_COLOR_4,
  },
  profileDetailsTitle: {
    marginBottom: 16,
    color: TEXT_COLOR_4,
  },
  profileNotFoundContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  profileNotFoundText: {
    fontSize: 24,
    color: TEXT_COLOR_4,
  },
  profileErrorContainer: {
    flex: 1,
    marginHorizontal: 24,
    justifyContent: "center",
  },
  profileErrorText: {
    textAlign: "center",
    marginBottom: 8,
  },
  profileErrorButton: {
    marginHorizontal: 24,
  },
});
