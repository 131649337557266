import { combineReducers } from "redux";

import { coupons } from "./coupons/reducers/CouponReducer";
import { auth } from "./coupons/reducers/AuthReducer";
import { profile } from "./coupons/reducers/ProfileReducer";
import { config } from "./coupons/reducers/AppConfigReducer";

export default combineReducers({
  coupons,
  auth,
  profile,
  config,
});
