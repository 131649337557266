import { StyleSheet } from "react-native";
import {
  BACKGROUND_COLOR,
  COMPONENT_COLOR_3,
  TEXT_COLOR_1,
  TEXT_COLOR_5,
  TEXT_COLOR_6,
} from "../common/colors";

export const signupStyle = StyleSheet.create({
  safeareaviewContainer: {
    flex: 1,
  },
  scrollviewContainer: {
    backgroundColor: BACKGROUND_COLOR,
    flexGrow: 1,
  },
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  inputContainer: {},
  inputBoxes: {
    marginHorizontal: -10,
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 20,
  },
  userInput: {
    flex: 1,
  },
  userInputContainer: {
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 4,
    paddingHorizontal: 16,
    borderBottomColor: COMPONENT_COLOR_3,
    marginBottom: -16,
  },
  userInputText: {
    fontSize: 14,
    color: TEXT_COLOR_6,
  },
  inputDoubleBoxes: {
    flexDirection: "row",
    marginBottom: 16,
  },
  inputDoubleBox: {
    flex: 0.5,
  },
  generalError: {
    color: "red",
    fontSize: 12,
    alignSelf: "center",
    marginBottom: 8,
  },
  signupButtonsContainer: {
    marginBottom: 24,
  },
  signUpButton: {
    marginTop: 24,
    marginBottom: 48,
  },
  signupLoading: {
    marginVertical: 11.5,
  },
  signinContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
  },
  signinText: {
    color: TEXT_COLOR_5,
  },
  signinLink: {
    color: TEXT_COLOR_1,
  },
  avatarAndfullname: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  avatar: {},
  avatarContainer: {
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 4,
    padding: 6,
    marginLeft: 10,
  },
});
