import React, { useState } from "react";
import {
  StyleSheet,
  Platform,
  TouchableOpacity,
  View,
  Text,
  Modal,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import PropTypes from "prop-types";
import { Input } from "@rneui/themed";

import {
  TEXT_COLOR_4,
  BACKGROUND_COLOR,
  COMPONENT_COLOR_5,
  TEXT_COLOR_1,
  COMPONENT_COLOR_3,
  COMPONENT_COLOR_1,
} from "../common/colors";
import { CreateCouponConsts } from "../common/constants";
import { Button } from "../common/Button";

export const InputDate = (props) => {
  const [state, setState] = useState({
    isDateTimePickerVisible: false, //For IOS
    isDatePickerVisible: false, //For Android
    isTimePickerVisible: false, //For Android
    date: null, //For Android
  });

  const {
    isDateTimePickerVisible,
    isDatePickerVisible,
    isTimePickerVisible,
    date,
  } = state;

  const platformPickerVisible =
    Platform.OS === "ios" ? isDateTimePickerVisible : isDatePickerVisible;
  const mode = Platform.OS === "ios" ? "datetime" : "date";

  const buttonLabel = props.endDate
    ? moment(props.endDate).format("lll")
    : props.defaultLabel;

  const minimumDate = moment().add(1, "days").toDate();

  const handleDatePicked = (value) => {
    const formatDate = moment(value, "MMM DD, YYYY hh:mm a"); //May 2, 2020 3:33 PM
    const utcDate = moment(formatDate)
      .utc()
      .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
    props.handleDateUpdate(utcDate);
  };

  const showPicker = () => {
    if (Platform.OS === "ios") {
      setState({
        ...state,
        isDateTimePickerVisible: true,
      });
    } else {
      setState({
        ...state,
        isDatePickerVisible: true,
        isTimePickerVisible: false,
      });
    }
  };

  const handlePicker = (event, value) => {
    //Nested conditions for verbosity
    if (Platform.OS === "ios") {
      if (event.type === "set") {
        const momentDatetime = moment(value).format("lll");
        setState({
          ...state,
          isDateTimePickerVisible: false,
        });
        handleDatePicked(value);
      }
    } else {
      if (event.type === "set") {
        if (isDatePickerVisible) {
          const momentDate = moment(value).format("ll");
          setState({
            ...state,
            isDatePickerVisible: false,
            isTimePickerVisible: true,
            date: momentDate,
          });
        } else if (date && isTimePickerVisible) {
          const momentTime = moment(value).format("LT");
          const momentDatetime = moment(date + " " + momentTime).format("lll");
          setState({
            ...state,
            isTimePickerVisible: false,
          });
          handleDatePicked(momentDatetime);
        }
      } else {
        setState({
          isDatePickerVisible: false,
          isTimePickerVisible: false,
          date: null,
        });
      }
    }
  };

  return (
    <>
      <TouchableOpacity
        style={inputDateStyle.redeemByContainer}
        onPress={showPicker}
        activeOpacity={1}
      >
        {Platform.OS === "android" ? (
          <Input
            editable={false}
            inputStyle={inputDateStyle.reddemByInput}
            label={CreateCouponConsts.REDEEM_BY_LABEL}
            placeholder={CreateCouponConsts.REDEEM_BY_DEFAULT_VALUE}
            value={buttonLabel}
          />
        ) : (
          <View
            style={[
              inputDateStyle.redeemByButtonContainer,
              {
                borderColor: props.endDate
                  ? COMPONENT_COLOR_1
                  : COMPONENT_COLOR_5,
              },
            ]}
          >
            <Text
              style={[
                inputDateStyle.redeemByButton,
                { color: props.endDate ? TEXT_COLOR_1 : TEXT_COLOR_4 },
              ]}
            >
              {buttonLabel}
            </Text>
          </View>
        )}
      </TouchableOpacity>

      {Platform.OS === "ios" ? (
        <DateModal
          visible={platformPickerVisible}
          minimumDate={minimumDate}
          mode={mode}
          onChange={handlePicker}
          closeModal={() => setState({ isDateTimePickerVisible: false })}
        />
      ) : (
        platformPickerVisible && (
          <DateTimePicker
            value={new Date()}
            minimumDate={minimumDate}
            mode={mode}
            onChange={handlePicker}
          />
        )
      )}

      {isTimePickerVisible && (
        <DateTimePicker
          value={new Date()}
          minimumDate={new Date()}
          mode={"time"}
          onChange={handlePicker}
        />
      )}
    </>
  );
};

const DateModal = ({ visible, minimumDate, mode, onChange, closeModal }) => {
  const [datetime, setDatetime] = useState(minimumDate);

  const setDate = () => {
    onChange(
      {
        type: "set",
      },
      datetime
    );
  };

  const resetDatetime = () => {
    setDatetime(minimumDate);
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType={"fade"}
      onDismiss={resetDatetime}
    >
      <View style={inputDateStyle.modalFullScreenBackground}>
        <View style={inputDateStyle.modalContainer}>
          <DateTimePicker
            value={datetime}
            minimumDate={minimumDate}
            mode={mode}
            onChange={(event, value) => setDatetime(value)}
          />
          <View style={inputDateStyle.modalButtons}>
            <Button type={2} onPress={closeModal}>
              {CreateCouponConsts.DATETIME_CLOSE_MODAL}
            </Button>
            <Button type={1} includeFlexContainer={true} onPress={setDate}>
              {CreateCouponConsts.DATETIME_SET_MODAL}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

InputDate.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  handleDateUpdate: PropTypes.func.isRequired,
  endDate: PropTypes.string,
};

const inputDateStyle = StyleSheet.create({
  redeemByContainer: {
    marginVertical: 8,
  },
  reddemByInput: {
    color: TEXT_COLOR_4,
  },
  redeemByButtonContainer: {
    backgroundColor: BACKGROUND_COLOR,
    borderWidth: 2,
    borderRadius: 4,
    marginHorizontal: 8,
    padding: 4,
  },
  redeemByButton: {
    textAlign: "center",
    fontSize: 20,
  },
  modalFullScreenBackground: {
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    flex: 1,
    justifyContent: "center",
  },
  modalContainer: {
    flexDirection: "column",
    backgroundColor: COMPONENT_COLOR_3,
  },
  modalButtons: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: 8,
  },
});
