import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Overlay } from "@rneui/themed";
import { ScrollView } from "react-native-gesture-handler";
import PropTypes from "prop-types";

import { Button } from "../common/Button";
import { avatars } from "../common/assets";
import { AvatarConsts } from "../common/constants";

const avatarsPerBucket = 5;
const buckets = Math.ceil(avatars.length / avatarsPerBucket);

function getAvatarBuckets() {
  const avatarBuckets = [];
  for (let i = 0; i < buckets; i++) {
    let avatarBucket = [];
    for (let j = 0; j < avatarsPerBucket; j++) {
      const index = avatarsPerBucket * i + j;
      avatarBucket.push(avatars[index]);
    }
    avatarBuckets.push(avatarBucket);
  }
  return avatarBuckets;
}

export const AvatarPicker = (props) => {
  const avatarBuckets = getAvatarBuckets();

  const handleAvatarClick = (avatarID) => {
    props.setAvatar(avatarID);
    props.toggleVisibility();
  };

  return (
    <Overlay
      isVisible={props.isVisible}
      onBackdropPress={props.toggleVisibility}
      fullScreen
    >
      <View style={style.container}>
        <ScrollView style={{}} contentContainerStyle={style.buckets}>
          {avatarBuckets.map((bucket, i) => (
            <View key={i} style={style.bucket}>
              {bucket.map((Avatar, j) => (
                <TouchableOpacity
                  key={j}
                  onPress={() => handleAvatarClick(avatarsPerBucket * i + j)}
                >
                  <Avatar height={50} width={50} />
                </TouchableOpacity>
              ))}
            </View>
          ))}
        </ScrollView>
        <View>
          <Button type={2} onPress={props.toggleVisibility}>
            {AvatarConsts.CLOSE}
          </Button>
        </View>
      </View>
    </Overlay>
  );
};

AvatarPicker.propTypes = {
  setAvatar: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
};

const style = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  buckets: {
    flexDirection: "column",
  },
  bucket: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginVertical: 8,
  },
  avatar: {},
});
