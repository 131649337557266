import {
  SHOW_COUPONS_BAR,
  ACTIVATE_OVERLAY_MESSAGE,
  DEACTIVATE_OVERLAY_MESSAGE,
  REFETCH_SENT_COUPONS,
  REFETCH_RECEIVED_COUPONS,
} from "../actions/AppConfigActionTypes";

const configInitialState = {
  showCouponsBar: true,
  overlay: {
    active: false,
    message: null,
    delay: 0,
  },
  shouldRefetchSentCoupons: false,
  shouldRefetchReceivedCoupons: false,
};

export const config = (state = configInitialState, action) => {
  switch (action.type) {
    case SHOW_COUPONS_BAR:
      return {
        ...state,
        showCouponsBar: action.payload,
      };
    case ACTIVATE_OVERLAY_MESSAGE:
      return {
        ...state,
        overlay: {
          active: true,
          message: action.message,
          delay: action.delay,
        },
      };
    case DEACTIVATE_OVERLAY_MESSAGE:
      return {
        ...state,
        overlay: {
          active: false,
          message: null,
          delay: 0,
        },
      };
    case REFETCH_SENT_COUPONS:
      return {
        ...state,
        shouldRefetchSentCoupons: action.shouldRefetchCoupons,
      };
    case REFETCH_RECEIVED_COUPONS:
      return {
        ...state,
        shouldRefetchReceivedCoupons: action.shouldRefetchCoupons,
      };
    default:
      return state;
  }
};
