import { registerForPushNotificationsAsync } from "../../Notifications";
import { processResponse, authFetchWithTimeout } from "../../../common/network";
import { getApiURL } from "../../../common/environment";
import { log } from "../../../common/logging";

const hostname = getApiURL();

const EXPO_ENDPOINT = hostname + "/api/expo";
const EXPO_DELETE_TOKEN_ENDPOINT = hostname + "/api/expo/delete";

const EXPO_REQ = (expoToken) => ({
  method: "POST",
  headers: {
    token: expoToken,
  },
});

export const updateExpoToken = () => async (dispatch) => {
  const expoToken = await registerForPushNotificationsAsync();
  if (!expoToken) {
    return;
  }
  return await authFetchWithTimeout(
    EXPO_ENDPOINT,
    EXPO_REQ(expoToken),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log(error.message);
    });
};

export const deleteExpoToken = () => async (dispatch) => {
  return await authFetchWithTimeout(
    EXPO_DELETE_TOKEN_ENDPOINT,
    {
      method: "POST",
    },
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log(error.message);
      return null;
    });
};
