import { Platform } from "react-native";

export const MainConsts = {
  CREATE_COUPON_BUTTON: "Create IOU",

  NOTIFICATION_BAR_CREATING_COUPON: "Creating Coupon...",
  NOTIFICATION_BAR_CREATING_COUPON_FAILED: "Failed to create coupon",
  NOTIFICATION_BAR_CREATING_COUPON_SUCCESS: "Successfully created coupon",
};

export const TaskBarConsts = {
  COUPONS_YOU_OWE: "Coupons You Owe",
  COUPONS_OWED: "Coupons Owed To You",
};

export const CreateCouponConsts = {
  COUPON_DETAILS: "Coupon Details",
  SELECT_FRIEND: "Select Friend",
  SUBMIT_MODAL: "Create & Share",
  CREATE_COOPON_LINK: "Create a Coopon link",

  NAME_LABEL: "What're you willing to do",
  NAME_PH: "e.g. Will make dinner this entire week",
  NAME_KEY: "name",
  NAME_ERROR_1: "Required field",
  NAME_ERROR_2: "Must be less than 40 characters in length",
  NAME_ERROR_3: "Certain special characters are not allowed",

  DESC_LABEL: "Reason for coupon",
  DESC_PH: "e.g. You walked the dogs for a whole month",
  DESC_KEY: "description",
  DESC_ERROR: "Must be less than 200 characters in length",

  START_DATE_LABEL: "Set Start Date",
  START_DATE_LABEL_AFTER_SET: "Start Date: ",
  START_DATE_PH: "Enter date coupon becomes effective",
  START_DATE_KEY: "startDate",
  START_DATE_ERROR: "Invalid date",

  END_DATE_LABEL: "No expiration",
  END_DATE_LABEL_AFTER_SET: "End Date: ",
  END_DATE_PH: "Enter expiration date",
  END_DATE_KEY: "endDate",
  END_DATE_ERROR: "Invalid date",

  RECEIVER_LABEL: "Send to",
  RECEIVER_PH: "Who to send to?",
  RECEIVER_KEY: "receiver",
  RECEIVER_ERROR_1: "Invalid user",
  RECEIVER_ERROR_2: "Required field",
  RECEIVER_ERROR_3: "Must be less than 40 characters in length",
  RECEIVER_ERROR_4: "No special characters allowed, except for . and @",
  RECEIVER_ERROR_5: "No spaces allowed",
  RECEIVER_ERROR_6: "User not found",
  RECEIVER_ERROR_7: "Can't send a coupon to yourself, silly",

  LIST_OF_REDEEMERS_LABEL: "Redeemers",
  LIST_OF_REDEEMERS_PH: "Who else will be able to redeem this coupon?",
  LIST_OF_REDEEMERS_KEY: "redeemers",
  LIST_OF_REDEEMERS_ERROR: "Invalid users",

  REDEEM_BY_LABEL: "Redeem By",
  REDEEM_BY_DEFAULT_VALUE: "No expiration",

  USERNAME_LABEL: "Enter Username",
  USERNAME_PH: "Find a buddy",
  USERNAME_KEY: "buddyUsername",
  USERNAME_ERROR_1: " not found",

  DATETIME_CLOSE_MODAL: "Close",
  DATETIME_SET_MODAL: "Set",
  SHARE_COUPON: (sender, title) =>
    title
      ? `[${title}] by ${sender}! Get the Coopon at ${
          Platform.OS === "android"
            ? CreateCouponConsts.ANDROID_STORE_URL
            : CreateCouponConsts.IOS_STORE_URL
        }`
      : `${sender} wants to share a Coopon with you! ${
          Platform.OS === "android"
            ? CreateCouponConsts.ANDROID_STORE_URL
            : CreateCouponConsts.IOS_STORE_URL
        }`,
  ANDROID_STORE_URL:
    "https://play.google.com/apps/testing/org.aegeanapps.coopons",
  IOS_STORE_URL: "",
};

export const CouponsSentConsts = {
  TITLE: "Sent Coopons",
  COUPON_TYPE: "SENT",
  NO_COUPONS_FOUND: "You haven't assigned any coopons.",
};

export const CouponsReceivedConsts = {
  TITLE: "Received Coopons",
  COUPON_TYPE: "RECEIVED",
  NO_COUPONS_FOUND: "You haven't been assigned any coopons.",
};

export const CouponsListConsts = {
  TITLE: "Coopons",
  NO_ACTIVE_COUPONS: "You have no active coopons",
  NO_SENT_COUPONS: "You haven't sent any coopons yet",
  CREATE_A_COUPON: "Create a Coopon",
  COMPLETED_EXPIRED_SECTION_TITLE: "Completed & Expired",
  ERROR_LOADING_COUPONS_MSG: "Failed to get your coopons",
  ERROR_RETRY_BTN: "Try again",
};

export const CouponsConsts = {
  NO_DESCRIPTION: "No Description",
  COUPON_SENTTO_MSG: "Sent To",
  COUPON_SENTREQUEST_MSG: "sent request to",
  COUPON_RECEIVEDBY_MSG: "Sent By",

  TYPE_SENT: "SENT",
  TYPE_RECEIVED: "RECEIVED",

  STATUS_ACCEPTED: "ACCEPTED",
  STATUS_REJECTED: "REJECTED",
  STATUS_REQUEST: "REQUEST",
  STATUS_SENT: "SENT",
  STATUS_SENT_LINK: "SENT_LINK",
  STATUS_WAITING_FOR_COMPLETION: "WAITING_FOR_COMPLETION",
  STATUS_REDEEMED: "REDEEMED",
  STATUS_COMPLETED: "COMPLETED",
  STATUS_RATED: "RATED",
  STATUS_EXPIRED: "EXPIRED",

  STATE_ACTIVE: "ACTIVE",
  STATE_INACTIVE: "INACTIVE",

  STATUS_ACCEPTED_ICON: "A",
  STATUS_REJECTED_ICON: "D",
  STATUS_REQUEST_ICON: "R",
  STATUS_SENT_ICON: "S",
  STATUS_WAITING_FOR_COMPLETION_ICON: "W",
  STATUS_REDEEMED_ICON: "R",
  STATUS_GO_ICON: "GO!",
  STATUS_COMPLETED_ICON: "C",
  STATUS_RATED_ICON: "C",
  STATUS_EXPIRED_ICON: "E",

  ACCEPT: "Accept",
  CLAIM: "Claim",
  DECLINE: "Decline",
  DELETE: "Delete",
  REDEEM: "Redeem",
  COMPLETE: "Completed",
  COPY_TO_CLIPBOARD: "Copy",
  SHARE: "Share",

  CONFIRMATION_DONE: "Yep, all done!",
  CONFIRMATION_NOT_DONE: "Not yet",
  CONFIRMATION_TITLE: (receiver) =>
    `Have you completed this task for ${receiver}?`,

  NO_EXPIRATION: "No expiration",
  COMPLETED: "Completed",

  TIME_LEFT: "Time Left",
  SECONDS_LEFT: "Seconds Left",
  SECOND_LEFT: "Second Left",
  MINUTES_LEFT: "Minutes Left",
  MINUTE_LEFT: "Minute Left",
  HOURS_LEFT: "Hours Left",
  HOUR_LEFT: "Hour Left",
  DAYS_LEFT: "Days Left",
  DAY_LEFT: "Day Left",
  MONTHS_LEFT: "Months Left",
  MONTH_LEFT: "Month Left",
  A_LONG_TIME: "A Long Time",
  TIME_UP: "Expired!",

  TASK_ERROR: (task) => `Failed to ${task} coopon. Try again later!`,
  TASK_COMPLETE: "complete",
  TASK_DELETE: "delete",
  TASK_DECLINE: "decline",
  TASK_ACCEPT: "accept",
  TASK_REDEEM: "redeem",
  TASK_RATE: "rate",
  TASK_SEND_AGAIN: "send",

  DETAILS_COUPON_TEMPLATE_TYPE_LABEL: (couponType) =>
    `You ${couponType === CouponsConsts.TYPE_RECEIVED ? "Received" : "Sent"}`,
  DETAILS_COUPON_TEMPLATE_DESCRIPTION: "because",
  DETAILS_COUPON_TEMPLATE_START_DATE: "Created On",
  DETAILS_COUPON_TEMPLATE_END_DATE: "Will Expire On",

  DETAILS_COMPLETED: "Completed",
  DETAILS_REQUEST: "Request",
  DETAILS_SENT: "Sent",
  DETAILS_SENT_LINK_FOR_RECEIVER: "Claimable",
  DETAILS_SENT_LINK_FOR_SENT: "Ready to be claimed",
  DETAILS_ACCEPTED: "Accepted",
  DETAILS_REJECTED: "Rejected",
  DETAILS_REDEEMED: "Redeemed",
  DETAILS_INPROGRESS: "In Progress",
  DETAILS_EXPIRED: "Expired",
  DETAILS_UNKNOWN: "Unknown",

  DETAILS_RATING_INFO: "Was this coopon completed to your liking?",
  DETAILS_COMPLETED_INFO: "This coopon was marked as completed",
  DETAILS_REQUEST_INFO:
    "You received a coopon! What would you like to do with it?",
  DETAILS_SENT_LINK_INFO_FOR_RECEIVER:
    "Anyone can claim this Coopon!\nYa want it?",
  DETAILS_SENT_LINK_INFO_FOR_SENT: "Share the link to give this Coopon away!",
  DETAILS_SENT_INFO: (receiver) =>
    `You sent a coopon to ${receiver}! Waiting on their response`,
  DETAILS_ACCEPTED_SENT_INFO: (receiver) =>
    `Your coopon was accepted! ${receiver} can redeem it at anytime as long as it has not expired`,
  DETAILS_ACCEPTED_RECEIVED_INFO:
    "You accepted this coopon! Redeem at anytime to use it!",
  DETAILS_REJECTED_INFO: "Your coopon was rejected",
  DETAILS_REDEEMED_INFO: (sender) =>
    `You activated the coopon! Waiting on ${sender} to complete the task`,
  DETAILS_INPROGRESS_INFO: (receiver) =>
    `${receiver} redeemed the coopon! Complete the task!`,
  DETAILS_EXPIRED_INFO: "This coopon expired",
  DETAILS_UNKNOWN_INFO: "Failed to get info. Try again later",
};

export const LoginConsts = {
  TITLE_SIGNIN: "SIGN IN",

  USERNAME: "Username",
  PASSWORD: "Password",
  SIGN_IN: "Sign In",
  LOG_IN: "Log In",
  SIGN_UP: "Sign Up",
  SIGN_UP_TEXT: "Don't have an account?",
  OR_SIGN_IN_WITH: "Or continue with",

  NETWORK_ERROR: "NetworkError",
  USERNAME_ERROR_1: "Please specify a username",
  PASSWORD_ERROR_1: "Please specify a password",

  GOOGLE_SIGN_IN_ERROR_1: "Couldn't log you in",
  GOOGLE_SIGN_IN_ERROR_2: "An account with this email address already exists",

  INCORRECT_CREDENTIALS: "Incorrect username/email address or password",
  FAILED_TO_SIGN_IN: "Couldn't log you in",
};

export const ResetPassConsts = {
  TITLE_RESETPASS: "RESET PASSWORD",
  SUBMIT_EMAIL: "Submit",
  EMAIL_ADDRESS: "Email Address",
  ENTER_REGISTERED_EMAIL: "Enter your email address",
  RESET_MESSAGE:
    "We sent a reset link to this email address if there's an account associated with it.",
};

export const SignupConsts = {
  USERNAME_FIELD: "Username",
  EMAIL_ADDR_FIELD: "Email Address",
  PASSWORD_FIELD: "Password",
  FIRSTNAME_FIELD: "First Name",
  LASTNAME_FIELD: "Last Name",
  FULLNAME_FIELD: "Display Name (Optional)",

  REQUIRED_FIELD_ERROR: "Required",

  USERNAME_ERROR_1: "Username is already taken",

  EMAIL_ADDR_ERROR_1: "An account with this email address already exists",

  PASSWORD_ERROR_1: "Password needs to be longer than 4 characters",

  TITLE_SIGNUP: "SIGN UP",
  SIGNUP_LINK_TEXT: "Sign up",
  SIGNIN_TEXT: "Have an account?",
  SIGNIN: "Sign In",

  UNAUTHORIZED_ACCOUNT_EXISTS: "Account already exists",
  SUCCESSFULLY_CREATED_ACCOUNT: "Account successfully created!",
};

export const AuthorizingConsts = {
  FAILED_TO_GET_PROFILE: "Failed to get profile for user",
};

export const NetworkConsts = {
  NETWORK_REQUEST_FAILED: "Network request failed",
  NETWORK_REQUEST_TIMED_OUT: "Network request timed out",

  FAILED_TO_FETCH_ITEMS: "Failed to fetch items",
  FAILED_TO_DELETE_ITEM: "Failed to delete item",
  FAILED_TO_ADD_ITEM: "Failed to add item",

  AUTHENTICATION_FAILED: "Authentication failed",
  AUTHENTICATION_FAILED_NAME: "AuthError",
};

export const AppDrawerConsts = {
  SIGN_OUT: "Sign Out",
  BUDDIES_BUTTON: "Buddies",
  EDIT_PROFILE_BUTTON: "Edit Profile",

  WELCOME_MSG: "Welcome,",
};

export const BuddiesConsts = {
  PEOPLE_I_KNOW: "People I know",
  ADD_BUDDY: "Add a friend",
  BUDS: "Buds",
  FAILED_TO_ADD_BUDDY: "Failed to add friend",
  FAILED_TO_FETCH_BUDDIES: "Failed to retrieve list of friends",
  RETRY_FETCHING_FRIENDS: "Retry",
  NO_FRIENDS: "You have no friends.",
  SEARCHING_FOR_FRIEND: (friend) => `Searching for "${friend}"`,
  NO_USER_WITH_NAME: (friend) => `No user with name "${friend}"`,
  ALREADY_FRIENDS_WITH: (friend) => `You're already friends with "${friend}"`,
  ADD: "+",
};

export const ProfileConsts = {
  OTHER_PROFILE_TITLE: "'s Profile",
  OTHER_PROFILE_TITLE_WITHOUT_S: "' Profile",
  MY_PROFILE: "My Profile",
  MY_REPUTATION: "My Reputation",
  OTHER_PROFILE_REPUTATION: "Reputation",
  NOT_AVAILABLE_REPUTATION: "N/A",
  FIELD_EMAIL: "Email",
  FIELD_USERNAME: "Username",
  FIELD_FULLNAME: "Display Name",
  FIELD_REPUTATION: "Reputation",
  USER_NOT_FOUND: "User not found!",
  PROFILE_FETCH_ERROR: (username) => `Failed to get profile for ${username}`,
  TRY_AGAIN: "Try again",
  FIELD_NO_DISPLAYNAME: "empty",
};

export const EditProfileConsts = {
  CHANGE_VATAR: "Change avatar",
  NAME_LABEL: "Display Name",
  NAME_KEY: "fullname",
  NAME_ERROR_1: "This field can't be shorter than 3 characters",
  NAME_ERROR_2: "Must be less than 20 characters in length",
  NAME_ERROR_3: "Certain special characters are not allowed",
  NAME_ERROR_4: "This field can't be empty",

  ERROR_1: "Values have not changed",
  ERROR_2: "Failed to update. Try again later",

  DONE: "Done",
  USER_NOT_FOUND: "User not found!",
};

export const HomeHeaderConsts = {
  MOTD: "Hello!",
  OPTION_1: "Sign Out",
};

export const NavigationConsts = {
  LOGIN_SCREEN: "login",
  SIGNUP_SCREEN: "signup",
  HOME_SCREEN: "home",
  PROFILE_SCREEN: "profile",
  EDIT_PROFILE_SCREEN: "editprofile",
  SETTINGS_SCREEN: "settings",
  BUDDIES_SCREEN: "buddies",
  RECEIVED_SCREEN: "received",
  SENT_SCREEN: "sent",
  COUPON_SCREEN: "coupon",
  CREATECOUPON_SCREEN: "createcoupon",
  BUDDIES_STACK: "buddiesstack",
  HOME_STACK: "homestack",
  PROFILE_STACK: "profilestack",
  COUPONS_STACK: "couponsstack",
  COUPONS_TABS: "couponstabs",
  MAIN_TAB: "maintab",

  NO_HEADER_TITLE: "",
  BUDDIES_HEADER_TITLE: "All Friends",
  COUPON_DETAILS_TITLE: "Coopon Details",
  CREATE_COUPON_TITLE: "Create Coopon",
  PROFILE_TITLE: "My Profile",
  EDITPROFILE_TITLE: "Edit Profile",
  SETTINGS_TITLE: "Settings",
  CONTACT_US: "Contact Us",
  RECEIVED_LABEL: "Received",
  SENT_LABEL: "Sent",
};

export const AvatarConsts = {
  CLOSE: "Close",
};
