export const BACKGROUND_COLOR = "#F6F6F6";
export const OPAGUE_BLACK = "#0008";
export const REJECTED_STATUS_ICON_COLOR = "#FE6766";
export const ACCEPTED_STATUS_ICON_COLOR = "#80BA91";
export const REQUEST_STATUS_ICON_COLOR = "#2D54DF";

export const APP_COLOR_1 = "#2D54DF"; // Buttons
export const APP_COLOR_1_OPAQUE = "#5bba6f61";
export const APP_COLOR_2 = "#2D54DF"; // Sign-in sign-up links
export const APP_COLOR_3 = "#2D54DF"; // Not used as of commit 586092a1
export const APP_COLOR_4 = "#FFFFFF"; // Coupon background
export const APP_COLOR_5 = "#2D54DF"; // Not used as of commit 586092a1

export const ALT_APP_COLOR_1 = "#2F3034"; // Button text color 1
export const ALT_APP_COLOR_2 = "#F6F6F6"; // Button BG color 1
export const ALT_APP_COLOR_3 = "#FFFFFF"; // Inactive task bar button
export const ALT_APP_COLOR_4 = "#FFFFFF"; // Input container

//Dark colors
export const DARK = "black";
export const DARK_ALT_APP_COLOR_2 = "#4E4F4D";

export const TEXT_COLOR_1 = "#2D54DF";
export const TExT_COLOR_2 = "#FE6766";
export const TEXT_COLOR_3 = "#FFFFFF";
export const TEXT_COLOR_4 = "#999999";
export const TEXT_COLOR_5 = "#000000";
export const TEXT_COLOR_6 = "#2F3034";
export const TEXT_COLOR_7 = "#dcdcdc";

export const ICON_COLOR_1 = "#707070";
export const ICON_COLOR_2 = "#FE6766";
export const ICON_COLOR_3 = "#FFFFFF";

export const BUTTON_COLOR_1 = "#2D54DF";
export const BUTTON_COLOR_2 = "#FE6766";
export const BUTTON_COLOR_3 = "#F6F6F6";
export const BUTTON_COLOR_4 = "#80BA91";

export const COMPONENT_COLOR_1 = "#2D54DF";
export const COMPONENT_COLOR_2 = "#FE6766";
export const COMPONENT_COLOR_3 = "#FFFFFF";
export const COMPONENT_COLOR_4 = "#80BA91";
export const COMPONENT_COLOR_5 = "#707070";
export const COMPONENT_COLOR_6 = "#2F3034";
export const COMPONENT_COLOR_7 = "#B9C4EE";

export const COUPON_STATE_COLOR_1 = "#2D54DF";
export const COUPON_STATE_COLOR_2 = "#f4de15";
export const COUPON_STATE_COLOR_3 = "#b03500";
export const COUPON_STATE_COLOR_4 = "#ffe9a6";
export const COUPON_STATE_COLOR_5 = "#43e672";
export const COUPON_STATE_COLOR_6 = "#acfcc3";

export const RATING_COLOR_1 = "#FFC107";
