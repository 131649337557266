import {
  authFetchWithTimeout,
  fetchWithTimeout,
  processResponse,
} from "../../../common/network";
import {
  fetchSentCouponsAction,
  fetchReceivedCouponsAction,
  deleteReceivedCouponAction,
  addSentCouponAction,
  deleteSentCouponAction,
} from "../actions/CouponActions";
import { CouponsConsts } from "../../../common/constants";
import { getApiURL } from "../../../common/environment";
import { log } from "../../../common/logging";

const hostname = getApiURL();
const GET_SENT_LINK_COUPON = hostname + "/api/sent/app";
const GET_SENT_COUPONS = hostname + "/api/sent";
const ADD_SENT_COUPON = hostname + "/api/sent/add";
const DELETE_SENT_COUPON = hostname + "/api/sent/delete";

const GET_RECEIVED_COUPONS = hostname + "/api/received";
const ADD_RECEIVED_COUPON = hostname + "/api/received/add";
const DELETE_RECEIVED_COUPON = hostname + "/api/received/delete";

const GET_REQ = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
};

const POST_REQ = (body) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: body ? JSON.stringify(body) : null,
});

export const fetchShareableCoupon = (cooponId) =>
  fetchWithTimeout(GET_SENT_LINK_COUPON + "/" + cooponId, GET_REQ)
    .then(processResponse)
    .then((res) => res.data)
    .catch((error) => {
      log("Failed to fetch coopon with ID " + cooponId + ": " + error.message);
      return null;
    });

export const fetchSentCoupon = (cooponId) => async (dispatch) =>
  authFetchWithTimeout(GET_SENT_COUPONS + "/" + cooponId, GET_REQ, dispatch)
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log("Failed to fetch coopon with ID " + cooponId + ": " + error.message);
      return null;
    });

export const fetchSentCoupons =
  (resultCallbacks = null) =>
  async (dispatch) => {
    return authFetchWithTimeout(GET_SENT_COUPONS, GET_REQ, dispatch)
      .then(processResponse)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.data);
        }
        dispatch(fetchSentCouponsAction(res.data));
        resultCallbacks ? resultCallbacks.success() : null;
      })
      .catch((error) => {
        log("Error fetching sent coupons: ", error.message);
        resultCallbacks ? resultCallbacks.failure() : null;
      });
  };

const prepareCouponObject = (coupon, couponStatus) => {
  return {
    status: couponStatus,
    ...coupon,
  };
};

export const createSentCoupon = (coupon) => async (dispatch) => {
  log(
    "Adding coupon: " +
      JSON.stringify(prepareCouponObject(coupon, CouponsConsts.STATUS_SENT))
  );
  return authFetchWithTimeout(
    ADD_SENT_COUPON,
    POST_REQ(prepareCouponObject(coupon, CouponsConsts.STATUS_SENT)),
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 201) {
        throw new Error(res.data.message);
      }
      const successMsg = "Coupon added successfully! - " + res.data.message;
      dispatch(addSentCouponAction({ ...res.data.addedCoupon, isNew: true }));
    })
    .catch((error) => {
      log("Coupon failed to be added: " + error.message);
    });
};

export const deleteSentCoupon =
  (couponId, resultCallbacks = null, disableDelete = false) =>
  (dispatch) => {
    return authFetchWithTimeout(
      DELETE_SENT_COUPON + "/" + couponId,
      POST_REQ(null),
      dispatch
    )
      .then(processResponse)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.data.message);
        }
        if (!disableDelete) {
          dispatch(deleteSentCouponAction(couponId));
        }
        resultCallbacks ? resultCallbacks.success() : null;
        return res.data;
      })
      .catch((error) => {
        log("Failed to delete item because ", error.message);
        resultCallbacks ? resultCallbacks.failure() : null;
        return null;
      });
  };

export const fetchReceivedCoupon = (cooponId) => async (dispatch) =>
  authFetchWithTimeout(GET_RECEIVED_COUPONS + "/" + cooponId, GET_REQ, dispatch)
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log("Failed to fetch coopon with ID " + cooponId + ": " + error.message);
      return null;
    });

export const fetchReceivedCoupons =
  (resultCallbacks = null) =>
  async (dispatch) => {
    return authFetchWithTimeout(GET_RECEIVED_COUPONS, GET_REQ, dispatch)
      .then(processResponse)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.data);
        }
        dispatch(fetchReceivedCouponsAction(res.data));
        resultCallbacks ? resultCallbacks.success() : null;
      })
      .catch((error) => {
        log("Error fetching received coupons: ", error.message);
        resultCallbacks ? resultCallbacks.failure() : null;
      });
  };

export const createReceivedCoupon = (coupon) => (dispatch) => {
  return authFetchWithTimeout(
    ADD_RECEIVED_COUPON,
    POST_REQ(
      prepareCouponObject(coupon, CouponsConsts.STATUS_REQUEST),
      dispatch
    )
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 201) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((error) => {
      log("Error: ", error.message);
      return null;
    });
};

export const deleteReceivedCoupon =
  (couponId, resultCallbacks = null, disableDelete = false) =>
  (dispatch) => {
    return authFetchWithTimeout(
      DELETE_RECEIVED_COUPON + "/" + couponId,
      POST_REQ(null),
      dispatch
    )
      .then(processResponse)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.data.message);
        }
        if (!disableDelete) {
          dispatch(deleteReceivedCouponAction(couponId));
        }
        resultCallbacks ? resultCallbacks.success() : null;
        return res.data;
      })
      .catch((error) => {
        log("Failed to delete item because ", error.message);
        resultCallbacks ? resultCallbacks.failure() : null;
        return null;
      });
  };
