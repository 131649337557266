import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import { bindActionCreators } from "redux";
import { Text, Input } from "@rneui/themed";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  updateUserProfile,
  getUserProfile,
} from "../../modules/coupons/services/ProfileService";
import { editProfileStyle } from "../../styles/EditProfileStyle";
import { LoadingIndicator } from "../LoadingIndicator";
import { EditProfileConsts } from "../../common/constants";
import { AvatarPicker } from "../Avatars";
import { avatars } from "../../common/assets";
import { Button } from "../../common/Button";
import { COMPONENT_COLOR_3 } from "../../common/colors";
import { updateUserProfileAction } from "../../modules/coupons/actions/ProfileActions";
import { isEmoji } from "../../common/utilities";
import { log } from "../../common/logging";

export class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: null,
      fullnameError: null,
      generalError: null,
      avatarID: null,

      isAvatarPickerVisible: false,
      isFetching: true,
      isUpdating: false,
      showDoneButton: false,
    };
  }

  async componentDidMount() {
    const { userProfile } = this.props.profile;

    if (!userProfile) {
      try {
        await this.props.getUserProfile();
      } catch (e) {}
    }

    if (userProfile) {
      this.setState({
        avatarID: userProfile.avatarID,
        fullname: userProfile.fullname,
      });
    }

    this.setState({ isFetching: false });
  }

  componentDidUpdate = () => {
    const { userProfile } = this.props.profile;
    var { avatarID, fullname } = this.state;

    if (fullname) {
      fullname = fullname.trim();
    }

    if (
      userProfile.avatarID !== avatarID ||
      userProfile.fullname !== fullname
    ) {
      if (!this.state.showDoneButton) {
        this.setState({ showDoneButton: true });
      }
    } else {
      if (this.state.showDoneButton) {
        this.setState({ showDoneButton: false });
      }
    }
  };

  validateInputs = () => {
    let { fullname } = this.state;

    fullname = fullname.trim();

    const doesFullnameContainSpecialChars =
      fullname.search(/^[a-zA-Z0-9!\.\[\]\(\)&? ]*$/) === -1;
    const isFullnameTooLong = fullname.search(/^.{0,30}$/) === -1;
    const doesDisplayNameContainEmojis = fullname.search(isEmoji) !== -1;

    if (isFullnameTooLong) {
      this.setState({ fullnameError: EditProfileConsts.NAME_ERROR_2 });
      throw new Error(EditProfileConsts.NAME_ERROR_2);
    }
    if (doesFullnameContainSpecialChars && !doesDisplayNameContainEmojis) {
      this.setState({ fullnameError: EditProfileConsts.NAME_ERROR_3 });
      throw new Error(EditProfileConsts.NAME_ERROR_3);
    }

    // Allow value to be set if fullname is empty
    if (
      !(this.getChangedValue("fullname") || this.getChangedValue("avatarID")) &&
      this.getChangedValue("fullname") !== ""
    ) {
      throw new Error(EditProfileConsts.ERROR_1);
    }
    this.setState({ fullnameError: null });
  };

  handleTextInput = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  setAvatar = (id) => {
    this.setState({
      avatarID: id,
    });
  };

  toggleAvatarPicker = () => {
    this.setState({
      isAvatarPickerVisible: !this.state.isAvatarPickerVisible,
    });
  };

  handleDone = async () => {
    this.setState({ isUpdating: true });
    try {
      this.validateInputs();

      let newProfileValues = {};
      const newAvatarID = this.getChangedValue("avatarID");
      const newFullname = this.getChangedValue("fullname");
      if (newAvatarID) {
        newProfileValues.avatarID = newAvatarID;
      }
      if (newFullname) {
        newProfileValues.fullname = newFullname.trim();
      } else if (newFullname === "") {
        newProfileValues.fullname = "";
      }

      if (newProfileValues) {
        const result = await this.props.updateUserProfile(newProfileValues);
        if (!result) {
          this.setState({ generalError: EditProfileConsts.ERROR_2 });
        } else {
          this.props.updateUserProfileAction(newProfileValues);
          this.props.navigation.goBack();
          return;
        }
      }
    } catch (e) {
      log(e.message);
    }
    this.setState({ isUpdating: false });
  };

  getChangedValue = (key) => {
    const { userProfile } = this.props.profile;

    if (userProfile[key] === this.state[key]) {
      return null;
    }
    return this.state[key];
  };

  render() {
    const {
      showDoneButton,
      isFetching,
      isAvatarPickerVisible,
      avatarID,
      fullname,
      fullnameError,
      generalError,
      isUpdating,
    } = this.state;
    const { userProfile } = this.props.profile;

    return (
      <View style={editProfileStyle.container}>
        <LoadingIndicator isLoading={isFetching}>
          {userProfile === null || Object.keys(userProfile).length === 0 ? (
            <Text h1>{EditProfileConsts.USER_NOT_FOUND}</Text>
          ) : (
            <View style={editProfileStyle.contentContainer}>
              <EditProfileForm
                userProfile={userProfile}
                fullname={fullname}
                fullnameError={fullnameError}
                generalError={generalError}
                handleTextInput={this.handleTextInput}
                avatarID={avatarID}
                toggleAvatarPicker={this.toggleAvatarPicker}
                showDoneButton={showDoneButton}
                handleDone={this.handleDone}
                isUpdating={isUpdating}
              />
            </View>
          )}
        </LoadingIndicator>
        <AvatarPicker
          setAvatar={this.setAvatar}
          toggleVisibility={this.toggleAvatarPicker}
          isVisible={isAvatarPickerVisible}
        />
      </View>
    );
  }
}

const EditProfileForm = (props) => {
  const {
    userProfile,
    fullnameError,
    handleTextInput,
    avatarID,
    toggleAvatarPicker,
    showDoneButton,
    handleDone,
    isUpdating,
    generalError,
  } = props;

  return (
    <View style={editProfileStyle.formContainer}>
      <View style={editProfileStyle.userDetailsInputContainer}>
        <ChooseAvatar
          avatarID={avatarID}
          setAvatarPicketVisible={toggleAvatarPicker}
        />
        <Input
          containerStyle={editProfileStyle.userDetailsInput}
          label={EditProfileConsts.NAME_LABEL}
          defaultValue={userProfile.fullname}
          onChangeText={(text) =>
            handleTextInput(EditProfileConsts.NAME_KEY, text)
          }
          errorMessage={fullnameError}
        />
      </View>
      <View>
        <Text style={editProfileStyle.generalErrorText}>{generalError}</Text>
        {showDoneButton ? (
          <Button
            isLoading={isUpdating}
            onLoadingJSX={
              <ActivityIndicator
                style={editProfileStyle.doneButtonLoading}
                color={COMPONENT_COLOR_3}
              />
            }
            type={1}
            onPress={handleDone}
          >
            {EditProfileConsts.DONE}
          </Button>
        ) : null}
      </View>
    </View>
  );
};

const ChooseAvatar = (props) => {
  if (props.avatarID === null) {
    return null;
  }

  const Avatar = avatars[props.avatarID];

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={props.setAvatarPicketVisible}
      style={editProfileStyle.avatar}
    >
      <Avatar height={45} width={45} />
      <Text style={editProfileStyle.changeAvatarText}>
        {EditProfileConsts.CHANGE_VATAR}
      </Text>
    </TouchableOpacity>
  );
};

EditProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfile: getUserProfile,
      updateUserProfile: updateUserProfile,
      updateUserProfileAction: updateUserProfileAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
