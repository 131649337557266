import { StackNavigationProp } from "@react-navigation/stack";
import { Divider } from "@rneui/themed";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  ALT_APP_COLOR_2,
  BACKGROUND_COLOR,
  TEXT_COLOR_6,
} from "../../../common/colors";
import { SettingsStackParamList } from "../../../modules/Navigation";
import { isEmailComposerAvailable, openEmailPrompt } from "./ContactUs";
import { Share } from "react-native";
import * as Sentry from "sentry-expo";
import { bindActionCreators, Dispatch } from "redux";
import { signOut } from "../../../modules/coupons/services/AuthService";
import { connect } from "react-redux";
import * as Updates from "expo-updates";

type SettingsScreenNavigationProp = StackNavigationProp<
  SettingsStackParamList,
  "settings"
>;

type Props = {
  navigation: SettingsScreenNavigationProp;
} & ReturnType<typeof mapDispatchToProps>;

const Settings = (props: Props) => {
  const handleContact = async () => {
    const isEmailAvailable = await isEmailComposerAvailable();
    if (isEmailAvailable) {
      await openEmailPrompt();
    } else {
      props.navigation.navigate("contactus");
    }
  };

  const handleShare = async () => {
    try {
      const result = await Share.share({
        message:
          "Create personalized Coopons\nhttps://play.google.com/store/apps/details?id=org.psyntax.coopons",
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.debug("Failed to share ", error.message);
      Sentry.Native.captureException(error);
    }
  };

  const handleSignOut = () => props.signOut(true);

  return (
    <View style={style.container}>
      <Divider />
      <SettingItem title="Contact Us" onPress={handleContact} />
      <Divider inset={true} insetType="middle" />
      <SettingItem title="Share our app" onPress={handleShare} />
      <Divider inset={true} insetType="middle" />
      <SettingItem title="Sign out" onPress={handleSignOut} />
      <Divider />
    </View>
  );
};

const SettingItem = (props: { title: string; onPress?: () => void }) => {
  return (
    <TouchableOpacity activeOpacity={1} onPress={props.onPress}>
      <View style={style.button}>
        <Text style={style.buttonText}>{props.title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const style = StyleSheet.create({
  container: {
    backgroundColor: BACKGROUND_COLOR,
    flex: 1,
  },
  button: {
    marginHorizontal: 8,
    padding: 16,
    backgroundColor: ALT_APP_COLOR_2,
  },
  buttonText: {
    fontSize: 18,
    color: TEXT_COLOR_6,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signOut: signOut,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Settings);
