import { authFetchWithTimeout, processResponse } from "../../../common/network";
import { updateUserProfileAction } from "../actions/ProfileActions";
import { getApiURL } from "../../../common/environment";
import { log } from "../../../common/logging";

const backendUrl = getApiURL();

const getUserProfileEndpoint = `${backendUrl}/api/profile`;
const getProfileSuggestionsEndpoint = `${backendUrl}/api/profile/suggestions/`;
const updateUserProfileEndpoint = `${backendUrl}/api/profile/edit`;

const GET = {
  method: "GET",
};

// Empty otherUserId means getUserProfile will return own user profile
export const getUserProfile =
  (otherUserId = "") =>
  async (dispatch) => {
    return authFetchWithTimeout(
      getUserProfileEndpoint + (otherUserId ? "/" + otherUserId : otherUserId),
      GET,
      dispatch
    )
      .then(processResponse)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.data.message);
        }
        if (otherUserId === "") {
          dispatch(updateUserProfileAction(res.data));
        }
        return res.data;
      })
      .catch((error) => {
        log("Error getting user profile: ", error.message);
        throw new Error(error.message);
      });
  };

export const getUserProfileSuggestions = (username) => (dispatch) => {
  return authFetchWithTimeout(
    getProfileSuggestionsEndpoint + username,
    GET,
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log("Error getting user profile suggestions: ", error.message);
      return null;
    });
};

export const updateUserProfile = (profileValues) => (dispatch) => {
  return authFetchWithTimeout(
    updateUserProfileEndpoint,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(profileValues),
    },
    dispatch
  )
    .then(processResponse)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      log("Error updating user profile: ", error.message);
    });
};
