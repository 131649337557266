import Constants from "expo-constants";
import { ToastAndroid } from "react-native";
import { getUpdateID } from "./environment";

export const isEmoji =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|\u200D|\uFE0F)/gi;

export const unicodeToEmojisString = (string) =>
  string.replace(/\\u(\w{4,4})/g, function (a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });

function toUTF16(codePoint) {
  var TEN_BITS = parseInt("1111111111", 2);
  function u(codeUnit) {
    let base16CodeUnit = codeUnit.toString(16).toUpperCase();
    return (
      "\\u" + "0000".substring(0, 4 - base16CodeUnit.length) + base16CodeUnit
    );
  }

  if (codePoint <= 0xffff) {
    return u(codePoint);
  }
  codePoint -= 0x10000;

  // Shift right to get to most significant 10 bits
  var leadSurrogate = 0xd800 + (codePoint >> 10);

  // Mask to get least significant 10 bits
  var tailSurrogate = 0xdc00 + (codePoint & TEN_BITS);

  return u(leadSurrogate) + u(tailSurrogate);
}

export const convertStringToUnicode = (string) => {
  const regex = isEmoji;

  if (!string) return "";
  const messageArray = [...string];
  for (let i = 0; i < messageArray.length; i++) {
    if (messageArray[i].match(regex)) {
      messageArray[i] = toUTF16(messageArray[i].codePointAt(0));
    }
  }
  return messageArray.join("");
};

// Show app info when you click the logo
export const showAppInfoToast = () =>
  ToastAndroid.show(
    "Version " +
      Constants.expoConfig.version +
      " - " +
      getUpdateID() +
      (Constants.expoConfig.extra.REALM
        ? "\nenv " + Constants.expoConfig.extra.REALM
        : ""),
    ToastAndroid.SHORT
  );
