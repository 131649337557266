import { StyleSheet } from "react-native";
import { FONT_SIZE_1 } from "../common/dimensions";
import {
  BACKGROUND_COLOR,
  COMPONENT_COLOR_3,
  TEXT_COLOR_4,
  TEXT_COLOR_3,
  COUPON_STATE_COLOR_1,
} from "../common/colors";

export const buddiesStyle = StyleSheet.create({
  container: {
    backgroundColor: BACKGROUND_COLOR,
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 8,
    marginBottom: 32,
  },
  findFriendContainer: {
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 8,
  },
  findFriend: {},
  findFriendInput: {
    marginTop: 4,
    marginHorizontal: 4,
  },
  findFriendIcon: {
    marginRight: 18,
  },
  dropdownContainer: {
    marginTop: 16,
    maxHeight: "35%",
  },
  buddiesListTitle: {
    fontSize: FONT_SIZE_1,
    color: "white",
  },
  buddiesList: {},
  buddiesListItem: {
    backgroundColor: COMPONENT_COLOR_3,
  },
  buddiesListItemXButton: {
    padding: 8,
  },
  containerNoList: {
    flex: 0.8,
    justifyContent: "center",
    alignSelf: "center",
  },
  noListErrorText: {
    fontSize: 16,
    color: TEXT_COLOR_4,
    marginVertical: 16,
  },
  noListErrorButton: {
    marginHorizontal: 32,
  },
  emptyListContainer: {
    flex: 0.8,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyListText: {
    fontSize: 16,
    color: TEXT_COLOR_4,
    marginVertical: 16,
  },
  suggestionContainer: {
    marginVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    backgroundColor: COUPON_STATE_COLOR_1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  suggestionText: {
    color: TEXT_COLOR_3,
    fontSize: 16,
  },
  suggestionActionText: {
    color: TEXT_COLOR_3,
    fontSize: 40,
  },
  suggestionSearchingContainer: {
    marginVertical: 8,
    backgroundColor: COMPONENT_COLOR_3,
  },
  suggestionSearchingText: {
    marginLeft: 16,
    color: TEXT_COLOR_4,
    fontSize: 16,
  },
  buddiesDetailsTitle: {
    marginVertical: 16,
    color: TEXT_COLOR_4,
  },
});
