import React, { useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
  ActionSheetIOS,
  UIManager,
  findNodeHandle,
} from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { NavigationConsts, HomeHeaderConsts } from "../common/constants";
import TripleDotsIcon from "../assets/images/3dots.svg";
import PlusIcon from "../assets/images/plus.svg";
import { signOut } from "../modules/coupons/services/AuthService";
import { getValueBasedOnScreenSize } from "../common/dimensions";

const CouponsHeaderIcons = (props) => {
  const handleCreateCoupon = () => {
    props.navigation.navigate(NavigationConsts.CREATECOUPON_SCREEN);
  };

  const handleMenu = () => {
    props.navigation.navigate(NavigationConsts.SETTINGS_SCREEN);
  };

  return (
    <View style={style.headerIcons}>
      <TouchableOpacity
        activeOpacity={1}
        style={style.iconContainerExcludingLeftmostIcon}
        onPress={handleCreateCoupon}
      >
        <PlusIcon height={20} width={20} />
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={1}
        style={style.iconContainerExcludingLeftmostIcon}
        onPress={handleMenu}
      >
        <TripleDotsIcon height={20} width={20} />
      </TouchableOpacity>
    </View>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut: signOut,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CouponsHeaderIcons);

const style = StyleSheet.create({
  headerIcons: {
    flexDirection: "row",
    right: -getValueBasedOnScreenSize(8, 16),
  },
  iconContainerExcludingLeftmostIcon: {
    padding: 8,
    marginRight: getValueBasedOnScreenSize(8, 16),
  },
});
