import {
  UPDATE_JWT_TOKEN,
  UPDATE_USER_ACCOUNT,
  SIGN_OUT,
  SIGN_IN,
  AUTHORIZE_USER,
} from "./AuthActionTypes";

export const updateJwtTokenAction = (token) => ({
  type: UPDATE_JWT_TOKEN,
  payload: token,
});

export const updateUserAccountAction = (profile) => ({
  type: UPDATE_USER_ACCOUNT,
  payload: profile,
});

export const updateSignOutAction = () => ({
  type: SIGN_OUT,
});

export const updateSignInAction = (token) => ({
  type: SIGN_IN,
  payload: token,
});

export const authorizeUserAccessAction = () => ({
  type: AUTHORIZE_USER,
});
