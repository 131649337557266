import {
  UPDATE_JWT_TOKEN,
  UPDATE_USER_ACCOUNT,
  SIGN_IN,
  SIGN_OUT,
  AUTHORIZE_USER,
} from "../actions/AuthActionTypes";

export const authInitialState = {
  jwt: null,
  isFetchnigJWT: true,
  isSignout: false, //Just a flag for ui activity
  userAccount: null,
  authorizeUser: false,
};

export const auth = (state = authInitialState, action) => {
  switch (action.type) {
    case UPDATE_JWT_TOKEN:
      return {
        ...state,
        jwt: action.payload,
        isFetchnigJWT: false,
      };
    case UPDATE_USER_ACCOUNT:
      return {
        ...state,
        userAccount: action.payload,
      };
    case SIGN_IN:
      return {
        ...state,
        isSignout: false,
        jwt: action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignout: true,
        isFetchnigJWT: false,
        jwt: null,
        authorizeUser: false,
      };
    case AUTHORIZE_USER:
      return {
        ...state,
        authorizeUser: true,
      };
    default:
      return state;
  }
};
