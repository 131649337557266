import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  KeyboardAvoidingView,
  Text,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input } from "@rneui/themed";
import * as Sentry from "sentry-expo";

import { loginStyle } from "../../styles/LoginStyle";
import {
  LoginConsts,
  NetworkConsts,
  NavigationConsts,
  ResetPassConsts,
  SignupConsts,
} from "../../common/constants";
import { Button } from "../../common/Button";
import {
  signIn,
  getUserAccount,
  doesGoogleAccountExist,
  googleSignIn,
} from "../../modules/coupons/services/AuthService";
import { authorizeUserAccessAction } from "../../modules/coupons/actions/AuthActions";
import { getUserProfile } from "../../modules/coupons/services/ProfileService";
import { COMPONENT_COLOR_3, COMPONENT_COLOR_1 } from "../../common/colors";
import { updateExpoToken } from "../../modules/coupons/services/ExpoService";
import Logo from "../../assets/images/Logo1-1.svg";
import GoogleIcon from "../../assets/images/google.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Google from "expo-auth-session/providers/google";
import { ResponseType } from "expo-auth-session";
import { showAppInfoToast } from "../../common/utilities";
import { log } from "../../common/logging";

const Login = (props) => {
  const [IsSigningIn, setIsSigningIn] = React.useState(false);
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [usernameError, setUsernameError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [signInError, setSignInError] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    responseType: ResponseType.IdToken,
    androidClientId:
      "610950019827-4psdtjlk262ngemv3m350fsvn0kibnlc.apps.googleusercontent.com",
    scopes: ["profile", "email"],
    expoClientId:
      "610950019827-2rr40jpkinutcsv1rc4301kem17kgud4.apps.googleusercontent.com",
  });

  React.useEffect(() => {
    /**
     * throws Error if doesGoogleAccountExist fails to make successful request
     */
    const isGoogleAccountRegistered = async (googleIdToken) => {
      const response = await props.doesGoogleAccountExist(googleIdToken);

      if (!response) {
        return null;
      }

      return {
        withGoogle: response.doesAccountWithGoogleExist,
        withCoopons: response.doesAccountWithCooponsExist,
        emailAddress: response.emailAddress,
      };
    };

    const signGoogleUserIn = async (idToken) => {
      try {
        const googleIdToken = idToken;

        const userLogin = await props.googleSignIn(googleIdToken);
        await AsyncStorage.setItem("@token", userLogin.token);

        const userAccount = await props.getUserAccount();
        await AsyncStorage.setItem(
          "@user_account",
          JSON.stringify(userAccount)
        );

        const userProfile = await props.getUserProfile();
        await AsyncStorage.setItem(
          "@user_profile",
          JSON.stringify(userProfile)
        );

        props.updateExpoToken();

        log(
          `User ${userAccount.username} succesfully logged with Google and with user id ${userLogin.userId}`
        );
        props.authorizeUserAccessAction();
      } catch (e) {
        console.debug("Failed to sign in because ", e.message);
        if (
          e.message === LoginConsts.INCORRECT_CREDENTIALS ||
          e.message === NetworkConsts.NETWORK_REQUEST_TIMED_OUT ||
          e.message === NetworkConsts.NETWORK_REQUEST_FAILED
        ) {
          setSignInError(e.message);
        } else {
          setSignInError(LoginConsts.FAILED_TO_SIGN_IN);
        }
        setIsSigningIn(false);
        Sentry.Native.captureException(e);
      }
    };

    const { params } = props.route;

    if (params && params.passwordLinkSent) {
      props.navigation.setParams({ passwordLinkSent: false });
      setMessage(ResetPassConsts.RESET_MESSAGE);
    }
    if (params && params.accountCreated) {
      setMessage(SignupConsts.SUCCESSFULLY_CREATED_ACCOUNT);
      setUsername(params.usernameCreated ? params.usernameCreated : null);
      props.navigation.setParams({
        accountCreated: false,
        usernameCreated: null,
      });
    }

    if (response && response.type === "success") {
      setSignInError(null);
      const { params } = response;
      isGoogleAccountRegistered(params.id_token)
        .then((isAccountRegistered) => {
          if (isAccountRegistered.withGoogle) {
            signGoogleUserIn(params.id_token);
          } else if (isAccountRegistered.withCoopons) {
            throw new Error(LoginConsts.GOOGLE_SIGN_IN_ERROR_2);
          } else if (
            !isAccountRegistered.withGoogle &&
            !isAccountRegistered.withCoopons
          ) {
            props.navigation.navigate(NavigationConsts.SIGNUP_SCREEN, {
              idToken: params.id_token,
              emailAddress: isAccountRegistered.emailAddress,
            });
          }
        })
        .catch((error) => {
          console.debug("Failed to sign in because ", error.message);
          Sentry.Native.captureException(error);
          if (error.message === LoginConsts.GOOGLE_SIGN_IN_ERROR_2) {
            setSignInError(error.message);
          } else {
            setSignInError(LoginConsts.GOOGLE_SIGN_IN_ERROR_1);
          }
        });
    } else if (response && response.type === "error") {
      Sentry.Native.captureMessage(response.error);
    }
  }, [response]);

  const handleSignIn = async () => {
    setIsSigningIn(true);
    try {
      validateCredentials();
      const userLogin = await props.signIn(username, password);
      await AsyncStorage.setItem("@token", userLogin.token);

      const userAccount = await props.getUserAccount();
      await AsyncStorage.setItem("@user_account", JSON.stringify(userAccount));

      const userProfile = await props.getUserProfile();
      await AsyncStorage.setItem("@user_profile", JSON.stringify(userProfile));

      props.updateExpoToken();

      log(
        `User ${username} succesfully logged with user id ${userLogin.userId}`
      );
      props.authorizeUserAccessAction();
    } catch (e) {
      console.debug("Failed to sign in because ", e.message);
      if (
        e.message === LoginConsts.INCORRECT_CREDENTIALS ||
        e.message === NetworkConsts.NETWORK_REQUEST_TIMED_OUT ||
        e.message === NetworkConsts.NETWORK_REQUEST_FAILED
      ) {
        setSignInError(e.message);
      } else {
        setSignInError(LoginConsts.FAILED_TO_SIGN_IN);
      }
      setIsSigningIn(false);
      Sentry.Native.captureException(e);
    }
  };

  const handleGoogleSignIn = async () => {
    if (request) {
      promptAsync();
    }
  };

  const validateCredentials = () => {
    if (!username) {
      setUsernameError(LoginConsts.USERNAME_ERROR_1);
      throw new Error(LoginConsts.USERNAME_ERROR_1);
    }
    if (!password) {
      setPasswordError(LoginConsts.PASSWORD_ERROR_1);
      throw new Error(LoginConsts.PASSWORD_ERROR_1);
    }
  };

  const handleUsernameEntered = () => {
    // this.inputRef.current.focus();
  };

  const handlePasswordEntered = () => {
    if (username && password) {
      handleSignIn();
    }
  };

  const getErrorAwareUserInputContainer = (error) => ({
    ...loginStyle.userInputContainer,
    ...(error ? { marginBottom: 0 } : {}),
  });

  return (
    <ScrollView contentContainerStyle={loginStyle.container}>
      <KeyboardAvoidingView contentContainerStyle={loginStyle.contentContainer}>
        <TouchableOpacity activeOpacity={1} onPress={showAppInfoToast}>
          <View style={loginStyle.logoContainer}>
            <Logo width={120} height={120} />
          </View>
        </TouchableOpacity>
        <View style={loginStyle.inputContainer}>
          <Text style={loginStyle.title}>{LoginConsts.TITLE_SIGNIN}</Text>
          <View style={loginStyle.inputBoxes}>
            <Input
              value={username}
              autoCapitalize={"none"}
              blurOnSubmit={false}
              onSubmitEditing={handleUsernameEntered}
              inputStyle={loginStyle.userInputText}
              inputContainerStyle={getErrorAwareUserInputContainer(
                usernameError
              )}
              placeholder={LoginConsts.USERNAME}
              errorMessage={usernameError}
              onChangeText={(text) =>
                setUsername(text.trim()) && setUsernameError(null)
              }
            />
            <Input
              onSubmitEditing={handlePasswordEntered}
              inputStyle={loginStyle.userInputText}
              inputContainerStyle={getErrorAwareUserInputContainer(
                passwordError
              )}
              placeholder={LoginConsts.PASSWORD}
              secureTextEntry={true}
              errorMessage={passwordError}
              onChangeText={(text) =>
                setPassword(text) && setPasswordError(null)
              }
            />
          </View>
          <Text style={loginStyle.credentialError}>{signInError}</Text>
          <Button
            type={1}
            style={loginStyle.signInButton}
            onPress={handleSignIn}
            isLoading={IsSigningIn}
            onLoadingJSX={
              <ActivityIndicator
                style={loginStyle.signinLoading}
                color={COMPONENT_COLOR_3}
              />
            }
          >
            {LoginConsts.LOG_IN}
          </Button>
          <View style={loginStyle.orSignInWithContainer}>
            <Text style={loginStyle.orSignInText}>
              {LoginConsts.OR_SIGN_IN_WITH}
            </Text>
            <TouchableOpacity
              style={loginStyle.googleSignInContainer}
              activeOpacity={0.5}
              onPress={handleGoogleSignIn}
            >
              {!request ? (
                <ActivityIndicator size={"small"} color={COMPONENT_COLOR_1} />
              ) : (
                <GoogleIcon height={25} width={25} />
              )}
            </TouchableOpacity>
          </View>
          <View style={loginStyle.signUpSection}>
            <Text>{LoginConsts.SIGN_UP_TEXT + " "}</Text>
            <Text
              style={loginStyle.signupLink}
              onPress={() =>
                props.navigation.navigate(NavigationConsts.SIGNUP_SCREEN)
              }
            >
              {LoginConsts.SIGN_UP}
            </Text>
          </View>
          {message ? (
            <View style={loginStyle.resetMessageContainer}>
              <Text style={loginStyle.resetMessage}>{message}</Text>
            </View>
          ) : null}
        </View>
      </KeyboardAvoidingView>
    </ScrollView>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserAccount: getUserAccount,
      getUserProfile: getUserProfile,
      signIn: signIn,
      googleSignIn: googleSignIn,
      authorizeUserAccessAction: authorizeUserAccessAction,
      updateExpoToken: updateExpoToken,
      doesGoogleAccountExist: doesGoogleAccountExist,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
