import React, { Component, useState } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
  Platform,
  Share,
} from "react-native";
import { connect } from "react-redux";
import { Input, ListItem } from "@rneui/themed";
import { ScrollView } from "react-native-gesture-handler";
import { bindActionCreators } from "redux";
import * as Sentry from "sentry-expo";

import { Button } from "../../common/Button";
import { createCouponStyle } from "../../styles/CreateCouponStyle";
import { CreateCouponConsts, NavigationConsts } from "../../common/constants";
import { InputDate } from "../InputDate";
import { createSentCoupon } from "../../modules/coupons/services/CouponService";
import { composeProfileSuggestions } from "../ProfileSuggestions";
import { getUserProfile } from "../../modules/coupons/services/ProfileService";
import { LoadingIndicator, LOADING_SIZE } from "../LoadingIndicator";
import Check from "../../assets/images/checkmark.svg";
import SearchIcon from "../../assets/images/search.svg";
import ShareIcon from "../../assets/images/share.svg";
import { COMPONENT_COLOR_1 } from "../../common/colors";
import {
  getBuddyProfiles,
  addBuddy,
} from "../../modules/coupons/services/BuddiesService";
import { avatars } from "../../common/assets";
import { addBuddyAction } from "../../modules/coupons/actions/ProfileActions";

const initialState = {
  name: "",
  description: "",
  startDate: null,
  endDate: null,
  sender: "",
  receiver: "",
  receiverProfile: null,
  redeemers: [],
  errorMsgs: {
    couponNameErr: "",
    couponDescErr: "",
    startDateErr: "",
    endDateErr: "",
    senderErr: "",
    receiverErr: "",
    listOfRedeemersErr: "",
  },
  submitting: false,
  createCooponLink: false,
};

class CreateCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount = async () => {
    const { userAccount } = this.props.auth;

    this.setState({ sender: userAccount.username });
    try {
      await this.props.getBuddyProfiles();
    } catch (e) {}
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.name !== prevState.name) {
      this.validateCouponName(this.state.name);
    }

    if (this.state.description !== prevState.description) {
      this.validateCouponDescription(this.state.description);
    }

    if (
      this.state.receiver !== prevState.receiver &&
      !this.state.createCooponLink
    ) {
      this.validateCouponReceiver(this.state.receiver);
    }
  };

  resetState = () => {
    this.setState(initialState);
  };

  handleTextInput = (key, value) => {
    if (key !== "redeemers") {
      this.setState({
        [key]: value.trim(),
      });
      if (key === CreateCouponConsts.RECEIVER_KEY) {
        this.setState({
          receiverProfile: null,
          createCooponLink: false,
        });
      }
    } else {
      this.setState({
        [key]: value.split(/(?:,|\s+|\.)/),
      });
    }
  };

  handlePressSuggestion = (username) => {
    this.setState({ [CreateCouponConsts.RECEIVER_KEY]: username });
  };

  handleCreateCoupon = async () => {
    this.setState({ submitting: true });
    Keyboard.dismiss();
    if (!(await this.validateInputs())) {
      this.setState({ submitting: false });
      return;
    }

    var {
      name,
      description,
      endDate,
      sender,
      receiver,
      receiverProfile,
      redeemers,
    } = this.state;

    try {
      if (receiver && !receiverProfile) {
        receiverProfile = await this.props.getUserProfile(receiver);
      }
    } catch (e) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_6);
      this.setState({ submitting: false });
      Sentry.Native.captureException(e);
      return;
    }

    await this.props.createSentCoupon({
      name,
      description,
      endDate,
      sender,
      receiver: this.state.createCooponLink ? null : receiverProfile.username,
      redeemers,
    });
    if (!this.state.createCooponLink) {
      try {
        await this.props.addBuddy(receiverProfile.username);
        this.props.addBuddyAction(receiverProfile);
      } catch (e) {
        console.debug(`'Failed to add buddy '${receiverProfile.username}'`);
        Sentry.Native.captureException(e);
      }
    }
    this.props.navigation.navigate(NavigationConsts.SENT_SCREEN);
  };

  validateInputs = async () => {
    if (
      !(
        this.validateCouponName(this.state.name) &&
        this.validateCouponDescription(this.state.description)
      )
    ) {
      return false;
    }

    if (!this.state.createCooponLink) {
      if (!this.validateCouponReceiver(this.state.receiver)) {
        return false;
      }

      let receiverProfile = null;
      try {
        receiverProfile = await this.props.getUserProfile(this.state.receiver);
      } catch (e) {
        this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_6);
        return false;
      }

      if (!receiverProfile) {
        this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_6);
        return false;
      }

      if (this.state.sender === receiverProfile.username) {
        this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_7);
        return false;
      }
    }

    return true;
  };

  validateCouponName = (name) => {
    var emptyRe = /^.+$/;
    const maxLength = 40;

    if (name.search(emptyRe)) {
      this.updateErrorMsg("couponNameErr", CreateCouponConsts.NAME_ERROR_1);
      return false;
    } else if ([...name].length > maxLength) {
      this.updateErrorMsg("couponNameErr", CreateCouponConsts.NAME_ERROR_2);
      return false;
    }
    this.updateErrorMsg("couponNameErr", "");
    return true;
  };

  validateCouponDescription = (desc) => {
    var lenRe = /^.{0,200}$/;

    if (desc.search(lenRe)) {
      this.updateErrorMsg("couponDescErr", CreateCouponConsts.DESC_ERROR);
      return false;
    }
    this.updateErrorMsg("couponDescErr", "");
    return true;
  };

  validateCouponReceiver = (name) => {
    var emptyRe = /^.+$/;
    var lenRe = /^.{0,40}$/;
    var specialCharsRe = /^[A-z0-9 .@]+$/;
    var noSpacesRe = /^[^ ]+$/;
    // 0 (matches) is false and -1 (no match) is true
    if (name.search(emptyRe)) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_2);
      return false;
    } else if (name.search(lenRe)) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_3);
      return false;
    } else if (name.search(specialCharsRe)) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_4);
      return false;
    } else if (name.search(noSpacesRe)) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_5);
      return false;
    }
    this.updateErrorMsg("receiverErr", "");
    return true;
  };

  updateErrorMsg = (key, value) => {
    this.setState({ errorMsgs: { [key]: value } });
  };

  findUser = async (receiver) => {
    let userprofile;
    try {
      userprofile = await this.props.getUserProfile(receiver);
    } catch (e) {
      userprofile = null;
    }
    if (!userprofile) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_6);
      return;
    }
    if (this.state.sender === userprofile.username) {
      this.updateErrorMsg("receiverErr", CreateCouponConsts.RECEIVER_ERROR_7);
      return;
    }

    this.setState({ receiverProfile: userprofile });
  };

  chooseBuddy = (profile) => {
    this.setState({
      receiverProfile: profile,
      receiver: profile.username,
      createCooponLink: false,
    });
  };

  handleCooponLinkOption = () => {
    this.setState({
      createCooponLink: !this.state.createCooponLink,
      receiver: "",
      receiverProfile: null,
    });
  };

  render() {
    const { couponNameErr, couponDescErr, receiverErr } = this.state.errorMsgs;

    const {
      receiver,
      receiverProfile,
      endDate,
      submitting,
      name,
      createCooponLink,
    } = this.state;

    const { buddies } = this.props.profile;

    const isCreateCooponButtonEnabled =
      name && (createCooponLink || receiverProfile);

    return (
      <View style={createCouponStyle.container}>
        <ScrollView style={createCouponStyle.inputContainer}>
          <KeyboardAvoidingView
            style={createCouponStyle.keyboardviewContainer}
            behavior={Platform.OS === "ios" ? "padding" : null}
          >
            <View style={createCouponStyle.couponDetailsContainer}>
              <Text style={createCouponStyle.couponDetailsTitle}>
                {CreateCouponConsts.COUPON_DETAILS}
              </Text>
              <View style={createCouponStyle.couponDetailsInputContainer}>
                <Input
                  containerStyle={createCouponStyle.couponDetailsInput}
                  label={CreateCouponConsts.NAME_LABEL}
                  maxLength={60}
                  placeholder={CreateCouponConsts.NAME_PH}
                  onChangeText={(text) =>
                    this.handleTextInput(CreateCouponConsts.NAME_KEY, text)
                  }
                  errorMessage={couponNameErr}
                />
                <Input
                  containerStyle={createCouponStyle.couponDetailsInput}
                  label={CreateCouponConsts.DESC_LABEL}
                  numberOfLines={1}
                  maxLength={220}
                  inputContainerStyle={
                    createCouponStyle.textLargeInputContainer
                  }
                  placeholder={CreateCouponConsts.DESC_PH}
                  onChangeText={(text) =>
                    this.handleTextInput(CreateCouponConsts.DESC_KEY, text)
                  }
                  errorMessage={couponDescErr}
                />
                <InputDate
                  defaultLabel={CreateCouponConsts.END_DATE_LABEL}
                  handleDateUpdate={(date) =>
                    this.handleTextInput(CreateCouponConsts.END_DATE_KEY, date)
                  }
                  endDate={endDate}
                />
              </View>
            </View>
            <View style={createCouponStyle.couponDetailsContainer}>
              <Text style={createCouponStyle.couponDetailsTitle}>
                {CreateCouponConsts.SELECT_FRIEND}
              </Text>
              <View style={createCouponStyle.couponDetailsInputContainer}>
                <View style={createCouponStyle.shareLinkOptionContainer}>
                  <TouchableOpacity
                    activeOpacity={0.8}
                    style={createCouponStyle.shareLinkOption}
                    onPress={this.handleCooponLinkOption}
                  >
                    <View></View>
                    <Text style={createCouponStyle.shareLinkOptionText}>
                      {CreateCouponConsts.CREATE_COOPON_LINK}
                    </Text>
                    {createCooponLink ? (
                      <Check width={20} height={20} />
                    ) : (
                      <View></View>
                    )}
                  </TouchableOpacity>
                </View>
                <View style={createCouponStyle.orContainer}>
                  <Text style={createCouponStyle.orText}>Or</Text>
                </View>
                <FindProfile
                  receiver={receiver}
                  receiverProfile={receiverProfile}
                  receiverErr={receiverErr}
                  validateCouponReceiver={this.validateCouponReceiver}
                  onChangeText={this.handleTextInput}
                  onFindUser={this.findUser}
                />
                <ListOfBuddies
                  buddies={buddies}
                  onChooseBuddy={this.chooseBuddy}
                  receiverProfile={receiverProfile}
                />
              </View>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
        <Button
          type={1}
          customStyle={createCouponStyle.submitButtonContainer}
          customButtonStyle={createCouponStyle.submitButton}
          onPress={this.handleCreateCoupon}
          isLoading={submitting}
          isDisabled={!isCreateCooponButtonEnabled}
        >
          {CreateCouponConsts.SUBMIT_MODAL}
        </Button>
      </View>
    );
  }
}

const ListOfBuddies = (props) => {
  const { buddies, onChooseBuddy, receiverProfile } = props;

  const avatarIcon = (avatarID) => {
    const AvatarIcon = avatars[avatarID];
    return <AvatarIcon height={25} width={25} />;
  };

  return buddies.map((buddy) => (
    <TouchableOpacity
      key={buddy.userId}
      activeOpacity={0.9}
      onPress={() => onChooseBuddy(buddy)}
      style={createCouponStyle.buddyContainer}
    >
      <View style={createCouponStyle.buddyInfo}>
        {avatarIcon(buddy.avatarID)}
        <Text style={createCouponStyle.buddyFullname}>
          {buddy.fullname ? buddy.fullname : buddy.username}
        </Text>
      </View>
      {buddy.userId === (receiverProfile?.userId ?? null) ? (
        <Check width={25} height={25} />
      ) : null}
    </TouchableOpacity>
  ));
};

const FindProfile = (props) => {
  const [isSearchingUser, setSearchingUser] = useState(false);

  const {
    receiver,
    receiverErr,
    receiverProfile,
    onFindUser,
    onChangeText,
    validateCouponReceiver,
  } = props;

  const beginSearch = async () => {
    setSearchingUser(true);

    if (!validateCouponReceiver(receiver)) {
      setSearchingUser(false);
      return;
    }

    await onFindUser(receiver);
    setSearchingUser(false);
  };

  return (
    <Input
      rightIcon={
        <>
          {receiverProfile ? (
            <Check width={25} height={25} />
          ) : isSearchingUser ? (
            <ActivityIndicator size={"small"} color={COMPONENT_COLOR_1} />
          ) : (
            <TouchableOpacity onPress={beginSearch}>
              <SearchIcon width={25} height={25} />
            </TouchableOpacity>
          )}
        </>
      }
      autoCapitalize="none"
      value={receiverProfile ? receiverProfile.username : receiver}
      onChangeText={(text) =>
        onChangeText(CreateCouponConsts.RECEIVER_KEY, text)
      }
      containerStyle={createCouponStyle.couponDetailsInput}
      label={CreateCouponConsts.USERNAME_LABEL}
      placeholder={CreateCouponConsts.USERNAME_PH}
      errorMessage={receiverErr}
    />
  );
};

const ReceiverInput = (props) => {
  const [suggestionSelected, setSuggestionSelected] = useState(false);

  const { profileSuggestions, isSearchingForSuggestions, handleFindProfile } =
    props;

  const handleTextChange = (text) => {
    props.onChangeText(CreateCouponConsts.RECEIVER_KEY, text);
    handleFindProfile(text);
    setSuggestionSelected(false);
  };

  const handleProfileClicked = (profile) => {
    props.onPressSuggestion(profile.username);
    setSuggestionSelected(true);
  };

  return (
    <View>
      <Input
        label={CreateCouponConsts.RECEIVER_LABEL}
        placeholder={CreateCouponConsts.RECEIVER_PH}
        onChangeText={(text) => handleTextChange(text)}
        errorMessage={props.errorMessage}
        value={props.value}
      />
      <LoadingIndicator
        isLoading={isSearchingForSuggestions}
        size={LOADING_SIZE.SMALL}
      >
        {profileSuggestions.length !== 0 && !suggestionSelected ? (
          <View style={createCouponStyle.receiverScrollView}>
            <ScrollView>
              {profileSuggestions.map((profile) => (
                <ListItem
                  key={profile.userId}
                  title={profile.username}
                  onPress={() => handleProfileClicked(profile)}
                  bottomDivider
                />
              ))}
            </ScrollView>
          </View>
        ) : null}
      </LoadingIndicator>
    </View>
  );
};

const ReceiverInputSuggestions = composeProfileSuggestions(ReceiverInput);

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSentCoupon: createSentCoupon,
      getUserProfile: getUserProfile,
      getBuddyProfiles: getBuddyProfiles,
      addBuddy: addBuddy,
      addBuddyAction: addBuddyAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoupon);
