import {
  SHOW_COUPONS_BAR,
  ACTIVATE_OVERLAY_MESSAGE,
  DEACTIVATE_OVERLAY_MESSAGE,
  REFETCH_RECEIVED_COUPONS,
  REFETCH_SENT_COUPONS,
} from "./AppConfigActionTypes";

export const showCouponsBar = (show) => ({
  type: SHOW_COUPONS_BAR,
  payload: show,
});

export const activateOverlayMessage = (message, delay = 0) => ({
  type: ACTIVATE_OVERLAY_MESSAGE,
  message: message,
  delay: delay,
});

export const deactivateOverlayMessage = () => ({
  type: DEACTIVATE_OVERLAY_MESSAGE,
});

export const refetchSentCoupons = (refetchCoupons = false) => ({
  type: REFETCH_SENT_COUPONS,
  shouldRefetchCoupons: refetchCoupons,
});

export const refetchReceivedCoupons = (refetchCoupons = false) => ({
  type: REFETCH_RECEIVED_COUPONS,
  shouldRefetchCoupons: refetchCoupons,
});
