import Pusher from "pusher-js/react-native";
import * as Sentry from "sentry-expo";
import { CouponsConsts } from "../../../common/constants";
import { getApiURL, getPusherId } from "../../../common/environment";
import { log } from "../../../common/logging";
import {
  activateOverlayMessage,
  refetchSentCoupons,
  refetchReceivedCoupons,
} from "../actions/AppConfigActions";

export const PusherTypes = {
  PUSHER_STATE_CHANGE: "state_change",
  PUSHER_SUBSCRIPTION_SUCCEEDED: "pusher:subscription_succeeded",
  USER_DM: "user_dm",
  SENT_COUPON_ADDED: "sent_coupon_added",
  RECEIVED_COUPON_ADDED: "received_coupon_added",
  SENT_COUPON_DELETED: "sent_coupon_deleted",
  RECEIVED_COUPON_DELETED: "received_coupon_deleted",
  COUPON_ACCEPTED: "coupon_accepted",
  COUPON_DECLINED: "coupon_declined",
  COUPON_REDEEMED: "coupon_redeemed",
  COUPON_RESENT: "coupon_resent",
  COUPON_COMPLETED: "coupon_completed",
  COUPON_EXPIRED: "coupon_expired",
  COUPON_RATED: "coupon_rated",
};

const PusherConnectionState = {
  CONNECTED: "connected",
  CONNECTING: "connecting",
  UNAVAILABLE: "unavailable",
};

const backendUrl = getApiURL();

Pusher.logToConsole = true;

let pusher = null;
let channel = null;

const initPusher = (userAccount) => {
  try {
    if (!userAccount.userId) {
      throw new Error("User account is null");
    }
    console.debug("Initializing pusher");
    const pusherId = getPusherId();
    pusher = new Pusher(pusherId, {
      cluster: "us2",
      authEndpoint: backendUrl + "/api/pusher/auth",
    });
    channel = pusher.subscribe(`private-user-${userAccount.userId}`);
  } catch (e) {
    console.debug("Pusher error: ", e.message);
    Sentry.Native.captureException(e);
  }
};

export const resetChannel = (userAccount = null) => {
  if (pusher !== null && userAccount !== null) {
    pusher.unsubscribe(`private-user-${userAccount.userId}`);
  }
  if (pusher !== null) {
    pusher.disconnect();
    pusher = null;
  }
  if (channel !== null) {
    channel.unbind();
    channel = null;
  }
};

export const initializeBinding = (
  userAccount,
  couponActions,
  profileActions,
  store
) => {
  console.debug(
    "Initializing binding to channel events using ",
    userAccount.username
  );

  resetChannel(userAccount);

  initPusher(userAccount);

  channel.bind(PusherTypes.USER_DM, ({ message }) => {
    log(`Sending message: ${message}`);
    store.dispatch(activateOverlayMessage(message));
  });

  // Received Coupons Pusher Binds
  channel.bind(PusherTypes.RECEIVED_COUPON_ADDED, ({ coupon }) => {
    store.dispatch(
      couponActions.addReceivedCouponAction({
        ...JSON.parse(coupon),
        isNew: true,
      })
    );
  });

  channel.bind(PusherTypes.RECEIVED_COUPON_DELETED, ({ couponId }) => {
    store.dispatch(couponActions.deleteReceivedCouponAction(couponId));
  });

  channel.bind(PusherTypes.COUPON_RESENT, ({ coupon }) => {
    store.dispatch(couponActions.addReceivedCouponAction(JSON.parse(coupon)));
  });

  channel.bind(PusherTypes.COUPON_COMPLETED, ({ couponId }) => {
    store.dispatch(
      couponActions.updateReceivedCouponAction(couponId, {
        status: CouponsConsts.STATUS_COMPLETED,
        isNew: true,
      })
    );
  });

  // Sent Coupons Pusher Binds
  channel.bind(PusherTypes.COUPON_ACCEPTED, ({ couponId }) => {
    store.dispatch(refetchSentCoupons(true));
    store.dispatch(
      couponActions.updateSentCouponAction(couponId, {
        status: CouponsConsts.STATUS_ACCEPTED,
        isNew: true,
      })
    );
  });

  channel.bind(PusherTypes.COUPON_DECLINED, ({ couponId }) => {
    store.dispatch(
      couponActions.updateSentCouponAction(couponId, {
        status: CouponsConsts.STATUS_REJECTED,
        isNew: true,
      })
    );
  });

  channel.bind(PusherTypes.COUPON_REDEEMED, ({ couponId }) => {
    store.dispatch(
      couponActions.updateSentCouponAction(couponId, {
        status: CouponsConsts.STATUS_WAITING_FOR_COMPLETION,
        isNew: true,
      })
    );
  });

  channel.bind(
    PusherTypes.COUPON_RATED,
    ({ couponId, ratings, number_of_ratings }) => {
      store.dispatch(
        couponActions.updateSentCouponAction(couponId, {
          status: CouponsConsts.STATUS_RATED,
        })
      );
      store.dispatch(
        profileActions.updateUserProfileAction({
          ratings: ratings,
          number_of_ratings: number_of_ratings,
        })
      );
    }
  );

  channel.bind(PusherTypes.SENT_COUPON_ADDED, ({ coupon }) => {
    store.dispatch(couponActions.addSentCouponAction(JSON.parse(coupon)));
  });

  channel.bind(PusherTypes.SENT_COUPON_DELETED, ({ couponId }) => {
    store.dispatch(couponActions.deleteSentCouponAction(couponId));
  });

  channel.bind(PusherTypes.COUPON_EXPIRED, ({ couponId }) => {
    store.dispatch(
      couponActions.updateSentCouponAction(couponId, {
        status: CouponsConsts.STATUS_EXPIRED,
        isNew: true,
      })
    );
    store.dispatch(
      couponActions.updateReceivedCouponAction(couponId, {
        status: CouponsConsts.STATUS_EXPIRED,
        isNew: true,
      })
    );
  });

  pusher.connection.bind(
    PusherTypes.PUSHER_STATE_CHANGE,
    ({ previous, current }) => {
      if (
        (previous === PusherConnectionState.UNAVAILABLE &&
          current === PusherConnectionState.CONNECTED) ||
        (previous === PusherConnectionState.CONNECTING &&
          current === PusherConnectionState.CONNECTED)
      ) {
        store.dispatch(refetchSentCoupons(true));
        store.dispatch(refetchReceivedCoupons(true));
      }
    }
  );
};
