import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Platform,
  ProgressBarAndroid,
  ProgressViewIOS,
  TouchableOpacity,
  Alert,
} from "react-native";
import PropTypes from "prop-types";
import moment from "moment";

import { couponStyle } from "../styles/CouponStyle";
import { CouponsConsts, ProfileConsts } from "../common/constants";
import {
  COMPONENT_COLOR_4,
  COMPONENT_COLOR_2,
  TEXT_COLOR_3,
  TEXT_COLOR_7,
  COMPONENT_COLOR_3,
} from "../common/colors";
import { avatars } from "../common/assets";

export const handleCompleteConfirmationDialog = (
  receiverDisplayName,
  receiver,
  title,
  completedCallback
) => {
  const validDisplayName = receiverDisplayName ? receiverDisplayName : receiver;

  Alert.alert(
    CouponsConsts.CONFIRMATION_TITLE(validDisplayName),
    title,
    [
      {
        text: CouponsConsts.CONFIRMATION_NOT_DONE,
        style: "cancel",
      },
      { text: CouponsConsts.CONFIRMATION_DONE, onPress: completedCallback },
    ],
    { cancelable: false }
  );
};

export function CouponHeader(props) {
  const contrast = props?.contrast ?? false;

  const expirationDate = props.endDate
    ? moment(props.endDate).format("L")
    : CouponsConsts.NO_EXPIRATION;
  const assignedDate = moment(props.startDate).format("L");

  return (
    <View style={couponStyle.header}>
      <View style={couponStyle.headerOne}>
        <Text
          style={[couponStyle.title, contrast ? { color: TEXT_COLOR_3 } : null]}
        >
          {props.name}
        </Text>
        <Text
          style={[couponStyle.date, contrast ? { color: TEXT_COLOR_7 } : null]}
        >
          {assignedDate}
        </Text>
      </View>
      <View style={couponStyle.headerTwo}>
        <Text
          style={[
            couponStyle.expiration,
            contrast ? { color: TEXT_COLOR_3 } : null,
          ]}
        >
          {expirationDate}
        </Text>
      </View>
    </View>
  );
}

CouponHeader.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  contrast: PropTypes.bool,
};

export function CouponDetailsTemplate(props) {
  const expirationDate = props.endDate
    ? moment(props.endDate).format("LLL")
    : CouponsConsts.NO_EXPIRATION;
  const assignedDate = moment(props.startDate).format("LLL");

  const senderName = props.senderDisplayName
    ? props.senderDisplayName
    : props.sender;
  const receiverName = props.receiverDisplayName
    ? props.receiverDisplayName
    : props?.receiver ?? ProfileConsts.UNKNOWN_USERNAME;

  return (
    <View style={couponStyle.detailsTemplateContainer}>
      <View style={couponStyle.detailsTemplateSection}>
        <Text style={couponStyle.detailsTemplateType}>
          {CouponsConsts.DETAILS_COUPON_TEMPLATE_TYPE_LABEL(props.couponType)}
        </Text>
      </View>
      <View style={couponStyle.detailsTemplateSection}>
        <Text style={couponStyle.detailsTemplateTitle}>{props.name}</Text>
      </View>
      {props.description ? (
        <View style={couponStyle.detailsTemplateSection}>
          <Text style={couponStyle.detailsTemplateSubSection1}>
            {CouponsConsts.DETAILS_COUPON_TEMPLATE_DESCRIPTION}
          </Text>
          <Text style={couponStyle.detailsTemplateSubSection2}>
            {props.description}
          </Text>
        </View>
      ) : null}
      <View style={couponStyle.detailsTemplateSection}>
        <Text style={couponStyle.detailsTemplateSubSection1}>
          {CouponsConsts.DETAILS_COUPON_TEMPLATE_START_DATE}
        </Text>
        <Text style={couponStyle.detailsTemplateSubSection2}>
          {assignedDate}
        </Text>
      </View>
      <View style={couponStyle.detailsTemplateSection}>
        <Text style={couponStyle.detailsTemplateSubSection1}>
          {CouponsConsts.DETAILS_COUPON_TEMPLATE_END_DATE}
        </Text>
        <Text style={couponStyle.detailsTemplateSubSection2}>
          {expirationDate}
        </Text>
      </View>
    </View>
  );
}

CouponDetailsTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  sender: PropTypes.string.isRequired,
  receiver: PropTypes.string,
  senderDisplayName: PropTypes.string,
  receiverDisplayName: PropTypes.string,
  couponType: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
};

export function CouponBody(props) {
  const contrast = props?.contrast ?? false;
  const avatarID =
    props.listType === CouponsConsts.TYPE_SENT
      ? props.receiverAvatarId
      : props.senderAvatarId;
  return (
    <View style={couponStyle.body}>
      <Assigner
        avatarID={avatarID}
        listType={props.listType}
        sender={props.sender}
        receiver={props.receiver}
        senderDisplayName={props.senderDisplayName}
        receiverDisplayName={props.receiverDisplayName}
        handleUserPress={props.handleUserPress}
        handleCouponPress={props.handleCouponPress}
        contrast={contrast}
        isBigBoxPressable={props.isBigBoxPressable}
      />
      <Progress
        status={props.status}
        startDate={props.startDate}
        endDate={props.endDate}
        onExpiration={props.handleExpiration}
      />
      {props.isBigBoxPressable ? null : <Status status={props.status} />}
      {props.children}
    </View>
  );
}

CouponBody.propTypes = {
  avatarID: PropTypes.number,
  listType: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
  receiver: PropTypes.string,
  senderDisplayName: PropTypes.string,
  receiverDisplayName: PropTypes.string,
  senderAvatarId: PropTypes.number.isRequired,
  receiverAvatarId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  handleUserPress: PropTypes.func.isRequired,
  handleExpiration: PropTypes.func.isRequired,
  handleCouponPress: PropTypes.func,
  contrast: PropTypes.bool,
  isBigBoxPressable: PropTypes.bool,
  children: PropTypes.element,
};

function Assigner(props) {
  if (!props.receiver && props.listType === CouponsConsts.TYPE_SENT) {
    return null;
  }

  const assignerMsg =
    props.listType === CouponsConsts.TYPE_SENT
      ? CouponsConsts.COUPON_SENTTO_MSG
      : CouponsConsts.COUPON_RECEIVEDBY_MSG;
  const senderValidName = props.senderDisplayName
    ? props.senderDisplayName
    : props.sender;
  const receiverValidName = props.receiverDisplayName
    ? props.receiverDisplayName
    : props.receiver;
  const displayName =
    props.listType === CouponsConsts.TYPE_SENT
      ? receiverValidName
      : senderValidName;
  const assignee =
    props.listType === CouponsConsts.TYPE_SENT ? props.receiver : props.sender;
  const Avatar =
    props.avatarID && props.avatarID !== -1 ? avatars[props.avatarID] : null;

  const onBigBoxPress = props.isBigBoxPressable
    ? () => props.handleUserPress(assignee, displayName)
    : props.handleCouponPress;

  return (
    <TouchableOpacity
      onPress={onBigBoxPress}
      activeOpacity={1}
      style={[
        couponStyle.assignerContainer,
        props.contrast ? { backgroundColor: COMPONENT_COLOR_3 } : null,
      ]}
    >
      <TouchableOpacity
        style={couponStyle.assigner}
        activeOpacity={1}
        onPress={() => props.handleUserPress(assignee, displayName)}
      >
        {props.avatarID && props.avatarID !== -1 && Platform.OS !== "web" ? (
          <Avatar style={couponStyle.assignerAvatar} width={30} height={30} />
        ) : null}
        <View style={couponStyle.assignerText}>
          <Text style={couponStyle.assignerHeader}>{assignerMsg}</Text>
          <View style={couponStyle.assignerBody}>
            <Text style={couponStyle.assignerName}>{displayName}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </TouchableOpacity>
  );
}

Assigner.propTypes = {
  avatarID: PropTypes.number,
  listType: PropTypes.string.isRequired,
  sender: PropTypes.string,
  receiver: PropTypes.string,
  senderDisplayName: PropTypes.string,
  receiverDisplayName: PropTypes.string,
  handleUserPress: PropTypes.func.isRequired,
  handleCouponPress: PropTypes.func,
  contrast: PropTypes.bool,
  isBigBoxPressable: PropTypes.bool,
};

export function Progress(props) {
  let [nowDate, setNowDate] = useState(moment());

  const dontCountdownProgressBar =
    props.status === CouponsConsts.STATUS_EXPIRED ||
    props.status === CouponsConsts.STATUS_COMPLETED ||
    props.status === CouponsConsts.STATUS_RATED ||
    props.status === CouponsConsts.STATUS_WAITING_FOR_COMPLETION ||
    props.status === CouponsConsts.STATUS_REDEEMED ||
    props.status === CouponsConsts.STATUS_REJECTED;

  const dontShowProgressBar =
    props.status === CouponsConsts.STATUS_COMPLETED ||
    props.status === CouponsConsts.STATUS_RATED ||
    props.status === CouponsConsts.STATUS_WAITING_FOR_COMPLETION ||
    props.status === CouponsConsts.STATUS_REDEEMED ||
    props.status === CouponsConsts.STATUS_REJECTED;

  useEffect(() => {
    if (dontCountdownProgressBar) {
      return;
    } else {
      const interval = setInterval(() => {
        setNowDate(moment());
      }, 100);
      return () => clearInterval(interval);
    }
  }, []);

  if (dontShowProgressBar) {
    return null;
  }

  const startDate = moment(props.startDate);
  const endDate = moment(props.endDate);

  if (!endDate.isValid()) {
    return null;
  }

  const diffStartToEnd = endDate.diff(startDate);
  const diffNowToEnd = endDate.diff(nowDate);
  const durationStartToEnd = moment.duration(diffStartToEnd, "milliseconds");
  const durationNowToEnd = moment.duration(diffNowToEnd, "milliseconds");
  let timeTilEnd =
    1 - durationNowToEnd.asMilliseconds() / durationStartToEnd.asMilliseconds();

  if (!isFinite(timeTilEnd)) {
    return null;
  }

  let timeLeftLabel = null;
  if (timeTilEnd >= 1) {
    timeLeftLabel = CouponsConsts.TIME_UP;
    timeTilEnd = 1;
    props.onExpiration();
  } else if (durationNowToEnd.asMinutes() < 60) {
    const displayMinutes = Math.round(durationNowToEnd.asMinutes());
    timeLeftLabel =
      displayMinutes +
      " " +
      (displayMinutes === 1
        ? CouponsConsts.MINUTE_LEFT
        : CouponsConsts.MINUTES_LEFT);
  } else if (durationNowToEnd.asHours() < 24) {
    const displayHours = Math.round(durationNowToEnd.asHours());
    timeLeftLabel =
      displayHours +
      " " +
      (displayHours === 1 ? CouponsConsts.HOUR_LEFT : CouponsConsts.HOURS_LEFT);
  } else if (durationNowToEnd.asDays() < 30) {
    const displayDays = Math.round(durationNowToEnd.asDays());
    timeLeftLabel =
      displayDays +
      " " +
      (displayDays === 1 ? CouponsConsts.DAY_LEFT : CouponsConsts.DAYS_LEFT);
  } else if (durationNowToEnd.asMonths() < 1000) {
    const displayMonths = Math.round(durationNowToEnd.asMonths());
    timeLeftLabel =
      displayMonths +
      " " +
      (displayMonths === 1
        ? CouponsConsts.MONTH_LEFT
        : CouponsConsts.MONTHS_LEFT);
  } else {
    timeLeftLabel = CouponsConsts.A_LONG_TIME;
  }

  const dangerThreshold = 0.8;

  return (
    <View style={couponStyle.progressContainer}>
      <View style={couponStyle.progressHeader}>
        <Text style={couponStyle.progressTitle}>{CouponsConsts.TIME_LEFT}</Text>
        <Text
          style={[
            couponStyle.progressValue,
            timeTilEnd >= dangerThreshold ? { color: COMPONENT_COLOR_2 } : null,
          ]}
        >
          {timeLeftLabel}
        </Text>
      </View>
      {Platform.OS === "web" ? null : Platform.OS === "android" ? (
        <ProgressBarAndroid
          styleAttr="Horizontal"
          color={
            timeTilEnd < dangerThreshold ? COMPONENT_COLOR_4 : COMPONENT_COLOR_2
          }
          indeterminate={false}
          progress={timeTilEnd}
        />
      ) : (
        <ProgressViewIOS
          progressTintColor={
            timeTilEnd < dangerThreshold ? COMPONENT_COLOR_4 : COMPONENT_COLOR_2
          }
          progress={timeTilEnd}
        />
      )}
    </View>
  );
}

Progress.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  status: PropTypes.string.isRequired,
  onExpiration: PropTypes.func,
};

function Status(props) {
  if (
    props.status === CouponsConsts.STATUS_COMPLETED ||
    props.status === CouponsConsts.STATUS_RATED
  ) {
    return (
      <View style={couponStyle.statusSection}>
        <Text style={couponStyle.statusText}>{CouponsConsts.COMPLETED}</Text>
      </View>
    );
  }
  return null;
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
};
