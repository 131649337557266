import { StyleSheet } from "react-native";
import {
  COMPONENT_COLOR_1,
  BACKGROUND_COLOR,
  TEXT_COLOR_3,
  TEXT_COLOR_4,
  TExT_COLOR_2,
  COMPONENT_COLOR_5,
  TEXT_COLOR_6,
  COMPONENT_COLOR_3,
} from "../common/colors";
import { getValueBasedOnScreenSize } from "../common/dimensions";

export const couponDetailsStyle = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: BACKGROUND_COLOR,
    justifyContent: "space-between",
  },
  couponContainer: {
    flex: 0.8,
  },
  upperCouponContainer: {
    marginTop: 24,
    marginHorizontal: 24,
    padding: 16,
    flex: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: COMPONENT_COLOR_1,
  },
  description: {
    color: TEXT_COLOR_3,
    marginVertical: 16,
  },
  dashedLine: {
    height: 0,
    borderStyle: "dashed",
    borderWidth: 0.5,
    borderColor: BACKGROUND_COLOR,
    borderRadius: 1,
    marginHorizontal: 24,
  },
  lowerCouponContainer: {
    marginHorizontal: 24,
    padding: 16,
    flex: 0.2,
    borderRadius: 1,
    borderColor: COMPONENT_COLOR_1,
    borderTopWidth: 2,
    backgroundColor: COMPONENT_COLOR_1,
    flexDirection: "row",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  detailsStatus: {},
  detailsStatusTitle: {
    fontSize: getValueBasedOnScreenSize(14, 16),
    fontWeight: "bold",
    color: TEXT_COLOR_3,
  },
  detailsStatusDesc: {
    fontSize: getValueBasedOnScreenSize(12, 14),
    color: TEXT_COLOR_3,
  },
  svgView1: {
    backgroundColor: BACKGROUND_COLOR,
    height: getValueBasedOnScreenSize(50, 60),
    width: getValueBasedOnScreenSize(50, 60),
    borderRadius: 100,
    right: 50,
  },
  svgView2: {
    backgroundColor: BACKGROUND_COLOR,
    height: getValueBasedOnScreenSize(50, 60),
    width: getValueBasedOnScreenSize(50, 60),
    borderRadius: 100,
    left: 50,
  },
  lowerCouponContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 12,
  },
  errorTextContainer: {
    height: 16,
    marginTop: 8,
  },
  errorText: {
    textAlign: "center",
    color: TExT_COLOR_2,
  },
  couponOptions: {
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: 8,
    flex: 0.2,
  },
  copyTextContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: COMPONENT_COLOR_5,
    borderRadius: 4,
    marginBottom: 8,
  },
  copyScrollView: {
    paddingVertical: 10,
  },
  linkText: {
    color: TEXT_COLOR_4,
  },
  copyBox: {
    backgroundColor: COMPONENT_COLOR_3,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  copy: {
    fontWeight: "500",
    color: TEXT_COLOR_6,
  },
  option: {
    marginVertical: 2,
  },
  optionsRating: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginHorizontal: 8,
    marginBottom: 14,
    flex: 0.2,
  },
  ratingText: {
    color: TEXT_COLOR_4,
  },
  couponRatings: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  ratingSubmitButton: {
    width: 30,
    height: 30,
    backgroundColor: COMPONENT_COLOR_1,
    padding: 8,
    left: 16,
    borderRadius: 4,
  },
});
