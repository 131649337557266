import React, { useState, Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Share } from "react-native";
import { AirbnbRating } from "@rneui/themed";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import _ from "lodash";
import * as Linking from "expo-linking";
import * as Clipboard from "expo-clipboard";

import { couponDetailsStyle } from "../../styles/CouponDetailsStyle";
import { Button } from "../../common/Button";
import {
  CouponBody,
  CouponDetailsTemplate,
  handleCompleteConfirmationDialog,
} from "../CouponComponents";
import { CouponsConsts, NavigationConsts } from "../../common/constants";
import {
  acceptCoupon,
  rejectCoupon,
  redeemCoupon,
  completeCoupon,
  rateCoupon,
  expireCoupon,
} from "../../modules/coupons/services/TaskService";
import {
  deleteSentCoupon,
  deleteReceivedCoupon,
  createReceivedCoupon,
} from "../../modules/coupons/services/CouponService";
import { BACKGROUND_COLOR } from "../../common/colors";
import CheckIcon from "../../assets/images/check.svg";
import { LoadingIndicator, LOADING_SIZE } from "../LoadingIndicator";
import {
  deleteSentCouponAction,
  deleteReceivedCouponAction,
} from "../../modules/coupons/actions/CouponActions";
import { refetchReceivedCoupons } from "../../modules/coupons/actions/AppConfigActions";

class CouponDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: null,
      taskError: null,
      shouldGoBack: false,
    };
  }

  componentDidMount() {
    const coupon = this.getCoupon();
    this.setState({ coupon: coupon });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      const coupon = this.getCoupon();
      this.setState({ coupon: coupon });
    }
  }

  handleAcceptPress = async (cbs) => {
    const { couponId } = this.props.route.params;
    this.setState({ taskError: null });

    let resultMsg = await this.props.acceptCoupon(couponId, cbs);
    if (!resultMsg) {
      this.setState({
        taskError: CouponsConsts.TASK_ERROR(CouponsConsts.TASK_ACCEPT),
      });
    } else {
      this.setState({ shouldGoBack: true });
    }
  };

  handleRejectPress = async (cbs) => {
    const { couponId } = this.props.route.params;
    this.setState({ taskError: null });

    let resultMsg = await this.props.rejectCoupon(couponId, cbs);
    if (!resultMsg) {
      this.setState({
        taskError: CouponsConsts.TASK_ERROR(CouponsConsts.TASK_DECLINE),
      });
    } else {
      this.setState({ shouldGoBack: true });
    }
  };

  handleDeletePress = async (cbs) => {
    const { couponId, listType } = this.props.route.params;
    this.setState({ taskError: null });

    let resultMsg;
    if (listType === CouponsConsts.TYPE_RECEIVED) {
      resultMsg = await this.props.deleteReceivedCoupon(couponId, cbs);
    } else if (listType === CouponsConsts.TYPE_SENT) {
      resultMsg = await this.props.deleteSentCoupon(couponId, cbs);
    }
    if (!resultMsg) {
      this.setState({
        taskError: CouponsConsts.TASK_ERROR(CouponsConsts.TASK_DELETE),
      });
    } else {
      this.setState({ shouldGoBack: true });
    }
  };

  handleRedeemPress = async () => {
    const { couponId } = this.props.route.params;
    this.setState({ taskError: null });

    let resultMsg = await this.props.redeemCoupon(couponId);
    if (!resultMsg) {
      this.setState({
        taskError: CouponsConsts.TASK_ERROR(CouponsConsts.TASK_REDEEM),
      });
    } else {
      this.setState({ shouldGoBack: true });
    }
  };

  handleCompletePress = async () => {
    const { couponId } = this.props.route.params;
    this.setState({ taskError: null });

    let resultMsg = await this.props.completeCoupon(couponId);
    if (!resultMsg) {
      this.setState({
        taskError: CouponsConsts.TASK_ERROR(CouponsConsts.TASK_COMPLETE),
      });
    } else {
      this.setState({ shouldGoBack: true });
    }
  };

  handleRating = async (rating) => {
    const { couponId } = this.props.route.params;
    this.setState({ taskError: null });

    let resultMsg = await this.props.rateCoupon(couponId, rating);
    if (!resultMsg) {
      this.setState({
        taskError: CouponsConsts.TASK_ERROR(CouponsConsts.TASK_RATE),
      });
    } else {
      this.setState({ shouldGoBack: true });
    }
  };

  handleUserPress = (username, displayName) => {
    this.props.navigation.navigate(NavigationConsts.PROFILE_SCREEN, {
      username: username,
      displayName: displayName,
    });
  };

  handleExpiration = () => {
    const { couponId } = this.props.route.params;
    const { status } = this.getCoupon();

    if (
      status === CouponsConsts.STATUS_REQUEST ||
      status === CouponsConsts.STATUS_ACCEPTED
    ) {
      this.props.expireCoupon(couponId);
    }
  };

  getCoupon = () => {
    const { couponId, listType, incomingCoopon } = this.props.route.params;
    const { couponsSent, couponsReceived } = this.props;

    if (listType === CouponsConsts.TYPE_SENT) {
      return couponsSent.find((coupon) => coupon.id === couponId);
    } else if (listType === CouponsConsts.TYPE_RECEIVED && !incomingCoopon) {
      return couponsReceived.find((coupon) => coupon.id === couponId);
    } else if (listType === CouponsConsts.TYPE_RECEIVED && incomingCoopon) {
      return incomingCoopon;
    }
    return null;
  };

  render() {
    const { listType } = this.props.route.params;

    if (this.state.shouldGoBack) {
      if (listType === CouponsConsts.TYPE_SENT) {
        this.props.navigation.navigate(
          NavigationConsts.SENT_SCREEN,
          this.state.coupon ? { scrollToCouponId: this.state.coupon.id } : null
        );
      } else {
        this.props.navigation.navigate(
          NavigationConsts.RECEIVED_SCREEN,
          this.state.coupon ? { scrollToCouponId: this.state.coupon.id } : null
        );
      }
    }
    if (!this.state.coupon) {
      return null;
    }

    const {
      name,
      sender,
      receiver,
      senderDisplayName,
      receiverDisplayName,
      senderAvatarId,
      receiverAvatarId,
      startDate,
      endDate,
      status,
      description,
      id,
    } = this.state.coupon;

    const { taskError } = this.state;

    const newDescription =
      description === "" ? CouponsConsts.NO_DESCRIPTION : description;

    return (
      <View style={couponDetailsStyle.container}>
        <View style={couponDetailsStyle.couponContainer}>
          <View style={couponDetailsStyle.upperCouponContainer}>
            <ScrollView showsVerticalScrollIndicator={false}>
              <CouponDetailsTemplate
                name={name}
                description={newDescription}
                sender={sender}
                receiver={receiver}
                senderDisplayName={senderDisplayName}
                receiverDisplayName={receiverDisplayName}
                couponType={listType}
                startDate={startDate}
                endDate={endDate}
              />
              <CouponBody
                listType={listType}
                sender={sender}
                receiver={receiver}
                senderDisplayName={senderDisplayName}
                receiverDisplayName={receiverDisplayName}
                senderAvatarId={senderAvatarId}
                receiverAvatarId={receiverAvatarId}
                status={status}
                startDate={startDate}
                endDate={endDate}
                handleUserPress={this.handleUserPress}
                handleExpiration={this.handleExpiration}
                contrast
                isBigBoxPressable
              />
            </ScrollView>
          </View>
          <View style={couponDetailsStyle.dashedLine}></View>
          <View style={couponDetailsStyle.lowerCouponContainer}>
            <View style={couponDetailsStyle.svgView1}></View>
            <View style={couponDetailsStyle.lowerCouponContent}>
              <CouponSnippet
                status={status}
                listType={listType}
                receiver={receiver}
                receiverDisplayName={receiverDisplayName}
                sender={sender}
                senderDisplayName={senderDisplayName}
              />
            </View>
            <View style={couponDetailsStyle.svgView2}></View>
          </View>
        </View>
        <View style={couponDetailsStyle.errorTextContainer}>
          {taskError ? (
            <Text style={couponDetailsStyle.errorText}>{taskError}</Text>
          ) : null}
        </View>
        <DetailsOptions
          couponId={id}
          status={status}
          listType={listType}
          sender={sender}
          senderDisplayName={senderDisplayName}
          receiver={receiver}
          receiverDisplayName={receiverDisplayName}
          couponTitle={name}
          onPressAccept={this.handleAcceptPress}
          onPressReject={this.handleRejectPress}
          onPressDelete={this.handleDeletePress}
          onPressRedeem={this.handleRedeemPress}
          onPressComplete={this.handleCompletePress}
          onRateCoupon={this.handleRating}
          refetchReceivedCoupons={this.props.refetchReceivedCoupons}
        />
      </View>
    );
  }
}

CouponDetails.propTypes = {
  couponsSent: PropTypes.array.isRequired,
  couponsReceived: PropTypes.array.isRequired,
};

export function CouponSnippet({
  status,
  listType,
  receiver,
  receiverDisplayName,
  sender,
  senderDisplayName,
}) {
  let snippetMessage;
  const validReceiverName = receiverDisplayName
    ? receiverDisplayName
    : receiver;
  const validSenderName = senderDisplayName ? senderDisplayName : sender;
  switch (status) {
    case CouponsConsts.STATUS_SENT:
      snippetMessage = {
        title: CouponsConsts.DETAILS_SENT,
        desc: CouponsConsts.DETAILS_SENT_INFO(validReceiverName),
      };
      break;
    case CouponsConsts.STATUS_REQUEST:
      snippetMessage = {
        title: CouponsConsts.DETAILS_REQUEST,
        desc: CouponsConsts.DETAILS_REQUEST_INFO,
      };
      break;
    case CouponsConsts.STATUS_ACCEPTED:
      snippetMessage = {
        title: CouponsConsts.DETAILS_ACCEPTED,
        desc:
          listType === CouponsConsts.TYPE_SENT
            ? CouponsConsts.DETAILS_ACCEPTED_SENT_INFO(validReceiverName)
            : CouponsConsts.DETAILS_ACCEPTED_RECEIVED_INFO,
      };
      break;
    case CouponsConsts.STATUS_REDEEMED:
    case CouponsConsts.STATUS_WAITING_FOR_COMPLETION:
      snippetMessage = {
        title: CouponsConsts.DETAILS_INPROGRESS,
        desc:
          listType === CouponsConsts.TYPE_SENT
            ? CouponsConsts.DETAILS_INPROGRESS_INFO(validReceiverName)
            : CouponsConsts.DETAILS_REDEEMED_INFO(validSenderName),
      };
      break;
    case CouponsConsts.STATUS_COMPLETED:
    case CouponsConsts.STATUS_RATED:
      snippetMessage = {
        title: CouponsConsts.DETAILS_COMPLETED,
        desc: CouponsConsts.DETAILS_COMPLETED_INFO,
      };
      break;
    case CouponsConsts.STATUS_REJECTED:
      snippetMessage = CouponsConsts.DETAILS_REJECTED;
      snippetMessage = {
        title: CouponsConsts.DETAILS_REJECTED,
        desc: CouponsConsts.DETAILS_REJECTED_INFO,
      };
      break;
    case CouponsConsts.STATUS_EXPIRED:
      snippetMessage = {
        title: CouponsConsts.DETAILS_EXPIRED,
        desc: CouponsConsts.DETAILS_EXPIRED_INFO,
      };
      break;
    case CouponsConsts.STATUS_SENT_LINK:
      snippetMessage = {
        title:
          listType === CouponsConsts.TYPE_RECEIVED
            ? CouponsConsts.DETAILS_SENT_LINK_FOR_RECEIVER
            : CouponsConsts.DETAILS_SENT_LINK_FOR_SENT,
        desc:
          listType === CouponsConsts.TYPE_RECEIVED
            ? CouponsConsts.DETAILS_SENT_LINK_INFO_FOR_RECEIVER
            : CouponsConsts.DETAILS_SENT_LINK_INFO_FOR_SENT,
      };
      break;
    default:
      snippetMessage = {
        title: CouponsConsts.DETAILS_UNKNOWN,
        desc: CouponsConsts.DETAILS_UNKNOWN_INFO,
      };
      break;
  }

  return (
    <View style={couponDetailsStyle.detailsStatus}>
      <Text style={couponDetailsStyle.detailsStatusTitle}>
        {snippetMessage.title}
      </Text>
      <Text style={couponDetailsStyle.detailsStatusDesc}>
        {snippetMessage.desc}
      </Text>
    </View>
  );
}

function DetailsOptions(props) {
  const [accepted, setAccept] = useState(false);
  const [rejected, setReject] = useState(false);

  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [redeemed, setRedeemed] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [rated, setRated] = useState(false);

  const [rating, setRating] = useState(5);

  const shareableLink = `https://coopons.psyntax.org/${props.couponId}`;

  const handleAccept = async () => {
    setAccept(true);
    await props.onPressAccept({
      success: () => {
        if (props.status === CouponsConsts.STATUS_SENT_LINK) {
          props.refetchReceivedCoupons(true);
        }
      },
      failure: () => {},
    });
    setAccept(false);
  };

  const handleReject = async () => {
    setReject(true);
    await props.onPressReject({
      success: () => {},
      failure: () => setReject(false),
    });
  };

  const handleDeleting = async () => {
    setDeleting(true);
    await props.onPressDelete({
      success: () => {},
      failure: () => setDeleting(false),
    });
  };

  const handleRedeem = async () => {
    setRedeemed(true);
    await props.onPressRedeem();
    setRedeemed(false);
  };

  const handleComplete = async () => {
    setCompleted(true);
    await props.onPressComplete();
    setCompleted(false);
  };

  const handleCompleteConfirmation = () => {
    handleCompleteConfirmationDialog(
      props.receiverDisplayName,
      props.receiver,
      props.couponTitle,
      handleComplete
    );
  };

  const handleRate = async () => {
    setRated(true);
    await props.onRateCoupon(rating);
    setRated(false);
  };

  const handleGetLink = async () => {
    await Clipboard.setStringAsync(shareableLink);
  };

  const handleShareCoopon = async () => {
    try {
      const senderName = props.senderDisplayName
        ? props.senderDisplayName
        : props.sender;
      const result = await Share.share({
        message: `${senderName} is sharing a Coopon - ${shareableLink}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.debug("Failed to share coopon", error.message);
      Sentry.Native.captureException(error);
    }
  };

  switch (props.status) {
    case CouponsConsts.STATUS_REQUEST:
      return (
        <View style={couponDetailsStyle.couponOptions}>
          <LoadingIndicator
            isLoading={accepted || rejected}
            size={LOADING_SIZE.LARGE}
          >
            <Button
              type={1}
              customStyle={couponDetailsStyle.option}
              onPress={handleAccept}
            >
              {CouponsConsts.ACCEPT}
            </Button>
            <Button
              type={2}
              customStyle={couponDetailsStyle.option}
              onPress={handleReject}
            >
              {CouponsConsts.DECLINE}
            </Button>
          </LoadingIndicator>
        </View>
      );
    case CouponsConsts.STATUS_SENT_LINK:
      if (props.listType === CouponsConsts.TYPE_SENT) {
        return (
          <View style={couponDetailsStyle.couponOptions}>
            <View style={couponDetailsStyle.copyTextContainer}>
              <ScrollView
                horizontal
                style={couponDetailsStyle.copyScrollView}
                contentContainerStyle={{ paddingHorizontal: 12 }}
              >
                <Text selectable style={couponDetailsStyle.linkText}>
                  {shareableLink}
                </Text>
              </ScrollView>
              <TouchableOpacity onPress={handleGetLink}>
                <View style={couponDetailsStyle.copyBox}>
                  <Text style={couponDetailsStyle.copy}>
                    {CouponsConsts.COPY_TO_CLIPBOARD}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <Button
              type={1}
              customStyle={couponDetailsStyle.option}
              onPress={handleShareCoopon}
            >
              {CouponsConsts.SHARE}
            </Button>
          </View>
        );
      } else {
        return (
          <View style={couponDetailsStyle.couponOptions}>
            <LoadingIndicator
              isLoading={accepted || rejected}
              size={LOADING_SIZE.LARGE}
            >
              <Button
                type={1}
                customStyle={couponDetailsStyle.option}
                onPress={handleAccept}
              >
                {CouponsConsts.CLAIM}
              </Button>
            </LoadingIndicator>
          </View>
        );
      }
    case CouponsConsts.STATUS_ACCEPTED:
      if (props.listType === CouponsConsts.TYPE_SENT) {
        return null;
      }
      return (
        <View style={couponDetailsStyle.couponOptions}>
          <LoadingIndicator isLoading={redeemed} size={LOADING_SIZE.LARGE}>
            <Button
              customStyle={couponDetailsStyle.option}
              type={1}
              onPress={handleRedeem}
            >
              {CouponsConsts.REDEEM}
            </Button>
          </LoadingIndicator>
        </View>
      );
    case CouponsConsts.STATUS_WAITING_FOR_COMPLETION:
      return (
        <View style={couponDetailsStyle.couponOptions}>
          <LoadingIndicator isLoading={completed} size={LOADING_SIZE.LARGE}>
            <Button
              type={1}
              customStyle={couponDetailsStyle.option}
              onPress={handleCompleteConfirmation}
            >
              {CouponsConsts.COMPLETE}
            </Button>
          </LoadingIndicator>
        </View>
      );
    case CouponsConsts.STATUS_COMPLETED:
      if (props.listType === CouponsConsts.TYPE_SENT) {
        return null;
      }
      return (
        <View style={couponDetailsStyle.optionsRating}>
          <Text style={couponDetailsStyle.ratingText}>
            {CouponsConsts.DETAILS_RATING_INFO}
          </Text>
          <View style={couponDetailsStyle.couponRatings}>
            <View style={{ width: 40, backgroundColor: "transparent" }}></View>
            <AirbnbRating
              reviews={[
                "Not at all!",
                "Sorta",
                "Good enough",
                "Yep!",
                "They delivered!",
              ]}
              defaultRating={rating}
              reviewSize={20}
              ratingCount={5}
              imageSize={40}
              onFinishRating={setRating}
            />
            <View style={{ width: 40 }}>
              {rating ? (
                <LoadingIndicator isLoading={rated} size={LOADING_SIZE.SMALL}>
                  <TouchableOpacity
                    activeOpacity={0.8}
                    style={couponDetailsStyle.ratingSubmitButton}
                    onPress={handleRate}
                  >
                    <CheckIcon width={15} height={15} />
                  </TouchableOpacity>
                </LoadingIndicator>
              ) : null}
            </View>
          </View>
        </View>
      );
    case CouponsConsts.STATUS_REJECTED:
      return (
        <View style={couponDetailsStyle.couponOptions}>
          <LoadingIndicator
            isLoading={sending || deleting}
            size={LOADING_SIZE.LARGE}
          >
            <Button
              type={2}
              customStyle={couponDetailsStyle.option}
              onPress={handleDeleting}
            >
              {CouponsConsts.DELETE}
            </Button>
          </LoadingIndicator>
        </View>
      );
    case CouponsConsts.STATUS_EXPIRED:
      return (
        <View style={couponDetailsStyle.couponOptions}>
          <LoadingIndicator isLoading={deleting} size={LOADING_SIZE.LARGE}>
            <Button
              type={1}
              customStyle={couponDetailsStyle.option}
              onPress={handleDeleting}
            >
              {CouponsConsts.DELETE}
            </Button>
          </LoadingIndicator>
        </View>
      );
    case CouponsConsts.STATUS_SENT:
    case CouponsConsts.STATUS_REDEEMED:
    case CouponsConsts.STATUS_RATED:
    default:
      return null;
  }
}

const mapStateToProps = (state) => ({
  couponsSent: state.coupons.couponsSent.coupons,
  couponsReceived: state.coupons.couponsReceived.coupons,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      acceptCoupon: acceptCoupon,
      rejectCoupon: rejectCoupon,
      deleteSentCoupon: deleteSentCoupon,
      deleteReceivedCoupon: deleteReceivedCoupon,
      redeemCoupon: redeemCoupon,
      completeCoupon: completeCoupon,
      expireCoupon: expireCoupon,
      rateCoupon: rateCoupon,
      createReceivedCoupon: createReceivedCoupon,
      deleteReceivedCouponAction: deleteReceivedCouponAction,
      deleteSentCouponAction: deleteSentCouponAction,
      refetchReceivedCoupons: refetchReceivedCoupons,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CouponDetails);
