import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

import rootReducer from "./modules/reducers";

const middlewares = [thunk];

export const store = createStore(
  rootReducer,
  //REDUX DEV TOOLS
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(...middlewares)
);
