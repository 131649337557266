import Constants from "expo-constants";
import { Buffer } from "buffer";
import {
  authFetchWithTimeout,
  fetchWithTimeout,
  processResponse,
} from "../../../common/network";
import {
  LoginConsts,
  SignupConsts,
  NetworkConsts,
} from "../../../common/constants";
import {
  updateUserAccountAction,
  updateSignInAction,
  updateSignOutAction,
} from "../actions/AuthActions";
import { getApiURL } from "../../../common/environment";
import { deleteExpoToken } from "./ExpoService";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { log } from "../../../common/logging";

const hostname = getApiURL();

const SIGN_IN = hostname + "/api/auth/login";
const SIGN_UP = hostname + "/api/auth/signup";
const CHECK_GOOGLE_ACCOUNT_EXIST = hostname + "/api/auth/google";
const GET_ACCOUNT = hostname + "/api/auth/account";

const SIGNIN_REQ = (user, pass) => {
  const token = Buffer.from(user + ":" + pass, "utf-8").toString("base64");
  return {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Basic " + token,
      deviceId: Constants.deviceId,
    }),
  };
};

const GOOGLE_SIGNIN_REQ = (googleIdToken) => {
  return {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: googleIdToken,
      deviceId: Constants.deviceId,
    }),
  };
};

const SIGNUP_REQ = (username, password) => {
  return {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  };
};

const GOOGLE_SIGNUP_REQ = (username, googleIdToken) => {
  return {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      googleIdToken: googleIdToken,
    }),
    body: JSON.stringify({
      username: username,
    }),
  };
};

export const signIn = (user, pass) => async (dispatch) => {
  const res = await fetchWithTimeout(SIGN_IN, SIGNIN_REQ(user, pass))
    .then((res) => {
      if (res.status === 401) {
        throw new Error(LoginConsts.INCORRECT_CREDENTIALS);
      } else if (res.status !== 200) {
        throw new Error(NetworkConsts.NETWORK_REQUEST_FAILED);
      }
      return res;
    })
    .then(async (res) => {
      const resJson = await res.json();
      dispatch(updateSignInAction(resJson.token));
      return resJson;
    })
    .catch((error) => {
      throw Error(error.message);
    });
  return res;
};

export const googleSignIn = (googleIdToken) => async (dispatch) => {
  const res = await fetchWithTimeout(SIGN_IN, GOOGLE_SIGNIN_REQ(googleIdToken))
    .then((res) => {
      if (res.status === 401) {
        throw new Error(LoginConsts.INCORRECT_CREDENTIALS);
      } else if (res.status !== 200) {
        throw new Error(NetworkConsts.NETWORK_REQUEST_FAILED);
      }
      return res;
    })
    .then(async (res) => {
      const resJson = await res.json();
      dispatch(updateSignInAction(resJson.token));
      return resJson;
    })
    .catch((error) => {
      throw Error(error.message);
    });
  return res;
};

export const signOut =
  (deleteExpoPushToken = false) =>
  async (dispatch) => {
    if (deleteExpoPushToken) {
      await deleteExpoToken()(dispatch);
    }
    await AsyncStorage.clear();
    dispatch(updateSignOutAction());
  };

export const signUp =
  (username, password, fullname = null, avatarID = 0) =>
  async (dispatch) => {
    const queryParam = `?${
      fullname ? "fullname=" + fullname + "&" : ""
    }avatarID=${avatarID}`;
    return await fetchWithTimeout(
      SIGN_UP + queryParam,
      SIGNUP_REQ(username, password)
    )
      .then(processResponse)
      .then(async (res) => {
        if (res.status === 409) {
          throw new Error(SignupConsts.UNAUTHORIZED_ACCOUNT_EXISTS);
        } else if (res.status !== 200) {
          throw new Error(NetworkConsts.NETWORK_REQUEST_FAILED);
        }
        dispatch(updateSignInAction(res.data.token));
        return res.data;
      })
      .catch((error) => {
        throw Error(error.message);
      });
  };

export const googleSignUp =
  (username, googleIdToken, fullname = null, avatarID = 0) =>
  async (dispatch) => {
    const queryParam = `?${
      fullname ? "fullname=" + fullname + "&" : ""
    }avatarID=${avatarID}`;
    return await fetchWithTimeout(
      SIGN_UP + queryParam,
      GOOGLE_SIGNUP_REQ(username, googleIdToken)
    )
      .then(processResponse)
      .then((res) => {
        if (res.status === 409) {
          throw new Error(SignupConsts.UNAUTHORIZED_ACCOUNT_EXISTS);
        } else if (res.status !== 200) {
          throw new Error(NetworkConsts.NETWORK_REQUEST_FAILED);
        }
        return res;
      })
      .catch((error) => {
        throw Error(error.message);
      });
  };

export const getUserAccount = () => (dispatch) => {
  return authFetchWithTimeout(
    GET_ACCOUNT,
    {
      method: "GET",
    },
    dispatch
  )
    .then(processResponse)
    .then(async (res) => {
      if (res.status !== 200) {
        throw Error(`Failed to get account for user: ${res.data}`);
      }
      dispatch(updateUserAccountAction(res.data));
      return res.data;
    })
    .catch((error) => {
      log("Error getting user account: ", error.message);
      throw Error(error.message);
    });
};

/**
 * Check if Google account exists by providing Google ID Token
 *
 * @param {String} googleIdToken
 * throws Error if Response is not 200
 */
export const doesGoogleAccountExist = (googleIdToken) => async (dispatch) => {
  return await fetchWithTimeout(CHECK_GOOGLE_ACCOUNT_EXIST, {
    method: "GET",
    headers: new Headers({
      googleIdToken: googleIdToken,
    }),
  })
    .then(processResponse)
    .then(async (res) => {
      if (res.status !== 200) {
        throw Error(res.data);
      }
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserAccount = (userAccount) => (dispatch) => {
  dispatch(updateUserAccountAction(userAccount));
};

export const getAuthToken = async () => {
  try {
    return await AsyncStorage.getItem("@token");
  } catch (e) {
    return null;
  }
};

export const invalidateAsyncStorage = async () => {
  try {
    return await AsyncStorage.clear();
  } catch (e) {
    return null;
  }
};
