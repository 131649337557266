import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import CouponsSent from "../components/screens/CouponsSent";
import CouponsReceived from "../components/screens/CouponsReceived";
import Profile from "../components/screens/Profile";
import CreateCoupon from "../components/screens/CreateCoupon";
import Buddies from "../components/screens/Buddies";
import Login from "../components/auth/Login";
import SignUp from "../components/auth/SIgnUp";
import Authorizing from "../components/auth/Authorizing";
import {
  COMPONENT_COLOR_6,
  ICON_COLOR_2,
  COMPONENT_COLOR_3,
  TEXT_COLOR_6,
  BACKGROUND_COLOR,
} from "../common/colors";
import { setupApp, teardownApp } from "./setup_coupons";
import TaskBar from "../components/TaskBar";
import { CouponsBar } from "../components/CouponsBar";
import { Header } from "../components/Header";
import { NavigationConsts } from "../common/constants";
import CouponDetails from "../components/screens/CouponDetails";
import EditProfile from "../components/screens/EditProfile";
import CouponsHeaderIcons from "../components/CouponsHeaderIcons";
import Logo from "../assets/images/Logo1-1.svg";
import { navigationRef } from "./TheNavigator";
import { Platform, TouchableOpacity } from "react-native";
import { showAppInfoToast } from "../common/utilities";
import Settings from "../components/screens/settings/Settings";
import { ContactUs } from "../components/screens/settings/ContactUs";
import { CouponDetailsWeb } from "../components/screens/CouponDetailsWeb";

export type RootStackParamList = {
  maintab: undefined;
  createcoupon: undefined;
  editprofile: undefined;
  settings: undefined;
  coupon: undefined;
  profile: {
    title: string;
    username: string | null;
  };
};

export type SettingsStackParamList = {
  settings: undefined;
  contactus: undefined;
};

const AuthStack = createStackNavigator();
const CouponsTabStack = createMaterialTopTabNavigator();
const CouponsStack = createStackNavigator();
const ProfileStack = createStackNavigator();
const SettingsStack = createStackNavigator<SettingsStackParamList>();
const MainTabStack = createBottomTabNavigator();
const MainStack = createStackNavigator<RootStackParamList>();
const MainWebStack = createStackNavigator();

const CouponsTabNavigation = (props) => {
  return (
    <CouponsTabStack.Navigator tabBar={(props) => <CouponsBar {...props} />}>
      <CouponsTabStack.Screen
        name={NavigationConsts.RECEIVED_SCREEN}
        component={CouponsReceived}
      />
      <CouponsTabStack.Screen
        name={NavigationConsts.SENT_SCREEN}
        component={CouponsSent}
      />
    </CouponsTabStack.Navigator>
  );
};

const CouponsStackNavigation = (props) => {
  return (
    <CouponsStack.Navigator>
      <CouponsStack.Screen
        name={NavigationConsts.COUPONS_TABS}
        component={CouponsTabNavigation}
        options={({ route }) => ({
          headerLeft: () => (
            <TouchableOpacity activeOpacity={1} onPress={showAppInfoToast}>
              <Logo style={{ marginLeft: 16 }} height={55} width={55} />
            </TouchableOpacity>
          ),
          ...Header(null, () => <CouponsHeaderIcons {...props} />, route),
        })}
        initialParams={{
          title: null,
        }}
      />
      <CouponsStack.Screen
        name={NavigationConsts.PROFILE_SCREEN}
        component={Profile}
        options={({ route }) =>
          Header(NavigationConsts.PROFILE_TITLE, () => {}, route)
        }
        initialParams={{
          title: NavigationConsts.PROFILE_TITLE,
        }}
      />
    </CouponsStack.Navigator>
  );
};

const ProfileStackNavigation = (props) => {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name={NavigationConsts.PROFILE_SCREEN}
        component={Profile}
        options={({ route }) =>
          Header(NavigationConsts.PROFILE_TITLE, () => {}, route)
        }
        initialParams={{
          title: NavigationConsts.NO_HEADER_TITLE,
          username: null,
        }}
      />
    </ProfileStack.Navigator>
  );
};

const SettingsStackNavigation = () => {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name={"settings"}
        component={Settings}
        options={() => Header(NavigationConsts.SETTINGS_TITLE, () => {})}
      />
      <SettingsStack.Screen
        name={"contactus"}
        component={ContactUs}
        options={() => Header(NavigationConsts.CONTACT_US, () => {})}
      />
    </SettingsStack.Navigator>
  );
};

const MainTabNavigation = (props) => {
  return (
    <MainTabStack.Navigator
      tabBar={(props) => <TaskBar {...props} />}
      tabBarOptions={{
        activeTintColor: ICON_COLOR_2,
        inactiveTintColor: COMPONENT_COLOR_3,
        activeBackgroundColor: COMPONENT_COLOR_6,
        inactiveBackgroundColor: COMPONENT_COLOR_6,
        keyboardHidesTabBar: true,
      }}
      backBehavior={"initialRoute"}
      initialRouteName={NavigationConsts.COUPONS_STACK}
    >
      <MainTabStack.Screen
        name={NavigationConsts.COUPONS_STACK}
        component={CouponsStackNavigation}
      />
      <MainTabStack.Screen
        name={NavigationConsts.PROFILE_STACK}
        component={ProfileStackNavigation}
      />
    </MainTabStack.Navigator>
  );
};

class MainStackNavigation extends React.Component {
  componentDidMount() {
    setupApp();
  }

  componentWillUnmount() {
    teardownApp();
  }

  render() {
    return (
      <MainStack.Navigator headerMode="screen">
        <MainStack.Screen
          name={"maintab"}
          component={MainTabNavigation}
          options={{
            header: () => null,
          }}
        />
        <MainStack.Screen
          name={"createcoupon"}
          component={CreateCoupon}
          options={{
            headerTitle: NavigationConsts.CREATE_COUPON_TITLE,
            headerTitleStyle: {
              color: TEXT_COLOR_6,
            },
            headerStyle: {
              backgroundColor: BACKGROUND_COLOR,
              //https://github.com/react-navigation/react-navigation/issues/2457
              shadowColor: "transparent",
              elevation: 0,
            },
            headerBackTitleVisible: false,
          }}
        />
        <MainStack.Screen
          name={"coupon"}
          component={CouponDetails}
          options={{
            headerTitle: NavigationConsts.COUPON_DETAILS_TITLE,
            headerTitleStyle: {
              color: TEXT_COLOR_6,
            },
            headerStyle: {
              backgroundColor: BACKGROUND_COLOR,
              //https://github.com/react-navigation/react-navigation/issues/2457
              shadowColor: "transparent",
              elevation: 0,
            },
            headerBackTitleVisible: false,
          }}
        />
        <MainStack.Screen
          name={"editprofile"}
          component={EditProfile}
          options={() => Header(NavigationConsts.EDITPROFILE_TITLE, () => {})}
        />
        <MainStack.Screen
          name={"settings"}
          component={SettingsStackNavigation}
          options={{
            headerShown: false,
          }}
        />
        <MainStack.Screen
          name={"profile"}
          component={Profile}
          options={({ route }) =>
            Header(NavigationConsts.PROFILE_TITLE, () => {}, route)
          }
          initialParams={{
            title: NavigationConsts.PROFILE_TITLE,
            username: null,
          }}
        />
      </MainStack.Navigator>
    );
  }
}

const AuthNavigation = () => {
  return (
    <AuthStack.Navigator screenOptions={{ headerShown: false }}>
      <AuthStack.Screen
        name={NavigationConsts.LOGIN_SCREEN}
        component={Login}
      />
      <AuthStack.Screen
        name={NavigationConsts.SIGNUP_SCREEN}
        component={SignUp}
      />
    </AuthStack.Navigator>
  );
};

const MainWebNavigation = () => {
  return (
    <MainWebStack.Navigator
      screenOptions={{ headerShown: false, title: "Coopons" }}
    >
      <MainWebStack.Screen
        name="coupondetailsweb"
        component={CouponDetailsWeb}
      />
    </MainWebStack.Navigator>
  );
};

const RootNavigation = (props) => {
  const { isFetchnigJWT, authorizeUser } = props.auth;

  if (Platform.OS === "web") {
    return (
      <NavigationContainer ref={navigationRef}>
        <MainWebNavigation />
      </NavigationContainer>
    );
  }

  if (isFetchnigJWT) {
    return <Authorizing />;
  }

  return (
    <NavigationContainer ref={navigationRef}>
      {authorizeUser ? <MainStackNavigation /> : <AuthNavigation />}
    </NavigationContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, null)(RootNavigation);
