import { BACKGROUND_COLOR } from "../common/colors";
import { getValueBasedOnScreenSize } from "../common/dimensions";

export function Header(title, headerRight, route = null) {
  return {
    headerShown: true,
    headerStyle: {
      backgroundColor: BACKGROUND_COLOR,
      //https://github.com/react-navigation/react-navigation/issues/2457
      shadowColor: "transparent",
      elevation: 0,
    },
    headerTitleContainerStyle: {
      marginLeft: 8,
    },
    headerRightContainerStyle: {
      marginRight: 16,
    },
    title: route !== null ? route.params.title : title,
    headerTitleStyle: {
      fontSize: getValueBasedOnScreenSize(16, 20),
      marginLeft: -30,
    },
    headerRight: headerRight,
    headerBackTitleVisible: false,
  };
}
