import { StyleSheet } from "react-native";

import {
  ALT_APP_COLOR_1,
  APP_COLOR_1,
  COMPONENT_COLOR_4,
  TEXT_COLOR_1,
  TEXT_COLOR_4,
  TEXT_COLOR_5,
  COMPONENT_COLOR_1,
  TEXT_COLOR_3,
  COMPONENT_COLOR_3,
} from "../common/colors";
import {
  FONT_SIZE_3,
  FONT_SIZE_4,
  getValueBasedOnScreenSize,
} from "../common/dimensions";

export var contrast = false;

export const couponStyle = StyleSheet.create({
  container: {
    backgroundColor: COMPONENT_COLOR_3,
    borderRadius: 4.8, //was 0.3rem
    marginHorizontal: 12, //was 0.5rem
    marginVertical: 3.2, //was 0.2rem
    paddingHorizontal: 16, //was 1rem
    padding: 16, //was 1rem
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  leftContainer: {
    alignItems: "center",
  },
  ratedIcon: {
    marginTop: 16,
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  optionsIcon: {
    // marginLeft: 'auto',
    // width: 16,
    paddingHorizontal: 8,
    paddingVertical: 20,
    justifyContent: "flex-end",
  },
  statusIcon: {
    fontSize: 24,
    fontWeight: "bold",
    alignSelf: "center",
    color: TEXT_COLOR_3,
  },
  couponHeader: {
    marginTop: 16,
  },
  couponFooter: {
    top: -6,
    fontSize: FONT_SIZE_4,
    color: ALT_APP_COLOR_1,
  },
  couponFooterName: {
    color: APP_COLOR_1,
  },
  title: {
    fontSize: 16,
    color: contrast ? TEXT_COLOR_3 : ALT_APP_COLOR_1,
    fontWeight: "bold",
    flexWrap: "wrap",
    flex: 1,
  },
  desc: {
    fontSize: FONT_SIZE_3,
  },

  lowerContainer: {},
  expandedCouponContainer: {
    marginVertical: 8,
  },

  expirationContainer: {
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginTop: 8,
    backgroundColor: APP_COLOR_1,
    borderRadius: 4,
    justifyContent: "space-between",
    alignSelf: "flex-start",
  },
  expirationUnitContainer: {
    flexDirection: "row",
    marginRight: 6,
  },
  expirationUnit: {
    color: ALT_APP_COLOR_1,
  },
  expirationUnitLabel: {
    fontWeight: "500",
    color: ALT_APP_COLOR_1,
  },

  expirationIcon: {
    borderRadius: 4,
    backgroundColor: APP_COLOR_1,
    marginLeft: "auto",
    marginRight: 8,
  },
  expirationIconText: {
    color: ALT_APP_COLOR_1,
    fontSize: FONT_SIZE_4,
    padding: 4,
  },

  optionsBarContainer: {
    top: -4.6,
    borderBottomLeftRadius: 4.8,
    borderBottomRightRadius: 4.8,
    marginHorizontal: 8,
    paddingHorizontal: 16,
    height: 0,
    backgroundColor: APP_COLOR_1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  trashIcon: {},

  requestOptions: {
    flexDirection: "row",
    flex: 1,
    marginTop: 8,
  },
  couponOptionsSubcontainer: {
    flexDirection: "row",
    flex: 1,
  },
  optionsRating: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },

  statusSection: {},
  statusText: {
    color: COMPONENT_COLOR_1,
  },

  statusIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 8,
    alignContent: "center",
    justifyContent: "center",
  },
  contents: {
    marginHorizontal: 12,
    flex: 1,
  },
  header: {
    flexDirection: "column",
  },
  headerOne: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTwo: {},
  expiration: {
    color: contrast ? TEXT_COLOR_3 : TEXT_COLOR_1,
    fontSize: 20,
  },
  date: {
    color: contrast ? TEXT_COLOR_3 : TEXT_COLOR_4,
    fontSize: 12,
  },
  body: {},
  assignerContainer: {
    marginVertical: 12,
    padding: 8,
    borderRadius: 4,
    flexDirection: "row",
  },
  assigner: {
    flexDirection: "row",
    alignItems: "center",
  },
  assignerAvatar: {
    marginRight: 4,
  },
  assignerText: {},
  assignerHeader: {
    fontSize: 12,
    color: TEXT_COLOR_4,
  },
  assignerBody: {
    marginTop: 4,
  },
  assignerName: {
    color: TEXT_COLOR_5,
  },

  progressContainer: {},
  progressHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  progressTitle: {
    fontSize: 12,
    color: TEXT_COLOR_4,
  },
  progressValue: {
    fontSize: 12,
    color: COMPONENT_COLOR_4,
  },

  options: {},

  detailsTemplateContainer: {},
  detailsTemplateSection: {
    marginBottom: 4,
  },
  detailsTemplateType: {
    color: TEXT_COLOR_3,
    fontSize: 16,
    marginBottom: 8,
  },
  detailsTemplateTitle: {
    textAlign: "center",
    color: TEXT_COLOR_3,
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
  },
  detailsTemplateSubSection1: {
    color: TEXT_COLOR_3,
    fontSize: 12,
    marginBottom: -2,
  },
  detailsTemplateSubSection2: {
    color: TEXT_COLOR_3,
    fontSize: 16,
    fontWeight: "bold",
    flexWrap: "wrap",
  },
});
