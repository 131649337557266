import React, { Component } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  ActivityIndicator,
} from "react-native";
import PropTypes from "prop-types";

import {
  APP_COLOR_1_OPAQUE,
  TEXT_COLOR_3,
  COMPONENT_COLOR_1,
  BUTTON_COLOR_3,
  COMPONENT_COLOR_3,
  BUTTON_COLOR_1,
  COMPONENT_COLOR_5,
  TEXT_COLOR_6,
} from "./colors";

export class Button extends Component {
  render() {
    let {
      selected = true,
      type,
      onPress,
      isLoading = false,
      onLoadingJSX = null,
      children,
      customStyle,
      customButtonStyle,
      customTextStyle,
      includeFlexContainer = false,
      activeOpacity = 0.8,
      isDisabled = false,
    } = this.props;

    const presetStyle = {
      btn: buttonStyle["Btn" + type],
      text: buttonStyle["Text" + type],
    };

    const presetDisabledStyle = {
      btn: buttonStyle["BtnDisabled" + type],
      text: buttonStyle["TextDisabled" + type],
    };

    onLoadingJSX = onLoadingJSX ? (
      onLoadingJSX
    ) : (
      <ActivityIndicator size={"small"} color={COMPONENT_COLOR_3} />
    );

    return (
      <View
        style={[includeFlexContainer ? buttonStyle.flex : null, customStyle]}
      >
        <TouchableOpacity
          style={[
            buttonStyle.button,
            presetStyle.btn,
            isDisabled && presetDisabledStyle.btn,
            selected ? null : buttonStyle.disabled,
            customButtonStyle,
          ]}
          activeOpacity={activeOpacity}
          onPress={onPress}
          disabled={isLoading || isDisabled}
        >
          {isLoading ? (
            onLoadingJSX
          ) : (
            <Text
              style={[
                buttonStyle.text,
                presetStyle.text,
                isDisabled && presetDisabledStyle.text,
                customTextStyle,
              ]}
            >
              {children}
            </Text>
          )}
        </TouchableOpacity>
      </View>
    );
  }
}

Button.propTypes = {
  selected: PropTypes.bool,
  type: PropTypes.number.isRequired,
  onPress: PropTypes.func,
  isLoading: PropTypes.bool,
  onLoadingJSX: PropTypes.object,
  children: PropTypes.any,
  customStyle: PropTypes.object,
  customButtonStyle: PropTypes.object,
  customTextStyle: PropTypes.object,
  includeFlexContainer: PropTypes.bool,
  activeOpacity: PropTypes.number,
};

const buttonStyle = StyleSheet.create({
  flex: {
    flex: 1,
  },
  button: {
    alignItems: "center",
    textAlign: "center",
    textAlignVertical: "center",
    height: 42,
  },
  text: {
    flex: 1,
  },
  disabled: {
    backgroundColor: APP_COLOR_1_OPAQUE,
  },

  Btn1: {
    backgroundColor: COMPONENT_COLOR_1,
    borderRadius: 4,
    justifyContent: "center",
  },
  BtnDisabled1: {
    backgroundColor: COMPONENT_COLOR_5,
  },
  Text1: {
    fontSize: 14,
    marginVertical: 12,
    color: TEXT_COLOR_3,
  },
  TextDisabled1: {
    color: TEXT_COLOR_3,
  },
  Btn2: {
    justifyContent: "center",
  },
  Text2: {
    fontSize: 14,
    marginVertical: 12,
    marginHorizontal: 24,
    color: COMPONENT_COLOR_1,
  },

  Btn3: {
    backgroundColor: BUTTON_COLOR_3,
    justifyContent: "center",
  },
  Text3: {
    fontSize: 14,
    marginVertical: 12,
    marginHorizontal: 24,
    color: TEXT_COLOR_6,
  },
  Btn4: {
    backgroundColor: BUTTON_COLOR_1,
    borderRadius: 36,
    justifyContent: "center",
    margin: 16,
    width: 200,
  },
  Text4: {
    alignSelf: "center",
    marginVertical: 10,
    fontSize: 16,
    color: BUTTON_COLOR_3,
  },
  Btn5: {
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 2,
    borderColor: COMPONENT_COLOR_1,
  },
  Text5: {
    fontSize: 14,
    marginVertical: 12,
    marginHorizontal: 24,
    color: COMPONENT_COLOR_1,
  },
});
