import Constants from "expo-constants";

import { NetworkConsts } from "./constants";
import { resetChannel } from "../modules/coupons/services/PusherService";
import { updateSignOutAction } from "../modules/coupons/actions/AuthActions";
import { AuthError } from "./AuthError";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Sentry from "sentry-expo";

export const NetworkStatus = {
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

export function fetchWithTimeout(url, options, timeout = 10000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error(NetworkConsts.NETWORK_REQUEST_TIMED_OUT)),
        timeout
      )
    ),
  ]);
}

export function authFetchWithTimeout(url, options, dispatch, timeout = 10000) {
  return AsyncStorage.getItem("@token")
    .then((token) => {
      const deviceId = Constants.deviceId;
      return {
        token: token,
        deviceId: deviceId,
      };
    })
    .then((auth) => {
      const authHeader = {
        Authorization: "Bearer " + auth.token,
        deviceId: auth.deviceId,
      };
      return Promise.race([
        fetch(url, {
          ...options,
          headers: { ...options.headers, ...authHeader },
        }),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error(NetworkConsts.NETWORK_REQUEST_TIMED_OUT)),
            timeout
          )
        ),
      ]);
    })
    .then(async (response) => {
      if (response.status === 401) {
        dispatch(updateSignOutAction());
        await invalidateAsyncStorage();
        throw new AuthError(NetworkConsts.AUTHENTICATION_FAILED);
      }
      return response;
    });
}

export function processResponse(response) {
  const status = response.status;
  var data;
  try {
    data = response.json();
  } catch (e) {
    data = response.text();
  }

  return Promise.all([status, data]).then((res) => ({
    status: res[0],
    data: res[1],
  }));
}

const invalidateAsyncStorage = async () => {
  try {
    resetChannel();
    return await AsyncStorage.clear();
  } catch (e) {
    Sentry.Native.captureException(e);
    return null;
  }
};
